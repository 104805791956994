<template>
    <input type="number" class="form-control" v-model="value" @change="change"
    :disabled="disabled" :class="addClass" :step="step">
</template>

<script>
export default {
    name: 'numberinput',
    props: ['v', 'd', 'disabled', 'addClass'],
    data  () {
        return {
            value: null,
            decimal: 0,
            step: "1"
        }
    },
    computed:{
    },
    created (){
    },
    mounted  () {
        if (this.d) {
            this.decimal = this.d;
            this.step = (1 / Math.pow(10, parseInt(this.d))).toString();
        }
        this.value = parseFloat(this.v).toFixed(this.decimal);
    },
    watch: {
        v () {
            if (this.d) {
                this.decimal = this.d;
                this.step = (1 / Math.pow(10, parseInt(this.d))).toString();
            }
            this.value = parseFloat(this.v).toFixed(this.decimal);
        }
    },
    methods: {
        change (){
            if (this.d) this.decimal = this.d;
            if (this.value) this.value = parseFloat(this.value).toFixed(this.decimal);
            this.$emit('update:v', this.value)
            this.$emit('change', this.value);
        }
    }
}
</script>