<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-10">
                <div class="card">
                    <div class="card-header table-responsive-md">
                        <table class="table table-striped table-sm table-bordered">
                            <thead>
                                <tr class="table-secondary">
                                    <th></th>
                                    <th>Cargo</th>
                                    <th>Descripción</th>
                                    <th>Pasajero</th>
                                    <th>Detalle</th>
                                    <th>Fecha</th>
                                    <th>Monto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(charge, idx) of getFeesCharges">
                                    <td><input type="checkbox" v-model="selected[idx]" @change="setSelected()"></td>
                                    <td>{{charge.FeeCode}}</td>
                                    <td>{{charge.ChargeDetail}}</td>
                                    <td>{{charge.Passenger}}</td>
                                    <td>{{charge.Info}}</td>
                                    <td>{{getDate(charge.CreatedUTC)}}</td>
                                    <td :class="getAmountClass(charge)">{{parseFloat(charge.ChargeAmount).toFixed(2)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row"
                    :disabled="(countSelected!=1 || !canDeleteFee) && !!feeStored"
                    @click="deleteFee">Eliminar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" :disabled="countSelected!=1 || !canOverrideFee"
                    @click="showOverrideFee">Override</button>
                </div>
                <div class="form-row">
                    <!--button type="button" class="btn btn-primary" @click="addPayments" data-toggle="modal"
                    data-target="#newpayment">Agregar</button-->
                    <button type="button" class="btn btn-primary button-row" @click="addFee">Agregar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="showPrint"
                        :disabled="countSelected==0">Imprimir</button>
                </div>
                <!--div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="showValues">Precios</button>
                </div-->


            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <newfee v-if="newfee" @close="close"></newfee>
        <inputvalue v-if="showinputvalue" @save="overrideFee" @close="showinputvalue = false" text="Ingresar Monto"
            :default="defValue" addclass="amount-right"></inputvalue>
        <feeprint v-if="toPrint" id="fee-print" :show.sync="toPrint" :selectedFees="getFeesChargesToPrint"></feeprint>
        <feevalues v-if="values" @close="closeValues"></feevalues>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import inputvalue from '@/components/tools/InputValue'
import newfee from '@/components/booking/NewFee'
import feeprint from '@/components/booking/FeePrint'
import feevalues from '@/components/booking/FeeValues'
var moment = require('moment');
export default {
  name: 'bookingfees',
  components: {
    inputvalue,
    newfee,
    feeprint,
    feevalues
  },
  data  () {
    return {
        newfee: false,
        selected: {},
        showinputvalue: false,
        defValue: null,
        canDeleteFee: false,
        canOverrideFee: false,
        toPrint: false,
        values: false
    }
  },
  mounted () {
    this.setSelectedNull();
    this.canDeleteFee = app.canAccess(this.user, 'api','POST', '/booking/delete_fee/', false);
    this.canOverrideFee = app.canAccess(this.user, 'api','POST', '/booking/override_fee/', false);
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    countSelected (){
        let c = 0;
        for (let s in this.selected){
            if (this.selected[s]) c ++;
        }
        return c;
    },
    feeStored () {
        let c = 0;
        for (let s in this.selected){
            if (this.selected[s]) {
                let fee = this.getFeesCharges[s];
                if (fee.isNew) return false;
            }
        }
        return true;
    },

    getFeesCharges () {
        let res = [];
        for (let i in this.booking.Passengers) {
            let pax = this.booking.Passengers[i];
            for (let fee of pax.Fees) {
                fee.ParentID = fee.PassengerID;
                fee.FeeClass = 'PASSENGER';
                fee.Passenger = pax.LastName + ', ' + pax.FirstName;
                fee.paxIdx = i;
                if (pax.MiddleName) fee.Passenger += ' ' + pax.MiddleName;
                fee.Info = fee.FeeDetail;
                if (!fee.FeeDetail && fee.DepartureStation && fee.ArrivalStation) {
                    fee.Info = fee.DepartureStation + '-' + fee.ArrivalStation;
                }
                fee.ChargeAmount = 0;
                for (let charge of fee.Charges) {
                    if (charge.ChargeType==3) continue;
                    let k = 1;
                    if (charge.ChargeType==1) k = -1;
                    fee.ChargeAmount += parseFloat(charge.ChargeAmount * k)
                    fee.ChargeDetail = charge.ChargeDetail;
                    if (charge.CurrencyCode) fee.CurrencyCode = charge.CurrencyCode;
                }
                res.push(fee);
            }
            for (let j in pax.Segments) {
                let segment = pax.Segments[j];
                for (let fee of segment.Fees) {
                    fee.ParentID = fee.SegmentID;
                    fee.paxIdx = i;
                    fee.FeeClass = 'SEGMENT';
                    fee.Passenger = pax.LastName + ', ' + pax.FirstName;
                    fee.segmentIdx = j;
                    if (pax.MiddleName) fee.Passenger += ' ' + pax.MiddleName;
                    fee.Info = "";
                    fee.ChargeAmount = 0;
                    if (segment.Legs.length>0) {
                        fee.Info = fee.Info + ' ' + segment.Legs[0].InventoryLeg.FlightNumber;
                    }
                    fee.Info = fee.Info + ' ' + segment.DepartureStation + '-' + segment.ArrivalStation;
                    if (segment.DepartureDate) {
                        fee.Info = fee.Info + ' ' + moment(segment.DepartureDate).locale('es').format('DD MMM YY');
                    }
                    for (let charge of fee.Charges) {
                        if (charge.ChargeType==3) continue;
                        let k = 1;
                        if (charge.ChargeType==1) k = -1;
                        fee.ChargeAmount += parseFloat(charge.ChargeAmount * k)
                        fee.ChargeDetail = charge.ChargeDetail;
                        if (charge.CurrencyCode) fee.CurrencyCode = charge.CurrencyCode;
                    }
                    res.push(fee);
                }
            }
        }
        for (let fee of this.booking.Fees) {
            fee.ParentID = fee.BookingID;
            fee.FeeClass = 'BOOKING';
            fee.Passenger = "";
            fee.Info = "";
            fee.ChargeAmount = 0;
            for (let charge of fee.Charges) {
                if (charge.ChargeType==3) continue;
                let k = 1;
                if (charge.ChargeType==1) k = -1;
                fee.ChargeAmount += parseFloat(charge.ChargeAmount * k)
                fee.ChargeDetail = charge.ChargeDetail;
                if (charge.CurrencyCode) fee.CurrencyCode = charge.CurrencyCode;

            }
            res.push(fee);
        }
        return res;
    },
    getFeesChargesToPrint () {
        let res = [];
        for (let i in this.getFeesCharges) {
            if (this.selected[i]) {
                res.push(this.getFeesCharges[i])
            }
        }
        return res;
    },
  },
  methods: {

    getDate (d) {
        return moment(d).format("DD/MM/YYYY HH:MM");
    },
    deleteFee () {
      for (let i in this.selected) {
        if (this.selected[i]) {
            this.actionFee('DELETE', i);
            return;
        }
      }
    },
    showOverrideFee () {
      if (!this.canOverrideFee) {
          alert('Sin permiso para esta acción');
          return;
      }
      this.showinputvalue = true;
      let fee = this.getFeesCharges[this.selected];
      if (fee) this.defValue = fee.ChargeAmount;
      this.$nextTick(function() {
        $('#inputValue').modal('show');
      })
    },
    overrideFee (value) {
      for (let i in this.selected) {
        if (this.selected[i]) {
            this.actionFee('OVERRIDE', i, value);
        }
      }
      $('#inputValue').modal('hide');
      this.showinputvalue = false;
      this.defValue = null;

    },
    actionFee (action, idx, value) {
        let fee = this.getFeesCharges[idx];
        if (fee.FeeClass=='PASSENGER') {
            let i = _.findIndex(this.booking.Passengers[fee.paxIdx].Fees, (f)=>f.FeeNumber==fee.FeeNumber);
            if (i>=0) {
                if (action=='DELETE') this.booking.Passengers[fee.paxIdx].Fees.splice(i, 1);
                if (action=='OVERRIDE') {
                    this.booking.Passengers[fee.paxIdx].Fees[i].Charges[0].ChargeAmount = value;
                    this.booking.Passengers[fee.paxIdx].Fees[i].FeeOverride = true;
                }
            }
        }
        if (fee.FeeClass=='BOOKING') {
            let i = _.findIndex(this.booking.Fees, (f)=>f.FeeNumber==fee.FeeNumber);
            if (i>=0) {
                if (action=='DELETE') this.booking.Fees.splice(i, 1);
                if (action=='OVERRIDE') {
                    this.booking.Fees[i].Charges[0].ChargeAmount = value;
                    this.booking.Fees[i].FeeOverride = true;
                }
            }
        }
        if (fee.FeeClass=='SEGMENT') {
            let i = _.findIndex(this.booking.Passengers[fee.paxIdx].Segments[fee.segmentIdx].Fees, (f)=>f.FeeNumber==fee.FeeNumber);
            if (i>=0) {
                if (action=='DELETE') this.booking.Passengers[fee.paxIdx].Segments[fee.segmentIdx].Fees.splice(i, 1);
                if (action=='OVERRIDE') {
                    this.booking.Passengers[fee.paxIdx].Segments[fee.segmentIdx].Fees[i].Charges[0].ChargeAmount = value;
                    this.booking.Passengers[fee.paxIdx].Segments[fee.segmentIdx].Fees[i].FeeOverride = true;
                }
            }

        }
        app.bookingSumUp(this.booking);
        this.setSelectedNull()
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },
    addFee () {
        this.newfee = true;
        this.$nextTick(function () {
          //$('#newfee').modal('show')
        })
    },
    close () {
        this.newfee = false;
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },
    getAmountClass (charge) {
        if (charge.ChargeAmount<0 || charge.ChargeType==1) return 'payment-refund';
        return "";
    },
    setSelectedNull () {
        this.selected = {};
        for (let s in this.getFeesCharges) {
            this.selected[s] = false;
        }
    },
    setSelected (){
        this.selected = Object.assign({}, this.selected);
    },
    showPrint () {
        for (let s in this.selected) {
            if (this.selected[s]){
                if (!this.getFeesCharges[s].ParentID) {
                    alert("No se permite imprimir cargos antes de grabar");
                    return;
                }
                if (parseFloat(this.booking.BalanceDue)>0) {
                    alert("No se permite imprimir con saldo pendiente");
                    return;
                }
                for (let payment of this.booking.Payments) {
                    if (!payment.PaymentID) {
                        alert("No se permite imprimir cargos antes de grabar");
                        return;
                    }
                }
            }
        }

        this.toPrint = true;
    },
    showValues () {
        this.values = true;
        this.$nextTick(function () {
          $('#fee-values').modal('show');
        })
    },
    closeValues () {
        this.values = false;
        $('#fee-values').modal('hide');
    },
  }
}
</script>

