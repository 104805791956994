<template>
  <div class="modal fade" id="inputValue" tabindex="-1" role="dialog" aria-labelledby="inputValueLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{text}}</h5>
        </div>
        <div class="modal-body">
          <input type="text" v-model="value" class="form-control" :class="addclass">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
          <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'inputvalue',
    props: ['default','showinputvalue', 'params', 'text', 'addclass'],
    data  () {
        return {
            value: null,
        }
    },
    mounted () {
        if (this.default) {
            this.value = this.default;
        }
    },
    methods: {
        save (record) {
            this.$emit('save', this.value, this.params);
        },
        close (){
            this.$emit('close');
            $('#inputValue').modal('hide');
        },
    }
}
</script>
