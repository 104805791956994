<template>
  <div class="modal fade" id="selectValue" tabindex="-1" role="dialog" aria-labelledby="inputValueLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{text}}</h5>
        </div>
        <div class="modal-body">
              <selectinput fieldname="value" :fieldOptions="{value: fieldOptions}"
              :current_value.sync="value"></selectinput>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
          <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectinput from '@/components/tools/SelectInput';
export default {
    name: 'selectvalue',
    props: ['default', 'params', 'text', 'fieldOptions'],
    components: {
      selectinput
    },
    data  () {
        return {
            value: null,
        }
    },
    methods: {
        save (record) {
            this.$emit('save', this.value, this.params);
        },
        close (){
            this.$emit('close');
            $('#inputValue').modal('hide');
        },
    }
}
</script>
