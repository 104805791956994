<template>
    <home endpoint="user" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'user',
  components: {
    home,
  },
  data () {
    return {
      params: {
        serverside: false,
        fields: [{name: 'UserName', label: 'Usuario', required: true},
          {name: 'AccessGroupId', relation: 'accessgroup', optionLabels: 'Description'
              , editor: 'select', label: 'Permisos', required: true},
          {name: 'OrganizationCode', relation: 'organization', optionLabels: 'Name'
              , editor: 'select', label: 'Organizatión', required: true},
          {name: 'FirstName', label: 'Nombre'},
          {name: 'LastName', label: 'Apellido'},
          {name: 'LocationId', relation: 'location', optionLabels: 'Name'
              , editor: 'select', label: 'Ubicación', required: true},
          {name: 'Password', type: 'password'},
          {name: 'OrganizationCode', label: 'Organización'},
          {name: 'id', hidden: true},
          {name: 'Closed', editor: 'checkbox', label: 'Cerrado'},
          {name: 'ChangePassword', editor: 'checkbox', label: 'Debe cambiar password'}
          ],
        title: 'Users',
        title2: 'User'
      }
    }
  },
}
</script>

