<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-10">
                <div class="card flight-card" v-for="(segment,sId) of getFlights">
                    <input type="checkbox" class="form-check-input" v-model="selected[sId]"
                        @change="setSelected()" :disabled="move">
                    <a data-toggle="collapse" :data-target="'#segment' + sId" aria-expanded="false" :aria-controls="'#segment' + sId">
                        <div class="card-header card-row">
                            {{getSegmentData(segment)}}
                        </div>
                    </a>
                    <div class="collapse" :id="'segment' + sId" >
                        <div class="card-body pax-segments">
                            <ul class="list-group list-group-flush" >
                                <li class="list-group-item row" v-for="passenger in booking.Passengers">
                                    <a data-toggle="collapse" :data-target="'#segment' + sId + passenger.PassengerID"
                                        aria-expanded="false" :aria-controls="'#segment' + sId + passenger.PassengerID">
                                        <div class="col-12">
                                            <p>{{passenger.LastName}}, {{passenger.FirstName}} {{passenger.PaxType}} {{getLiftStatus(passenger, sId)}}</p>
                                        </div>
                                    </a>
                                    <div class="collapse" :id="'segment' + sId + passenger.PassengerID" >
                                        <div class="col-12">
                                            <p>SSRs: {{getSSRs(passenger, segment)}}</p>
                                        </div>
                                        <div class="col-12">
                                            <p>Bags: {{getBaggageTags(passenger, segment)}}</p>
                                        </div>
                                        <div class="col-12" v-if="segment.Legs[0]">
                                            <p>Asiento: {{getUnitDesinator(passenger, segment)}}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" :disabled="!isSelected" @click="deleteSegments">Eliminar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="addSegments(false)">Agregar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="addSegments(true)"
                    :disabled="countSelected!=1 || !userCanMove">Mover</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="showOverrideFare"
                    :disabled="countSelected!=1 || !userCanOverrideFare || !hasSegmentID">Override</button>
                </div>

            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <flightselect v-if="search" :move="move" :flightkey="getSelectedFlightKey"
            @close="closeSearch" @deletemoved="deleteMoved"></flightselect>
        <inputvalue v-if="showinputvalue" @save="overrideFare" @close="showinputvalue = false" text="Ingresar Monto"
            :default="defValue" addclass="amount-right"></inputvalue>

    </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
import flightselect from '@/components/booking/FlightSelect';
import inputvalue from '@/components/tools/InputValue';
export default {
  name: 'bookingflights',
  components: {
    flightselect,
    inputvalue
  },
  data  () {
    return {
        selected: {},
        search: false,
        showinputvalue: false,
        move: false,
        LiftStatusValues: {
            0: '',
            1: '(CHECKIN)',
            2: '(EMBARCADO)',
            3: '(NO SHOW)',
            4: '(ENDOSADO)'
        },
        userCanMove: false,
        userCanOverrideFare: false,
        defValue: null,

    }
  },
  mounted () {
    this.setSelectedNull();
    this.userCanMove = app.canAccess(this.user, 'api','POST', '/booking/move_flight/', false);
    this.userCanOverrideFare = app.canAccess(this.user, 'api','POST', '/booking/override_fare/', false);
    this.userCanMoveLegs = app.canAccess(this.user, 'api','POST', '/booking/move_flight_legs/', false);
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    getFlights () {
        if (this.booking.Passengers.length>0) return this.booking.Passengers[0].Segments;
        return []
    },
    isSelected (){
        for (let s in this.selected){
            if (this.selected[s]) return true;
        }
    },
    countSelected (){
        let c = 0;
        for (let s in this.selected){
            if (this.selected[s]) c ++;
        }
        return c;
    },
    getSelectedFlightKey () {
        for (let s in this.selected){
            if (this.selected[s]) return this.booking.Passengers[0].Segments[s].SegmentFlightKey;
        }
    },
    hasSegmentID () {
        for (let s in this.selected){
            if (this.selected[s] && this.booking.Passengers[0].Segments[s].SegmentID)
                return true;
        }
    }


  },
  methods: {
    setSelectedNull () {
        this.selected = {};
        for (let s in this.getFlights) {
            this.selected[s] = false;
        }
    },
    getSegmentData (segment){
        let res = app.getSegmentData(segment, true);
        if (segment.Legs[0] && segment.Legs[0].InventoryLeg) {
            res += ' ' + segment.Legs[0].InventoryLeg.LegStatus;
        }
        return res;
    },
    setSelected (){
        this.selected = Object.assign({}, this.selected);
    },
    deleteMoved () {
        this.deleteSegments();
        this.closeSearch();
        this.move = false;
    },
    deleteSegments (){
        for (let s in this.selected){
            if (this.selected[s]) {
                delete this.selected[s];
                this.bookingDeleteSegment(s);
            }
        }
        this.selected = Object.assign({}, this.selected);
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },
    checkSegmentStatus (sId) {
        let segment = this.booking.Passengers[0].Segments[sId];
        let legIDs = [];
        for (let leg of segment.Legs) {
            legIDs.push(leg.InventoryLegID);
        }
        if (legIDs.length>0) {
            for (let pax of this.booking.Passengers) {
                for (let s of pax.Segments) {
                    for (let leg of s.Legs) {
                        if (legIDs.indexOf(leg.InventoryLegID)==-1) continue;
                        if (leg.LiftStatus==1 && !this.userCanMoveLegs) {
                            alert('No se puede eliminar o mover el segmento. Pasajeros chequeados')
                            return false;
                        }
                        if (leg.LiftStatus==2 && !this.userCanMoveLegs) {
                            alert('No se puede eliminar o mover el segmento. Pasajeros embarcados')
                            return false;
                        }
                        if (leg.LiftStatus==4 && !this.userCanMoveLegs) {
                            alert('No se puede eliminar o mover el segmento. Pasajeros endosado')
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    },
    bookingDeleteSegment (sId) {
        //let segment = _.find(this.booking.Passengers[0].Segments, (s) => s.SegmentFlightKey==SegmentFlightKey);
        if (!this.checkSegmentStatus(sId)) return;
        let segment = this.booking.Passengers[0].Segments[sId];
        let legIDs = [];
        for (let leg of segment.Legs) {
            legIDs.push(leg.InventoryLegID);
        }
        if (legIDs.length>0) {
            for (let p of this.booking.Passengers) {
                let i = _.findIndex(p.Segments, function(s) {
                    for (let leg of s.Legs) {
                        if (leg.InventoryLegID==legIDs[0]) return true;
                    }
                });
                if (i>-1) {
                    this.moveFeesToPasenger(p, p.Segments[i]);
                    p.Segments.splice(i, 1);
                }
            }
        } else {
            for (let p of this.booking.Passengers) {
                let i = _.findIndex(p.Segments, function(s) {
                    return segment.SegmentFlightKey==s.SegmentFlightKey;
                });
                if (i>-1) {
                    this.moveFeesToPasenger(p, p.Segments[i]);
                    p.Segments.splice(i, 1);
                }
            }

        }
        app.bookingSumUp(this.booking);
    },
    moveFeesToPasenger (p, s) {
        for (let fee of s.Fees) {
            let info = "";
            if (s.Legs.length>0) {
                info = s.Legs[0].InventoryLeg.FlightNumber;
            }
            info = info + ' ' + s.DepartureStation + '-' + s.ArrivalStation;
            if (s.DepartureDate) {
                info = info + ' ' + moment(s.DepartureDate).locale('es').format('DD MMM YY');
            }
            let newFee = {
                CreatedUserID: fee.CreatedUserID,
                CreatedUTC: fee.CreatedUTC,
                FeeCode: fee.FeeCode,
                FeeDetail: info,
                Note: fee.Note,
                FeeType: fee.FeeType,
                FeeOverride: fee.FeeOverride,
                Status: fee.Status,
                SSRCode: fee.SSRCode,
                SSRNumber: fee.SSRNumber,
                UnitValue: fee.UnitValue,
                PaymentID: fee.PaymentID,
                FeeNumber: app.getNextFeeNumber(p.Fees),
                Charges: fee.Charges,
                ArrivalStation: s.ArrivalStation,
                DepartureStation: s.DepartureStation
            }
            p.Fees.push(newFee);
        }
    },
    addSegments (move) {
        if (move && !this.userCanMove) {
            alert('Sin permiso para esta acción');
            return;
        }
        if (move) {
            for (let s in this.selected){
                if (this.selected[s]) {
                    if (!this.checkSegmentStatus(s)) return;
                }
            }
        }
        this.search = true;
        this.move = move;
        this.$nextTick(function () {
          //$('#flightselect').modal('show')
        })
    },
    getLiftStatus (pax, sId) {
        //let segment = _.find(this.booking.Passengers[0].Segments, (s) => s.SegmentFlightKey==SegmentFlightKey);
        let segment = this.booking.Passengers[0].Segments[sId];
        let legIDs = [];
        for (let leg of segment.Legs) {
            legIDs.push(leg.InventoryLegID);
        }
        if (legIDs.length>0) {
            for (let s of pax.Segments) {
                for (let leg of s.Legs) {
                    if (legIDs.indexOf(leg.InventoryLegID)==-1) continue;
                    return this.LiftStatusValues[leg.LiftStatus];
                }
            }
        }
    },
    getSSRs (pax, s) {
        let res = [];
        for (let segment of pax.Segments){
            if (segment.SegmentFlightKey==s.SegmentFlightKey) {
                for (let fee of segment.Fees) {
                    if (res.indexOf(fee.FeeCode)==-1) {
                        res.push(fee.FeeCode);
                    }
                }
                for (let leg of segment.Legs) {
                    if (leg.SSRs) {
                        for (let ssr of leg.SSRs) {
                            if (res.indexOf(ssr.SSRCode)==-1) {
                                res.push(ssr.SSRCode);
                            }
                        }
                    }
                }
            }
        }
        return res.join(', ');
    },
    getUnitDesinator (pax, s) {
        let res = [];
        for (let segment of pax.Segments){
            if (segment.SegmentFlightKey==s.SegmentFlightKey) {
                if (segment.Legs[0]) return segment.Legs[0].UnitDesignator;
            }
        }
        return '';
    },

    getBaggageTags (pax, s) {
        let res = []
        let segment = _.find(pax.Segments, (r)=> r.SegmentFlightKey==s.SegmentFlightKey);
        if (!segment) return '';
        for (let bag of pax.Baggages) {
            let f = _.find(bag.LegBaggages, function(b) {
                if (segment.Legs.length>0) {
                    return b.SegmentID==segment.SegmentID && b.LegNumber==segment.Legs[0].LegNumber && b.BaggageStatus!=2;
                }
            })
            if (f) {
                res.push(bag.OSTag + ' / ' + bag.Weight + ' Kg')
            }
        }
        return res.join(', ');
    },

    closeSearch () {
        this.search = false;
        this.setSelectedNull();
        this.move = false;
        //this.$emit("update:booking", Object.assign({}, this.booking));
        //$('#flightselect').modal('hide');
    },
    showOverrideFare () {
      if (!this.showOverrideFare) {
          alert('Sin permiso para esta acción');
          return;
      }
      this.showinputvalue = true;
      let k;
      for (let i in this.selected) {
        if (this.selected[i]) {
            k = i;
        }
      }
      let segment = this.booking.Passengers[0].Segments[k];
      if (!segment) return;
      let fare;
      for (let charge of segment.Charges) {
        if (charge.ChargeCode=='') fare = charge.ChargeAmount;
        break
      }
      if (fare) this.defValue = fare;
      this.$nextTick(function() {
        $('#inputValue').modal('show');
      })
    },
    overrideFare: async function(value) {
      let k;
      for (let i in this.selected) {
        if (this.selected[i]) {
            k = i;
        }
      }
      let segment = this.booking.Passengers[0].Segments[k];
      if (!segment) return;
      for (let pax of this.booking.Passengers) {
        for (let s of pax.Segments) {
            if (s.SegmentFlightKey==segment.SegmentFlightKey) {
                await this.applyFare(s, parseFloat(value));
            }
        }
      }
      app.bookingSumUp(this.booking);
      $('#inputValue').modal('hide');
      this.showinputvalue = false;
      this.defValue = null;
    },
    applyFare: async function(s, value) {
        let data = {
            DepartureDate: s.DepartureDate,
            DepartureStation: s.DepartureStation
        }
        let tax_settings = await app.get('/api/booking/get_tax_settings', data);
        if (tax_settings) {
            for (let charge of s.Charges) {
                if (charge.ChargeCode==null) {
                    charge.ChargeAmount = value;
                    charge.ForeignAmount = value;
                } else if (this.tax_settings.Fees[charge.ChargeCode]) {
                    charge.ChargeAmount = value * this.tax_settings.Fees[charge.ChargeCode];
                    charge.ForeignAmount = value * this.tax_settings.Fees[charge.ChargeCode];
                }
            }
        }
    }

  }
}
</script>

