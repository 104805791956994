<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-12">
                <div class="card">
                    <a>
                        <div class="card-header" style="min-height: 160px">
                            <div>
                                <b>VUELOS</b>
                                <b v-if="checkSegmentsFlights" style="color: red;">CANTIDAD INCORRECTA DE SEGMENTOS EN VUELO {{checkSegmentsFlights}}</b>
                                <hr></hr>
                            </div>
                            <div v-for="segment in getFlights">
                                {{getSegmentData(segment)}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="spacer"></div>
                <div class="card">
                    <a>
                        <div class="card-header" style="min-height: 160px">
                            <div>
                                <b>PASAJEROS</b>
                                <hr></hr>
                            </div>
                            <div v-for="pax in booking.Passengers">
                                {{getPassengerData(pax)}}
                                <div v-if="getExtraData(pax)" class="pax-extras"> {{getExtraData(pax)}} </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="spacer"></div>
                <div class="card">
                    <a>
                        <div class="card-header" style="min-height: 160px">
                            <div>
                                <b>CONTACTO</b>
                                <hr></hr>
                            </div>
                            <div v-if="this.booking.ContactInformation[0].FirstName">
                                {{this.booking.ContactInformation[0].FirstName}}
                                {{this.booking.ContactInformation[0].LastName}}
                            </div>
                            <div v-if="this.booking.ContactInformation[0].EmailAddress">
                                {{this.booking.ContactInformation[0].EmailAddress}}
                            </div>
                            <div v-if="this.booking.ContactInformation[0].HomePhone">
                                {{this.booking.ContactInformation[0].HomePhone}}
                            </div>
                            <div v-if="this.booking.ContactInformation[0].WorkPhone">
                                {{this.booking.ContactInformation[0].WorkPhone}}
                            </div>
                            <div v-if="this.booking.ContactInformation[0].OtherPhone">
                                {{this.booking.ContactInformation[0].OtherPhone}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="spacer"></div>
                <div class="card">
                    <a>
                        <div class="card-header" style="min-height: 160px">
                            <div>
                                <b>PAGOS</b>
                                <hr></hr>
                            </div>
                            <div v-for="payment of booking.Payments">
                                {{getPaymentData(payment)}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="spacer"></div>
                <div class="card">
                    <a>
                        <div class="card-header" style="min-height: 160px">
                            <div>
                                <b>COMENTARIOS</b>
                                <hr></hr>
                            </div>
                            <div v-for="comment of booking.Comments">
                                {{comment.CommentText}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'bookingsummary',
  components: {
  },
  data  () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    getFlights () {
        if (this.booking.Passengers.length>0) return this.booking.Passengers[0].Segments;
        return []
    },
    checkSegmentsFlights () {
        let res = null;
        if (this.booking.Passengers.length==0) return res;
        let r = {}
        for (let pax of this.booking.Passengers) {
            for (let s of pax.Segments) {
                let key = s.DepartureDate + '_' + s.DepartureStation + '_' + s.ArrivalStation;
                if (!r[key]) r[key] = [];
                let c;
                if (!s.Legs) {
                    c = 0;
                } else {
                    c = s.Legs.length;
                }
                if (r[key].indexOf(c)==-1) r[key].push(c)
            }
        }
        for (let key in r) {
            if (r[key].length>1) {
                return key;
            }
        }
    }
  },
  methods: {
    getSegmentData (segment){
        return app.getSegmentData(segment, true);
    },
    getPassengerData (pax) {
        return app.getPassengerData(pax);
    },
    getExtraData (pax) {
        return app.getExtraData(pax);
    },
    getPaymentData (payment) {
        return moment(payment.CreatedUTC).format('DD MMM YY') + ' '
            + payment.PaymentMethod.Name + ' ' + payment.CurrencyCode + ' ' + parseFloat(payment.PaymentAmount).toFixed(2)
            + '   (' + app.getAuthorizationStatusNames()[payment.AuthorizationStatus] + ')';
    }
  },
}
</script>

