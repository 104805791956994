<template>
  <div class="modal fade bd-example-modal-lg" id="holdtime" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body form-row">
            <div class="col-md-2">
                <label for="date">Fecha</label>
                <datepicker id="date" :def.sync="date"></datepicker>
            </div>
            <div class="col-md-2">
                <label for="field.name" >Hora</label>
                <input v-model="time" class="form-control" type="text">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import datepicker from '@/components/tools/DatePicker'
var moment = require('moment');
export default {
  name: 'holdtime',
  components: {
    datepicker
  },
  data  () {
    return {
        time: null,
        date: null,
    }
  },
  mounted: async function() {
     if (this.booking.HoldUTC) {
         this.time = moment.utc(this.booking.HoldUTC).local().format('HH:mm');
         this.date = moment.utc(this.booking.HoldUTC).local().format('YYYY-MM-DD');
     } else {
         this.time = moment(new Date()).format('HH:mm');
         this.date = moment(new Date()).format('YYYY-MM-DD');
     }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    save () {
        let d = this.date + ' ' + this.time;
        let datetime = moment(d).utc().format('YYYY-MM-DDTHH:mm:ss');
        this.booking.HoldUTC = datetime;
        this.booking.Status = 1;
        this.$emit('close');
    }
  },
}
</script>

