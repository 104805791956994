<template>
    <home endpoint="accessgroup" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'accessgroup',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [{name: 'id', hidden: 'true'},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          {name:'Description', label: 'Descripción'},
          {name:'Permissions', label: 'Permisos', defValue: {}},
          {name:'permissions', label: 'Permisos', defValue: {}}
          ],
        title: 'Perfiles de Usuario',
        title2: 'Perfil de Usuario',
        modaltarget: 'accessgroupwindow'
      }
    }
  },
}
</script>

