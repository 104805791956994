<template>
  <div class="modal fade" id="settings-window" tabindex="-1" role="dialog" aria-labelledby="inputValueLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Opciones</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                  <selectinput fieldname="printerType" :fieldOptions="fieldOptions" label="Tipo de Impresora"
                  :current_value.sync="printerType" @change="setPrinterType"></selectinput>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                  <selectinput fieldname="printDirect" :fieldOptions="fieldOptions" label="Boarding Pass sin Vista Previa"
                  :current_value.sync="printDirect" @change="setPrintDirect"></selectinput>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectinput from '@/components/tools/SelectInput';
export default {
    name: 'settingswindow',
    components: {
      selectinput
    },
    data  () {
        return {
            printerType: null,
            printDirect: null,
            fieldOptions: {
                printerType:
                    [
                        {value: 'default', label: 'Normal'},
                        {value: 'ticket', label: 'Bordinera'},
                    ],
                printDirect:
                    [
                        {value: '0', label: 'Vista Previa'},
                        {value: '1', label: 'Sin Vista Previa'},
                        {value: '2', label: 'Servicio de Impresion'},
                    ]

            }
        }
    },
    mounted () {
        this.printerType = localStorage.getItem("printerType");
        this.printDirect = localStorage.getItem("printDirect");
    },
    methods: {
        close (){
            this.$emit('close');
            $('#inputValue').modal('hide');
        },
        setPrinterType (value) {
            localStorage.setItem("printerType", value);
        },
        setPrintDirect (value) {
            localStorage.setItem("printDirect", value);
        }
    }
}
</script>
