<template>
  <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmDeleteLabel">Confirmar</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Elimar registro?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Cancelar</button>
          <button type="button" class="btn btn-danger" @click="remove">Eliminar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'confirmdelete',
    props: [],
    methods: {
        remove (record) {
            $('#confirmDelete').modal('hide');
            this.$emit('confirmRemove')
        },
        close (){
            $('#confirmDelete').modal('hide');
        }
    }
}
</script>
