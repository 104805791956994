<template>
  <div class="modal fade printable" id="boadings-print" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
        <div class="modal-body font-16 mr-0 pr-0" id="print-content" v-if="ready">
            <div class="form-row mb-3 mr-0 pr-0">
                <div class="col-12 form-row not-break-inside print-page mr-0 pr-0 mb-3" v-for="(rows, i) of dList">
                    <div class="col-12 form-row mb-1 boarding-travel mb-3" v-for="row of rows">
                        <div class="form-row col-8 border">
                          <div class="form-row fix-margins h-100">
                            <div class="col-8 text-center form-row pt-3">
                                <div class="col-3 mb-3 font-18">
                                    {{row.Orden}}
                                </div>
                                <div class="col-9 font-18">
                                    BOARDING PASS <span v-if="row.INF">INFANTE</span>
                                </div>
                                <div class="col-6">
                                    Nombre del Pasajero
                                </div>
                                <div class="col-6">
                                    DNI
                                </div>
                                <div class="col-6">
                                    <span v-if="row.Nombre || row.Apellido">{{row.Apellido}}, {{row.Nombre}} </span>
                                </div>
                                <div class="col-6">
                                    {{row.Documento}}
                                </div>
                            </div>
                            <div class="col-4 pt-3">
                                <img src="@/img/logo3.png" height="50">
                            </div>
                            <div class="col-12 mt-3 fix-margins px-0">
                                <table class="table table-striped table-sm table-bordered mb-0 font-16">
                                    <thead>
                                        <tr class="table-secondary">
                                            <th>Itinerario/Ruta</th>
                                            <th>Vuelo</th>
                                            <th>Salida</th>
                                            <th>Hora</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span v-if="row.DepartureStation">{{row.DepartureStation}}
                                                ({{row.DepartureCity}})
                                                / {{row.ArrivalStation}} ({{row.ArrivalCity}}) </span>
                                            </td>
                                            <td>{{row.FlightNumber}}</td>
                                            <td>{{row.DepartureDate | getDateFormat('DD/MM/YYYY')}}</td>
                                            <td>{{row.STD}}</td>
                                            <td>OK</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-center font-10 mb-0">
                                No transferible. No reembolsable. Válido para viajes sólo en las rutas y fechas indicadas. Embarque sujeto a documentación en regla.
                            </div>
                            <div class="col-6 text-center border-right border-top">
                                <img :src="customerLogo(row)" height="50">
                                <div class="border-top">
                                    <span class="mr-5">CONTINGENTE {{row.Comment}}</span>
                                </div>
                            </div>
                            <div class="col-6 text-center border-top px-0">
                                <table class="table table-striped table-sm table-bordered mb-0 h-100 font-16">
                                    <thead>
                                        <tr class="table-secondary">
                                            <th>Puerta</th>
                                            <th>Hora de Embarque</th>
                                            <th>Asiento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>{{row.Asiento}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                          </div>

                        </div>
                        <div class="col-4 border text-center">
                            <div class="form-row fix-margins h-100">
                                <div class="col-9 pt-3">
                                    <img src="@/img/logo3.png" height="50">
                                </div>
                                <div class="col-3 mb-3 font-18">
                                    {{row.Orden}}
                                </div>
                                <div class="col-12 border-top back-grey">
                                    Apellido y Nombre
                                </div>
                                <div class="col-12 border-top col-names">
                                    <span v-if="row.Apellido || row.Nombre">{{row.Apellido}}, {{row.Nombre}}</span>
                                </div>
                                <div class="col-4 border-top back-grey">
                                    Destino
                                </div>
                                <div class="col-4 border-top back-grey">
                                    Asiento
                                </div>
                                <div class="col-4 border-top back-grey">
                                    Vuelo
                                </div>
                                <div class="col-4 col-names">
                                    {{row.ArrivalCity}}
                                </div>
                                <div class="col-4 col-names">
                                    {{row.Asiento}}
                                </div>
                                <div class="col-4 col-names">
                                    {{row.FlightNumber}}
                                </div>
                                <div class="col-6 border-top back-grey">
                                    Hora Límite
                                </div>
                                <div class="col-6 border-top back-grey">
                                    Puerta de Embarque
                                </div>
                                <div class="col-6">
                                    &nbsp;
                                </div>
                                <div class="col-6">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="page-break" style="page-break-after: always;">&nbsp;</p>
                    <p class="page-break" style="page-break-before: always;">&nbsp;</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
export default {
    name: 'boardings-print',
    props: ['list', 'show'],
    mixins: [escape],
    data () {
        return {
            ready: false,
            dList: [],
        }
    },
    async mounted () {
        this.ready = true;
        let newList = Object.assign([], this.list);
        let infants = _.filter(newList, (r) => r.Infant);
        for (let infant of infants) {
            newList.splice(infant.Orden, 0, infant.Infant)
        }
        this.dList = _.chunk(newList, 4);
        $('#boadings-print').modal('show');
        await this.$nextTick()
    },
    computed: {
    },
    methods: {
        print () {
            window.print();
        },
        close () {
            this.$emit('update:show', false);
            $('#boadings-print').modal('hide');
        },
        customerLogo (row) {
            let src = 'logo3.png';
            if (row.Logo) src = row.Logo;
            let img = require(`@/img/${src}`);
            return img;
        }
    },
}
</script>

<style scoped>
    .col-names {
        min-height: 30px;
    }
</style>