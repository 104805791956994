<template>
    <home endpoint="tax" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
var moment = require('moment');
export default {
  name: 'tax',
  components: {
    home,
  },
  data  () {
    let self = this;
    return {
      params: {
        fields: [
          {name: 'id', hidden: 'true'},
          {name: 'TaxCode', label: 'Código', required: true},
          {name: 'DepartureStation', label: 'Aeropuerto', editor: 'select', addBlank: true,
              relation: 'station', optionLabels: 'id'},
          {name: 'StartDate', label: 'Inicio', required: true, editor: 'date'},
          {name: 'EndDate', label: 'Fin', editor: 'date'},
          {name: 'Amount', label: 'Monto', editor: 'number', decimal: 6},
          {name: 'CurrencyId', label: 'Moneda', editor: 'select', required: true,
              relation: 'currency', optionLabels: 'id'},
          {name: 'TaxType', label: 'Tipo', editor: 'select', required: true,
              options: [
                    {value: 'TAX', label: 'Valor Fijo'},
                    {value: 'FEE', label: 'Porcentaje'},
                    ]},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'}
          ],
        title: 'Tasas e Impuestos',
        title2: 'Tasa/Impuestos',
      }
    }
  },
}
</script>

