<template>
    <home endpoint="paymentmethod" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'paymentmethod',
  components: {
    home,
  },
  data () {
    return {
      params: {
        primaryKey: 'PaymentMethodCode',
        fields: [
          {name: 'PaymentMethodCode', label: 'Código', required: true},
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'PaymentMethodType', label: 'Tipo de Pago', editor: 'select', required: true,
              options: [
                    {value: 0, label: 'CONTADO'},
                    {value: 1, label: 'EXTERNO/TARJETA'},
                    {value: 2, label: 'CUENTA CORRIENTE AGENCIA'},
                    {value: 3, label: 'CUENTA CLIENTE'},
                    {value: 4, label: 'VOUCHER'},
                    {value: 5, label: 'LOYALTY'},
                    {value: 6, label: 'TARJETA'},
                    ]},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          ],
        title: 'Métodos de Pago',
        title2: 'Métodos de Pago'
      }
    }
  },
}
</script>

