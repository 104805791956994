import $ from 'jquery'
import jQuery from 'jquery'
//const _ = require("underscore");
import * as _ from 'lodash';
var moment = require('moment');
const uuidv1 = require('uuid/v1');
import flightFares from '@/components/tools/FlightFares';


const serverurl = process.env.SERVER_ENDPOINT;

;(function ($) {

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

class App {

    inintConstructor() {
	    this.loadingQueue = 0;
        this.data = {}
        this.user = null;
    }

    loading(hide = false) {
        hide ? this.loadingQueue--:this.loadingQueue++;
        this.loadingQueue == 0 ? jQuery(".loading").addClass("is-hidden"):jQuery(".loading").removeClass("is-hidden");
    }

    async getUser() {
        var user = await this.get('/current_user');
        if (user) {
            user.original_id = user.id;
            this.user = user;
            return user;
        }
    }

    canAccess(user, type, method, endpoint, defValue=true) {
        if (!user || !user.AccessGroup) return true;
        let p = user.AccessGroup.permissionsMerge || {};
        if (typeof p[type] === 'boolean') return p[type]
        if (defValue){
            var def = (((p[type] || {}).defaults || {}).methods || {})[method];
            if (def===undefined) def = true;
        }else{
            var def = false;
        }
        if (!endpoint) return def
        endpoint = endpoint.replace(/(<[^:]+)?:[^>/]+>?/g, ':p')
        var perm = ((p[type] || {})[endpoint] || {}).methods
        if (typeof perm === 'object') {
            return (typeof perm === 'undefined') ? def: perm[method]
        }
        if (typeof perm === 'boolean'){
            return perm
        }
        if (!perm) {
            return def
        }
        return (typeof perm[method] === 'undefined') ? def: perm[method]
    }

    async get(url, params) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: serverurl + url,
                data: params,
                method: 'get',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: function (result, status, xhr) {
                    resolve(result);
                },
                error: function (error) {
                    if (error.status==401) {
                        window.location.replace(window.location.origin + '/#/login');
                    } else if (error.status==400){
                        alert(error.responseText);
                    } else{
                        reject(error);
                    }
                }
            })
        })
    }

    postJSON(url, json, self) {
        if (self) self.sending = true;
        return new Promise((resolve, reject) => {
            $.ajax( {
                method: 'post',
                data: JSON.stringify(json),
                crossDomain: true,
                contentType: "application/json",
                xhrFields: {
                    withCredentials: true
                },
                url: serverurl + url,
                success: function (result) {
                    if (self) self.sending = false;
                    resolve(result)
                },
                error: function (error) {
                    if (self) self.sending = false;
                    if (self) self.processing = false;
                    if (error.status==401) {
                        window.location.replace(window.location.origin + '/#/login');
                    } else if (error.status==400){
                        alert(error.responseText);
                    } else if (error.status==403){
                        alert('Sin permiso para esta acción');
                    } else{
                        alert(error.responseText)
                        reject(error);
                    }
                }, timeout: 600000
            })
        })
    }

    postMultipart(url, data, self) {
        return new Promise((resolve, reject) => {

            fetch(serverurl + url, { // Your POST endpoint
                method: 'POST',
                mode:'cors',
                credentials: 'include',
                headers: {
                  // Content-Type may need to be completely **omitted**
                  // or you may need something
                  contentType: "multipart/form-data"
                },
                body: data // This is your file object
            }).then(
            response => response.json() // if the response is a JSON object
            ).then(
            success => resolve(success) // Handle the success response object
            ).catch(
            error => {console.log(error);reject(error);} // Handle the error response object
            );
        })
    }

    /*
    put(url, json) {
        return new Promise((resolve, reject) => {
            $.ajax( {
                method: 'put',
                data: JSON.stringify(json),
                contentType: "application/json",
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                url: serverurl + url,
                success: function (result) {
                    resolve(result)
                },
                error: function (error) {
                    if (error.status==401) {
                        window.location.replace(window.location.origin + '/#/login');
                    } else if (error.status==400){
                        alert(error.responseText);
                    } else{
                        reject(error);
                    }
                }
            })
        })
    }

    del(url, json) {
        return new Promise((resolve, reject) => {
            $.ajax( {
                method: 'delete',
                data: JSON.stringify(json? json: {}),
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                contentType: "application/json",
                url: serverurl + url,
                success: function (result) {
                    resolve(result)
                },
                fail: function (error) {
                    reject(error)
                }
            })
        })
    } */

    async getCities() {
        return this.get('/api/city/')
    }

    async getServices() {
        return this.get('/api/service/')
    }


    mergeDateAndTime(d, t, z = 'utc'){
        if (!d) return '';
        if(z == 'local')
            return moment.utc(d+' '+t, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DDTHH:mm:ss');
        return moment(d+' '+t, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
    }

    getDate (d) {
        if (!d) return '';
        return moment(d).format("DD MMM YYYY");
    }

    getDateFormat (d, format) {
        if (!d) return '';
        return moment(d).format(format);
    }

    getTime (t) {
        if (!t) return '';
        return moment(t).format("HH:mm:ss");
    }

    getLocalDate (d) {
        if (!d) return '';
        return moment.utc(d).local().locale('es').format('DD MMM YYYY')
    }

    getLocalTime (d) {
        if (!d) return '';
        return moment.utc(d).local().locale('es').format('HH:mm')
    }

    getLocalDateTime (d) {
        if (!d) return '';
        return moment.utc(d).local().locale('es').format('DD MMM YYYY HH:mm')
    }

   	dateIsAfter(date1, date2){
   		// compare if date1 is after date2
   		if(moment(date1).isAfter(date2))
   			return true;
   		return false;

   	}

   	diffHours(date1, date2){
   		return moment(date1).diff(moment(date2))/3600000;
   	}


    remove(self, record) {
        if (!self.canDelete || !self.canEdit){
            alert('1. Sin permiso para esta acción');
            return;
        }
        self.processing = true;
        let original_id = record.original_id;
        if (!original_id) original_id = record[self.primarykey] ? record[self.primarykey]: record.id;
        $.ajax({
            url: serverurl + self.endpoint,
            method: 'post',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            data: {remove: true, id: original_id},
            success: function (result) {
                self.$emit("remove", result.id)
                self.$emit("notification","Registro Eliminado");
                self.$emit("close");
                self.processing = false;
            },
            error: function(error) {
                if (error.status==401) {
                    window.location.replace(window.location.origin + '/#/login');
                } else if (error.status==400) {
                    alert(error.responseText)
                    //self.addError(error.responseText)
                    self.processing = false;
                } else {
                    self.processing = false;
                    alert(error)
                    reject(error)
                }
            }
        })
    }

    save (self, record) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: serverurl + self.endpoint,
                method: 'post',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                data: {data: JSON.stringify(record)},
                success: function (result) {
                    resolve(result)
                },
                error: function(error) {
                    if (error.status==401) {
                        window.location.replace(window.location.origin + '/#/login');
                    } else if (error.status==400) {
                        alert(error.responseText)
                        //self.addError(error.responseText)
                        self.processing = false;
                    } else {
                        self.processing = false;
                        alert(error.responseText)
                        reject(error)
                    }
                }
            })
        })
    }

    async saveRecord (self, record, modalId, record_name) {
        if (!record_name) record_name = 'current';
        let required = this.checkFields(self, record_name);
        if (required) return;
        self.processing = true;
        let result = await app.save(self, record);
        if (result) {
            Object.assign(record, result);
            self[record_name].original_id = record[self.primarykey] ? record[self.primarykey]: record.id;
            self.$emit("notification", "Registro Grabado");
            self.$emit("updatecurrent", self[record_name]);
            self.$emit("close");
            $(modalId).modal('hide')
            self.processing = false;
        }
    }

    myfields (fields) {
        let res = []
        for (let f of fields) {
            if (f.name) {
                let ff = _.cloneDeep(f)
                ff.label = f.label? f.label : f.name;
                ff.sortorder = 1;
                ff.editor = f.editor ? f.editor : 'text';
                ff.type = f.type ? f.type: 'text';
                if (f.classes) ff.classes ? f.classes : '';
                res.push(ff);
            } else {
                res.push({name: f, label: f, editor: 'text', sortorder: 1, classes: f == 'Color'? 'colorpicker': '', iscolor: f == 'Color'? true : false });
            }
        }
        return res;
    }

    downloadReport (id) {
        let table = document.getElementById(id);
        let worksheet = table.innerHTML;
        this.download('descarga.xls',worksheet)
    }


    download (filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + escape(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    async calculateFieldOptionsByField (self, field, fo, serverside) {
        if (field.options) {
            //fo[field.name] = _.map(field.options, (o) => {return {label: o.label, value: o.value}})
            fo[field.name] = _.map(field.options, function(o) {
                let value = o;
                let label = o;
                let bgcolor = null;
                let color = '#000000';
                if (o.value!=null && o.value!=undefined) value = o.value
                if (o.label) label = o.label
                if (o.bgcolor) bgcolor = o.bgcolor
                if (o.color) color = o.color
                return {label: label, value: value, bgcolor: bgcolor, color: color}
            })
        } else if (field.relation && !serverside) {
            let recs = await this.fetchTable(self, '/api/' + field.relation + '/')
            let opts = []
            for (let rec of recs) {
                opts.push({label: rec[field.optionLabels], value: rec[field.id] || rec.id})
            }
            fo[field.name] = opts
        }
    }

    async calculateFieldOptions (self, serverside, fields) {
        let fo = {}
        let fieldsList;
        if (!fieldsList) fieldsList = self.myfields;
        for (let field of fieldsList) {
            await this.calculateFieldOptionsByField (self, field, fo, serverside)
            if (Array.isArray(field.editor) && Array.isArray(field.rowFields)) {
                for (let rowfield of field.rowFields) {
                    await this.calculateFieldOptionsByField (self, rowfield, fo, serverside)
                }
            }
        }
        return fo;
    }

    async fetchTable (self, endpoint, filters) {
        self.processing = true;
        if (self.hasClosed) {
            endpoint = endpoint + "?IncludeClosed=" + self.showClosed;
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                url: serverurl + endpoint,
                data: {filters: JSON.stringify(filters)},
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: function (result) {
                    let list=result
                    resolve(list);
                    self.processing = false;
                },
                error: function(error) {
                    if (error.status==401) {
                        window.location.replace(window.location.origin + '/#/login');
                    } else if (error.status==400) {
                        alert(error.responseText)
                    } else {
                        reject(error)
                    }

                }
            })
        })
    }

    async fetchList (self, filters, endpoint) {
        let e = endpoint || self.endpoint;
        this.processing = true;
        let list = await this.fetchTable(self, e, filters);
        if (list){
            self.datalist = _.each(list, (c) => {
                c.original_id = c[self.params.primaryKey] ? c[self.params.primaryKey]: c.id
            })
            this.processing = false;
        }
    }

    addNew (self) {
        let c = {}
        for (let f of self.myfields) {
            if (Array.isArray(f.editor)){
                c[f.name] = [];
            }else{
                c[f.name] = null;
                if (f.defValue) c[f.name] = f.defValue;
            }
        }
        self.datalist.push(c)
        self.current = c
    }

    addCopy (self, record) {
        self.datalist.push(record)
        self.current = record
    }

    clearCurrent (self){
        let id = self.params.primaryKey ? self.params.primaryKey: 'id';
        self.datalist = _.filter(self.datalist, (c) => c[id] != null);
        self.current = null;
    }

    updateField (self, fieldname, value, id){
        idx = _.findIndex(self.datalist, (c) => c.id == id)
        if (idx>-1){
            self.datalist[idx][fieldname] = value;
        }
    }

    updateCurrent (self, record){
        let primaryKey = self.params.primaryKey ? self.params.primaryKey: 'id';
        let idx = _.findIndex(self.datalist, (c) => c[primaryKey] == record[primaryKey])
        if (idx>-1){
            self.datalist[idx] = record;
        }else{
            self.datalist.push(record)
        }
    }

    toggleSort (self, field){
        if (self.sortfield === field) {
            field.sortorder = -field.sortorder
        }
        self.sortfield = field;
    }


    getDisplayValue (fieldOptions, record, field, serverside) {
        if (record[field.name]==null || record[field.name]==undefined) return '';
        if (record[field.name]===true) return 'SI'
        if (record[field.name]===false) return 'NO'
        if (field.dateformat && record[field.name]) {
            return moment(record[field.name]).format(field.dateformat);
        }
        if (field.relation || field.options) {
            if (serverside) {
                let key = field.name + '_' + field.optionLabels;
                if (record[key]) return record[key];
            }
            if (!fieldOptions[field.name]) return null
            let opt = _.find(fieldOptions[field.name], (opt) => opt.value == record[field.name])
            if (opt) return opt.label
            return "NOT FOUND"
        }
        return ""+record[field.name]
    }

    abmList (self) {
        if (self.sortfield) {
            self.datalist.sort((a,b) => {
                return self.getDisplayValue(a, self.sortfield).localeCompare(self.getDisplayValue(b, self.sortfield)) * self.sortfield.sortorder
            })
        }
        if (self.search) {
            let values = self.search.split(' ')
            return _.filter(self.datalist, (r) => {
                for (let value of values){
                    let found = false;
                    let re = new RegExp(value, 'i')
                    for (let f of self.myfields) {
                        var displayValue = self.getDisplayValue(r, f)
                        if (!displayValue) displayValue = r[f]
                        if (displayValue){
                            let m = displayValue.match(re)
                            if (m) found = true;
                        }
                    }
                    if (!found) return false;
                }
                return true;
            })
        }
        return self.datalist
    }

    updateCurrentColor (self){
        try{
            let elements = jQuery(".colorpicker");
            let defaultColor = typeof self.current != 'undefined'? self.current.Color : 'rgba(63, 63, 191, 0.80)';
            if (elements.spectrum) {
                elements.spectrum({
                    preferredFormat: "hex",
                    showAlpha: true,
                    clickoutFiresChange: true,
                    showInput: true,
                    color: defaultColor,
                    change: function(color) {
                        self.currentColor = color.toRgbString();
                    }
                });
            }
        }catch(err){
            //nothing happens
        }
    }

    toggleSortTable (self,data,i){
        if (self.sortcol==i){
            self.sortorder = -self.sortorder;
        }else{
            self.sortorder = 1;
        }
        self.sortcol = i;
        if (self.sortorder==1){
            data.rows.sort(function (a, b) {
                if (a[i] && a[i].value){
                    if (a[i].value > b[i].value) return 1;
                    if (a[i].value < b[i].value) return -1;
                }else{
                    if (a[i] > b[i]) return 1;
                    if (a[i] < b[i]) return -1;
                }
                return 0;
            });
        } else {
            data.rows.sort(function (b, a) {
                if (a[i] && a[i].value){
                    if (a[i].value > b[i].value) return 1;
                    if (a[i].value < b[i].value) return -1;
                }else{
                    if (a[i] > b[i]) return 1;
                    if (a[i] < b[i]) return -1;
                }
                return 0;
            });
        }
    }

    getValue (v){
        if (v && v.value!=undefined && v.value!=null){
            return v.value;
        }
        return v;
    }

    getBGColor (v){
        if (v && v.bgcolor) return v.bgcolor;
        return null;
    }

    getAlign (v){
        if (v && v.align) return v.align;
        return 'left';
    }

    checkFields (self, record_name){
        if (!record_name) record_name = 'current';
        let required = false;
        for (let p in self.myfields){
            if (Array.isArray(self.myfields[p].editor)) {
                for (let k in self[record_name][self.myfields[p].name]) {
                    let row = self[record_name][self.myfields[p].name][k]
                    if (!self.myfields[p].rowFields) continue
                    for (let rowfield of self.myfields[p].rowFields) {
                        let rowNr = k;
                        if (row.rowNr) rowNr = row.rowNr;
                        let rowfieldname = self.myfields[p].name + '-' + rowfield.name + '-' + rowNr;
                        if (rowfield.editor=='date') rowfieldname = 'date-picker-input' + rowfieldname;
                        let div = $("#" + rowfieldname)[0];
                        if (!div) continue
                        if (div && div.classList) div.classList.remove('is-invalid')
                        self.getInvalidClass[self.myfields[p].name] = false;
                        if (rowfield.required && (row[rowfield.name]==null || row[rowfield.name]==undefined)) {
                            div.classList.add('is-invalid')
                            self.getInvalidClass[self.myfields[p].name] = true;
                            required = true;
                        }
                    }
                }
            } else {
                let fname = self.myfields[p].name;
                if (self.myfields[p].editor=='date') fname = 'date-picker-input' + fname;
                let div = $("#" + fname)[0]
                if (!div) div = $("input[name=" + fname + "]")[0];
                if (div && div.classList) div.classList.remove('is-invalid')
                self.getInvalidClass[fname] = false;
                let value = self[record_name][self.myfields[p].name];
                if ((value==null || value==undefined) && self.myfields[p].required){
                    div.classList.add('is-invalid')
                    self.getInvalidClass[fname] = true;
                    required = true;
                }
            }
        }
        self.getInvalidClass = Object.assign({}, self.getInvalidClass);
        return required;
    }

    addError (self, msg) {
        self.errors.push(msg)
        setTimeout(() => {
            let idx = _.findIndex(self.errors, (i) => {return i === msg});
            if (idx>=0) self.errors.splice(idx,1)
        }, 6000)
    }

    addEscapeKey (self, modalId){
        $(modalId).bind("keydown" ,function(event) {
            if (event.keyCode == "27") {
                self.$emit('close');
            }
        });
    }

    addNotification (self, msg, timeout) {
        let n = {msg: msg}
        self.notifications.push(n)
        if (timeout) {
            setTimeout(() => {
                let idx = _.findIndex(self.notifications, (i) => {return i === n});
                if (idx>=0) self.notifications.splice(idx,1)
            }, 6000)
        }
    }

    setAutoSizeTextArea (t){
        t.addEventListener('keyup', function() {
            app.autoSizeTextArea(this);
        }, false);
    }

    autoSizeTextArea (self){
        //setTimeout(() => {
            self.style.overflow = 'hidden';
            self.style.height = 0;
            self.style.height = self.scrollHeight + 'px';
            if (parseInt(self.style.height)<40) self.style.height = '40px';
       //}, 100)
    }

    arrayFields (myfields) {
        let res = [];
        for (let f of myfields) {
            if (Array.isArray(f.editor)){
                res.push(f);
            }
        }
        return res;
    }

    addLine (self, fieldname){
        let line = {}
        for (let row of self.arrayFields){
            if (row.name==fieldname){
                for (let frow of row.rowFields){
                    line[frow.name] = null;
                }
            }
        }
        self.current[fieldname].push(line);
    }

    callAction (callback, record) {
        callback(record)
    }

    vueTableText () {
        return {
           count: "Mostrando {from} hasta {to} de {count} registros|{count} registros|Un registro",
           first: 'Primero',
           last: 'Último',
           filter: "Filtro:",
           filterPlaceholder: "Buscar",
           limit: "Registros por página:",
           page: "Página:",
           noResults: "Sin resultados",
           filterBy: "Filtrar por {column}",
           loading: 'Cargando...',
           defaultOption: 'Seleccionar {column}',
           columns: 'Columnas'
        }
    }

    setSTD (row, FlightDate) {
        if (row.STDTime && FlightDate) {
            let m = moment.duration(row.STDTime).asMinutes();
            row.STD = moment(FlightDate, "YYYY-MM-DD").add(m, 'minutes').format("YYYY-MM-DDTHH:mm:ss")
            if (row.STDTime && row.STATime<row.STDTime) {
                row.STA = moment(FlightDate, "YYYY-MM-DD").add(m, 'minutes').add(1, 'days').format("YYYY-MM-DDTHH:mm:ss")
            } else if (row.STATime){
                row.STA = moment(FlightDate, "YYYY-MM-DD").add(m, 'minutes').format("YYYY-MM-DDTHH:mm:ss")
            }
        }
    }

    setSTA (row, FlightDate) {
        if (row.STATime && FlightDate) {
            let m = moment.duration(row.STATime).asMinutes();
            if (row.STDTime && row.STATime<row.STDTime) {
                row.STA = moment(FlightDate, "YYYY-MM-DD").add(m, 'minutes').add(1, 'days').format("YYYY-MM-DDTHH:mm:ss")
            } else {
                row.STA = moment(FlightDate, "YYYY-MM-DD").add(m, 'minutes').format("YYYY-MM-DDTHH:mm:ss")
            }
        }
    }

    getFlightNumber (segment) {
        if (segment.Legs.length>0 && segment.Legs[0].InventoryLeg) {
            return segment.Legs[0].InventoryLeg.FlightNumber;
        }
        return "";
    }

    getSegmentDate (segment) {
        if (segment.Legs.length>0 && segment.Legs[0].InventoryLeg) {
            return moment(segment.Legs[0].InventoryLeg.STD).locale('es').format('dddd DD MMM');
        }
        return "";
    }

    getDepartureTime (segment) {
        if (segment.Legs.length>0 && segment.Legs[0].InventoryLeg) {
            return segment.Legs[0].InventoryLeg.STDTime;
        }
        return "";
    }

    getArrivalTime (segment) {
        if (segment.Legs.length>0 && segment.Legs[segment.Legs.length-1].InventoryLeg) {
            return segment.Legs[segment.Legs.length-1].InventoryLeg.STATime;
        }
        return "";
    }

    getFare (segment) {
        for (let charge of segment.Charges) {
            if (charge.ChargeType==0 && charge.ChargeAmount) return charge.CurrencyCode + ' ' + parseFloat(charge.ChargeAmount).toFixed(2);
            if (!charge.ChargeCode && charge.ChargeAmount) return charge.CurrencyCode + ' ' + parseFloat(charge.ChargeAmount).toFixed(2);
        }
        return "";
    }

    getTotalSegment (segment) {
        let total = 0;
        for (let charge of segment.Charges) {
            if (!charge.ChargeAmount) continue
            if (charge.ChargeType==3) continue
            let k = 1
            if (charge.ChargeType == 1) k = -1
            total += parseFloat(charge.ChargeAmount) * k;
        }
        return total.toFixed(2);
    }

    getPassengerData (pax) {
        let res = '';
        if (!pax.FirstName) return "-";
        let paxName = pax.LastName + ', ' + pax.FirstName;
        if (pax.MiddleName) paxName += ' ' + pax.MiddleName;
        res = paxName + ' ' + pax.PaxType + ' ' + moment(pax.DOB).format("DD/MM/YYYY");
        let seats = [];
        for (let segment of pax.Segments){
            for (let leg of segment.Legs) {
                if (!leg.UnitDesignator) continue;
                if (seats.indexOf(leg.UnitDesignator)==-1) {
                    seats.push(leg.UnitDesignator);
                }
            }
        }
        if (seats.length > 0) {
            res += ' ' + seats.join(' ')
        }
        return res;
    }

    getExtraData (pax) {
        let res = [];
        if (pax.Infant && pax.Infants[0]) res.push('INF');
        for (let segment of pax.Segments){
            for (let fee of segment.Fees) {
                if (res.indexOf(fee.FeeCode)==-1) {
                    res.push(fee.FeeCode);
                }
            }
            for (let leg of segment.Legs) {
                if (leg.SSRs) {
                    for (let ssr of leg.SSRs) {
                        if (res.indexOf(ssr.SSRCode)==-1) {
                            res.push(ssr.SSRCode);
                        }
                    }
                }
            }
        }
        return res.join(', ');
    }

    getSegmentData (segment, showFare) {
        let r = this.getFlightNumber(segment) + ', '
            + ' ' + this.getDepartureStation(segment) + this.getSegmentLegsData(segment)
            + ' - ' + this.getArrivalStation(segment) + ', '
            + this.getSegmentDate(segment)
            + ' ' + this.getDepartureTime(segment) + '/'
            + this.getArrivalTime(segment);
        if (!showFare) return r;
        r += ', ' + segment.ClassOfService + ' (' + segment.ProductClass.Name + ') '
            + this.getFare(segment) + ' (' + this.getTotalSegment(segment) + ')';
        return r;
    }

    getDepartureStation (segment) {
        let res = segment.DepartureStation;
        if (segment.Legs.length>0 && segment.Legs[0].InventoryLeg && segment.Legs[0].InventoryLeg.DepartureStation) {
            return segment.Legs[0].InventoryLeg.DepartureStation;
        }
        return res;
    }

    getArrivalStation (segment) {
        let res = segment.ArrivalStation;
        let l = segment.Legs.length;
        if (l>0 && segment.Legs[l-1].InventoryLeg && segment.Legs[l-1].InventoryLeg.ArrivalStation) {
            return segment.Legs[l-1].InventoryLeg.ArrivalStation;
        }
        return res;
    }


    getSegmentLegsData (segment) {
        let res = '';
        if (segment.Legs.length>1) {
            for (let i in segment.Legs) {
                if (i==0) continue;
                let leg = segment.Legs[i];
                if (leg.InventoryLeg && leg.InventoryLeg.DepartureStation) {
                    res += ' - ' + leg.InventoryLeg.DepartureStation;
                }
            }
        }
        return res;
    }


    bookingSumUp (booking) {
        let TotalPayments = parseFloat(0);
        for (let p of booking.Payments){
            if (p.Status!=3 || p.AuthorizationStatus!=4) continue;
            if (!p.PaymentAmount) continue;
            TotalPayments += parseFloat(p.PaymentAmount);
        }
        booking.TotalPayments = Math.round(TotalPayments * 100) / 100;
        let TotalCost = parseFloat(0);
        for (let pax of booking.Passengers) {
            for (let segment of pax.Segments){
                for (let fee of segment.Fees) {
                    for (let charge of fee.Charges) {
                        if (!charge.ChargeAmount) continue;
                        if (charge.ChargeType==3) continue;
                        let k = 1;
                        if (charge.ChargeType==1) k = -1;
                        TotalCost += parseFloat(charge.ChargeAmount * k);
                    }
                }
                for (let charge of segment.Charges){
                    if (!charge.ChargeAmount) continue;
                    if (charge.ChargeType==3) continue;
                    let k = 1;
                    if (charge.ChargeType==1) k = -1;
                    TotalCost += parseFloat(charge.ChargeAmount * k);
                }
            }
            for (let fee of pax.Fees) {
                for (let charge of fee.Charges) {
                    if (!charge.ChargeAmount) continue;
                    if (charge.ChargeType==3) continue;
                    let k = 1;
                    if (charge.ChargeType==1) k = -1;
                    TotalCost += parseFloat(charge.ChargeAmount * k);
                }
            }
        }
        for (let fee of booking.Fees) {
            for (let charge of fee.Charges) {
                if (!charge.ChargeAmount) continue;
                if (charge.ChargeType==3) continue;
                let k = 1;
                if (charge.ChargeType==1) k = -1;
                TotalCost += parseFloat(charge.ChargeAmount * k);
            }
        }

        booking.TotalCost = Math.round(TotalCost * 100) / 100;
        booking.BalanceDue = Math.round((TotalCost - TotalPayments) * 100) / 100;
    }

    newBooking (){
        let booking = {
            webId: uuidv1(),
            BalanceDue: 0,
            Comments: [],
            ContactInformation: [
                {
                    TypeCode: 'P',
                    EmailAddress: null,
                    HomePhone: null,
                    WorkPhone: null,
                    OtherPhone: null,
                    FirstName: null,
                    MiddleName: null,
                    LastName: null,
                }
            ],
            CurrencyCode: 'ARS',
            History: [],
            PaidStatus: 1,
            Passengers: [],
            Payments: [],
            Fees: [],
            RecordLocator: null,
            Status: 0,
            TotalCost: 0,
            TotalPayments: 0
        }
        return booking;
    }

    async getPrinterList (electron) {
        return new Promise((resolve, reject) => {
            var ipcRenderer = electron.ipcRenderer;
            ipcRenderer.on('get-printer-list-result', function (event, store) {
                resolve(store)
            });
            ipcRenderer.send('get-printer-list', null);

        })
    }

    async setCurrentPrinter (electron, printerName) {
        return new Promise((resolve, reject) => {
            var ipcRenderer = electron.ipcRenderer;
            ipcRenderer.on('set-current-printer-result', function (event, store) {
                if (store) {
                    resolve(store);
                } else {
                    resolve(null)
                }
            });
            ipcRenderer.send('set-current-printer', printerName);
        })
    }

    async openDevTools (electron) {
        return new Promise((resolve, reject) => {
            if (electron) {
                var ipcRenderer = electron.ipcRenderer;
                ipcRenderer.send('open-dev-tools');
            }
        })
    }



    async getCurrentPrinter (electron) {
        return new Promise((resolve, reject) => {
            var ipcRenderer = electron.ipcRenderer;
            ipcRenderer.on('get-current-printer-result', function (event, store) {
                if (store) {
                    resolve(store);
                } else {
                    resolve(null)
                }
            });

            ipcRenderer.send('get-current-printer', null);
        })
    }

    getAuthorizationStatusNames () {
        return {
            0: "Desconocido",
            1: "Recibido",
            2: "Pendiente",
            3: "En Proceso",
            4: "Aprobado",
            5: "Declinado",
            6: "Remisión",
            7: "",
            8: "",
            9: "Anulado",
            10: "",
            11: "Charged Back",
            12: "Error",
            13: "Validacion Fallida",
            14: "",
            15: "",
            16: "Prevención de Fraude",
        }
    }

    numberFormat(num) {
        return num.toFixed(2).replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

     getFlightFields () {
        return [
          {name: 'id', hidden: 'true'},
          {name: 'FlightNumber', label: 'Número', required: true},
          {name: 'FlightDate', label: 'Fecha', required: true, editor: 'date', dateformat: 'DD/MM/YYYY'},
          {name: 'WeekDay', label: 'Día', editor: 'select', disabled: true,
              options: [
                    {value: '0', label: 'Lunes'},
                    {value: '1', label: 'Martes'},
                    {value: '2', label: 'Miércoles'},
                    {value: '3', label: 'Jueves'},
                    {value: '4', label: 'Viernes'},
                    {value: '5', label: 'Sábado'},
                    {value: '6', label: 'Domingo'},
                    ]},
          {name: 'Status', label: 'Estado', editor: 'select', defValue: 'CONFIRMED',
              options: [
                    {value: 'CONFIRMED', label: 'Confirmado'},
                    {value: 'SUSPENDED', label: 'Cerrado a la Venta'},
                    {value: 'CANCELED', label: 'Cancelado'},
              ]
          },
          {name: 'CustomerID', relation: 'customer', optionLabels: 'Name', editor: 'select', label: 'Cliente', addBlank: true},
          {name: 'Sync', label: 'Sincronizar', editor: 'checkbox'},
          {name: 'InventoryLegs', editor: [], hideFromList: true, label: 'Legs',
            rowFields: [
                {name: 'DepartureStation', relation: 'station', optionLabels: 'id', class: 'mw-70'
                    , editor: 'select', label: 'Desde', required: true, columns: 1},
                {name: 'ArrivalStation', relation: 'station', optionLabels: 'id', class: 'mw-70'
                    , editor: 'select', label: 'Hacia', required: true, columns: 1},
                {name: 'STDTime', label: 'Salida', editor: 'time', required: true, columns: 1, class: 'mw-60'},
                {name: 'STD', label: '', editor: 'datetime', columns: 2, class: 'mw-130'},
                {name: 'STATime', label: 'Llegada', editor: 'time', required: true, columns: 1, class: 'mw-60'},
                {name: 'STA', label: '', editor: 'datetime', columns: 2, class: 'mw-130'},
                {name: 'SalesConfigurationId', label: 'Configuración', editor: 'select', class: 'mw-120', 
                    relation: 'salesconfiguration', optionLabels: 'Name', columns: 1},
                {name: 'LegStatus', label: 'Estado', editor: 'select', defValue: 'OPEN', columns: 2, class: 'mw-130', 
                    options: [
                        {value: 'OPEN', label: 'Abierto'},
                        {value: 'CLOSE_PENDING', label: 'Cierre Pendiente'},
                        {value: 'CANCELED', label: 'Cancelado'},
                        {value: 'CLOSED', label: 'Cerrado'},
                    ]
                },
                {name: 'WebCheckinStart', label: 'Web Checkin', editor: 'number', columns: 2, class: 'mw-100'},
            ]},
            {name: 'FlightFares', editor: 'component', component: flightFares, hideFromList: true, label: 'Fares'}
          ]

     }

     flighAfteredit () {
        return {
            'FlightDate': function(record) {
                record.WeekDay = moment(record.FlightDate).weekday() - 1;
                if (record.WeekDay==-1) {
                    record.WeekDay = 6;
                }
                for (let row of record.InventoryLegs) {
                    app.setSTD(row, record.FlightDate);
                    app.setSTA(row, record.FlightDate);
                }
            },
            'InventoryLegs': function(record, rowfieldname, rownr) {
                let row = record.InventoryLegs[rownr];
                if (rowfieldname=='STDTime') {
                    app.setSTD(row, record.FlightDate);
                }
                if (rowfieldname=='STATime' && row.STATime && record.FlightDate) {
                    app.setSTA(row, record.FlightDate);
                }
            }
        }
     }

     getNextFeeNumber (fees) {
        if (fees.length==0) return 1;
        let nr = Math.max.apply(Math, fees.map(function(o) { return o.FeeNumber; }));
        return nr + 1;
     }


}

let app = new App();

window.app = app;
if (window.io) {
    window.socket = io.connect('//' + document.domain + ':' + location.port);
}
})(jQuery);
