<template>
    <home endpoint="emailconfiguration" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'emailconfiguration',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
            {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Código', required: true, columns: 12},
            {name: 'TopLink', label: 'TopBanner'},
            {name: 'TopBanner', label: 'TopBanner'},
            {name: 'MiddleLink', label: 'MiddleLink'},
            {name: 'MiddleBanner', label: 'MiddleBanner'},
            {name: 'BottomLeftLink', label: 'BottomLeftLink'},
            {name: 'BottomLeftBanner', label: 'BottomLeftBanner'},
            {name: 'BottomMiddleLink', label: 'BottomMiddleLink'},
            {name: 'BottomMiddleBanner', label: 'BottomMiddleBanner'},
            {name: 'BottomRightLink', label: 'BottomRightLink'},
            {name: 'BottomRightBanner', label: 'BottomRightBanner'},
            {name: 'BottomLine2LeftLink', label: 'BottomLine2LeftLink'},
            {name: 'BottomLine2LeftBanner', label: 'BottomLine2LeftBanner'},
            {name: 'BottomLine2MiddleLink', label: 'BottomLine2MiddleLink'},
            {name: 'BottomLine2MiddleBanner', label: 'BottomLine2MiddleBanner'},
            {name: 'BottomLine2RightLink', label: 'BottomLine2RightLink'},
            {name: 'BottomLine2RightBanner', label: 'BottomLine2RightBanner'},
            {name: 'emailTest', label: 'Mail de Prueba'},
            ],
        title: 'Configuraciónd de Correos',
        title2: 'Configuraciónd de Correos',
        primaryKey: 'ArrivalStation',
        actions: [
            {label: 'Enviar', method: this.test}
        ]
      }
    }
  },
  methods: {
      test: async function(self) {
        let data = {
            Email: self.current.emailTest,
            ArrivalStation: self.current.ArrivalStation,
        }
        let res = await app.postJSON('/api/booking/send_itinerary_test', data);
        if (res) {
            alert('Mensage Enviado');
        }
    }
  }
}
</script>


