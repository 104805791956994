<template>
    <div v-if="booking" class="form-row">
      <div class="modal-content col-md-8" v-if="!edit">
        <div class="modal-body form-row">
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Nombre:</label>
                </div>
                <div class="col-md-8">
                    <label>
                        {{booking.ContactInformation[0].LastName}},
                        {{booking.ContactInformation[0].FirstName}}
                        {{booking.ContactInformation[0].MiddleName}}
                    </label>
                </div>
            </div>
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Dirección:</label>
                </div>
                <div class="col-md-8">
                    <label>{{booking.ContactInformation[0].AddressLine1}}</label>
                </div>
            </div>
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Email:</label>
                </div>
                <div class="col-md-8">
                    <label>{{booking.ContactInformation[0].EmailAddress}}</label>
                </div>
            </div>
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Teléfono:</label>
                </div>
                <div class="col-md-8">
                    <label>{{booking.ContactInformation[0].HomePhone}}</label>
                </div>
            </div>
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Tel.Agencia:</label>
                </div>
                <div class="col-md-8">
                    <label>{{booking.ContactInformation[0].WorkPhone}}</label>
                </div>
            </div>
            <div class="col-md-12 form-row">
                <div class="col-md-4">
                    <label>Otro Tel.:</label>
                </div>
                <div class="col-md-8">
                    <label>{{booking.ContactInformation[0].OtherPhone}}</label>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-content col-md-8" v-if="edit">
        <div class="modal-body form-row">
            <div class="col-md-6 form-row">
                <div class="col-md-12">
                    <label for="field.name">Apellido</label>
                    <input v-model="contactInformation.LastName" class="form-control" type="text" id="LastName">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Primer Nombre</label>
                    <input v-model="contactInformation.FirstName" class="form-control" type="text" id="FirstName">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Segundo Nombre</label>
                    <input v-model="contactInformation.MiddleName" class="form-control" type="text" id="MiddleName">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Dirección</label>
                    <input v-model="contactInformation.AddressLine1" class="form-control" type="text" id="AddressLine1">
                </div>
            </div>
            <div class="col-md-6 form-row">
                <div class="col-md-12">
                    <label for="field.name">Email</label>
                    <input v-model="contactInformation.EmailAddress" class="form-control" type="text" id="EmailAddress">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Teléfono Hogar</label>
                    <input v-model="contactInformation.HomePhone" class="form-control" type="text" id="HomePhone">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Teléfono Particular</label>
                    <input v-model="contactInformation.WorkPhone" class="form-control" type="text" id="WorkPhone">
                </div>
                <div class="col-md-12">
                    <label for="field.name">Otro Teléfono </label>
                    <input v-model="contactInformation.OtherPhone" class="form-control" type="text" id="OtherPhone">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="closeEdit">Cerrar</button>
        </div>
      </div>
      <div class="col-md-2 offset-md-2" v-else>
            <div class="form-row">
                <button type="button" class="btn btn-primary button-row" @click="openEdit">Editar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'bookingcontact',
  components: {
  },
  data  () {
    return {
        edit: false,
        contactInformation: {
            FirstName: null,
            MiddleName: null,
            LastName: null,
            AddressLine1: null,
            EmailAddress: null,
            HomePhone: null,
            WorkPhone: null,
            OtherPhone: null,
        }
    }
  },
  mounted () {
    for (let fieldname in this.contactInformation) {
        this.contactInformation[fieldname] = this.booking.ContactInformation[0][fieldname];
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    save () {
        for (let fieldname in this.contactInformation) {
            this.booking.ContactInformation[0][fieldname] = this.contactInformation[fieldname];
        }
        this.edit = false;
    },
    closeEdit () {
        this.edit = false;
        for (let fieldname in this.contactInformation) {
            this.contactInformation[fieldname] = null;
        }
    },
    openEdit () {
        this.edit = true;
        for (let fieldname in this.contactInformation) {
            this.contactInformation[fieldname] = this.booking.ContactInformation[0][fieldname];
        }
    }
  },
}
</script>

