<template>
    <div>
        <div class="container">
            <div class="form-row">
                <div class="form-group col-md-2 form-row">
                  <div class="col-md-10">
                      <label for="FlightNumber" >Número de Vuelo</label>
                      <input v-model="FlightNumber" name="FlightNumber" field-editor="FlightNumber"
                      class="form-control" type="text" placeholder="" id="FlightNumber">
                  </div>
                  <div class="col-md-10">
                      <selectinput fieldname="stations" :fieldOptions="formattedStations" label="Desde"
                      :current_value.sync="DepartureStation" id="DepartureStation">
                      </selectinput>
                  </div>
                  <div class="col-md-10">
                      <selectinput fieldname="stations" :fieldOptions="formattedStations" label="Hacia"
                      :current_value.sync="ArrivalStation" id="ArrivalStation">
                      </selectinput>
                  </div>
                  <div class="col-md-10">
                      <label for="FromDate">Fecha Desde</label>
                      <datepicker id="FromDate" :def.sync="FromDate" @changedate="checkDate('FromDate')"></datepicker>
                      <div class="invalid-feedback">
                        Campo requerido
                      </div>
                  </div>
                  <div class="col-md-10">
                      <label for="ToDate">Fecha Hasta</label>
                      <datepicker id="ToDate" :def.sync="ToDate" @changedate="checkDate('ToDate')"></datepicker>
                      <div class="invalid-feedback">
                        Campo requerido
                      </div>
                  </div>
                  <div class="col-md-10">
                    <div class="spacer"></div>
                    <button type="button" class="btn btn-primary" @click="searchFlights">Sincronizar</button>
                  </div>
                </div>
                <div class="form-group col-md-6">
                    <div v-if="processing">
                        <img class="loading-gif" src="@/img/loading.gif">
                    </div>
                    <div v-if="result">
                      Se analizaron {{result.total}} tramos. Se actualizaron {{result.updated}}
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import '@/css/app.css';

import { mapState } from 'vuex';
import datepicker from '@/components/tools/DatePicker'
import recordwindow from '@/components/tools/RecordWindow';
import selectinput from '@/components/tools/SelectInput'
import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);

var moment = require('moment');
export default {
  name: 'syncflight',
  components: {

    datepicker,
    selectinput
  },
  data  () {
    return {
        current: null,
        FlightNumber: null,
        FromDate: moment(new Date()).format("YYYY-MM-DD"),
        ToDate: moment(new Date()).format("YYYY-MM-DD"),
        DepartureStation: null,
        ArrivalStation: null,
        result: "",
        processing: false,
     }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        stations: state => state.stations,
    }),
    formattedStations () {
      var list = [];
      for (var s of this.stations) {
        list.push({value: s.id, label: s.id});
      }
      return {"stations": list}
    }
  },
  mounted () {

  },
  methods: {
    checkDate (fieldname) {
        if (moment(this[fieldname]) < moment()) {
            alert('Fecha Incorrecta');
            let self = this;
            this.$nextTick(function() {
                self[fieldname] = moment(new Date()).format("YYYY-MM-DD");
            })
        }
    },
    searchFlights: async function() {
        if (_.isEmpty(this.FlightNumber) && (_.isEmpty(this.DepartureStation) && _.isEmpty(this.ArrivalStation))) {
          $("#FlightNumber")[0].classList.add('is-invalid');
          $("#ArrivalStation")[0].classList.add('is-invalid');
          $("#DepartureStation")[0].classList.add('is-invalid');
          return;
        }  else {
          $("#FlightNumber")[0].classList.remove('is-invalid');
          $("#ArrivalStation")[0].classList.remove('is-invalid');
          $("#DepartureStation")[0].classList.remove('is-invalid');
        }

        this.processing = true;
        let data = {
            flightNumber: this.FlightNumber,
            fromDate: this.FromDate,
            toDate: this.ToDate,
            departureStation: this.DepartureStation,
            arrivalStation: this.ArrivalStation
        }
        let res = await app.postJSON('/api/flight/inventory_leg_class', data)
        if (res) {
            this.result = res;
            this.processing = false;
        }
    }
  },
}
</script>

