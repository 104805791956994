<template>
  <!--div class="modal fade bd-example-modal-lg" id="newpayment" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"-->
      <div class="modal-content">
        <div class="modal-body" v-if="fieldOptions && !processing" >
            <div class="form-row">
                <div class="col-md-2">
                    <selectinput fieldname="PaymentMethodCode" :fieldOptions="fieldOptions" label="Método de Pago"
                    :current_value.sync="paymentCode" :disabled="refund!=null">
                    </selectinput>
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="CurrencyCode" :fieldOptions="fieldOptions" label="Moneda"
                    :current_value.sync="currency">
                    </selectinput>
                </div>
                <div class="col-md-2">
                    <label for="field.name" >Monto</label>
                    <numberinput :v.sync="amount" d="2" :addClass="amountError?'form-error': ''">
                    </numberinput>
                    <!--input v-model="amount" class="form-control" type="number" :class="amountError?'form-error': ''"-->
                </div>
            </div>
            <div class="form-row" v-if="paymentMethodType==3 || paymentMethodType==2">
                <div class="col-md-6">
                    <label for="field.name">Cuenta Corriente</label>
                    <div class="form-row">
                        <div class="col-md-4">
                            <input v-model="account" @blur="getAccount" class="form-control" type="text">
                        </div>
                        <div class="col-md-8" v-if="showCredit">
                            <label>Disponible: ARS {{availableCredit}}</label>
                        </div>
                        <div class="col-md-8" v-else-if="organizationNotFound">
                            <label>Agencia no encontrada</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row" v-if="paymentMethodType==6">
                <div class="col-md-2">
                    <label>Ult. 4 Dígitos</label>
                    <input v-model="account" class="form-control" type="text">
                </div>
                <div class="col-md-2">
                    <label>Cod. Autorización</label>
                    <input v-model="authorizationCode" class="form-control" type="text">
                </div>
                <div class="col-md-2">
                    <label>Cuotas</label>
                    <input v-model="installments" class="form-control" type="text">
                </div>

            </div>
        </div>
        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save" :disabled="!completed">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    <!--/div>
  </div-->
</template>

<script>
import { mapState } from 'vuex';
import selectinput from '@/components/tools/SelectInput';
import numberinput from '@/components/tools/NumberInput';
var moment = require('moment');
export default {
  name: 'newpayment',
  props: ['refund'],
  components: {
    selectinput,
    numberinput
  },
  data  () {
    return {
        amount: null,
        currency: 'ARS',
        paymentCode: null,
        parentPaymentID: null,
        fieldOptions: null,
        paymentMethods: [],
        account: null,
        authorizationCode: null,
        installments: null,
        availableCredit: 0,
        paymentAccountType: 0,
        organizationNotFound: false,
        processing: false,
        currencies: [
            {label: 'ARS', value: 'ARS'}
        ]
    }
  },
  mounted: async function() {
    this.processing = true;
    let res = await app.get('/api/paymentmethod/');
    if (res) {
        let payments = [];
        for (let r of res) {
            if (r.InActive) continue;
            payments.push({value: r.PaymentMethodCode, label: r.Name})
        }
        this.fieldOptions = {PaymentMethodCode: payments, CurrencyCode: this.currencies};
        this.paymentMethods = res;
        if (this.refund!=null) {
            this.paymentCode = this.booking.Payments[this.refund].PaymentMethodCode;
            this.parentPaymentID = this.booking.Payments[this.refund].PaymentID;
        }
        this.processing = false;
    }
    if (this.booking.BalanceDue>0) {
        this.amount = this.booking.BalanceDue;
    }
    if (this.refund!=null) {
        this.amount = -this.refundAmount;
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    refundAmount () {
        if (this.refund==null) return;
        let total = parseFloat(this.booking.Payments[this.refund].PaymentAmount);
        for (let p of this.booking.Payments) {
            if (p.ParentPaymentID==this.booking.Payments[this.refund].PaymentID) {
                total += parseFloat(p.PaymentAmount);
            }
        }
        return total;
    },
    paymentMethodType () {
        if (this.paymentCode) {
            let p = _.find(this.paymentMethods, (c)=>c.PaymentMethodCode==this.paymentCode);
            return p.PaymentMethodType;
        }
    },
    showCredit () {
        if (this.paymentMethodType==3 || this.paymentAccountType==1) return true;
        return false;
    },
    completed () {
        if (this.amountError) return false;
        if (this.paymentMethodType==3 && !this.account) return false;
        if (this.paymentMethodType==2 && !this.account) return false;
        if (this.paymentMethodType==3 && !this.availableCredit) return false;
        if (this.organizationNotFound) return false;
        if (this.amount && this.paymentCode) return true;
    },
    amountError () {
        if (this.paymentMethodType==3 && parseFloat(this.amount)>this.availableCredit) return true;
        if (this.paymentAccountType==1 && parseFloat(this.amount)>this.availableCredit) return true;
        if (parseFloat(this.amount)<0 && !this.parentPaymentID) return true;
        if (parseFloat(this.amount)>this.booking.BalanceDue && !this.parentPaymentID) return true;
        if (this.refund!=null && parseFloat(this.amount)<-this.refundAmount) return true;
    },
    pendingAmount () {
        let res = 0;
        for (let payment of this.booking.Payments) {
            if (payment.PaymentID) continue
            if (payment.PaymentMethod) {
                if (payment.PaymentMethod.PaymentMethodType==1 || payment.PaymentMethod.PaymentMethodType==2) {
                    if (payment.AccountNumber==this.account) {
                        res += parseFloat(payment.PaymentAmount);
                    }
                }
            }
        }
        return res;
    }

  },
  methods: {
    save () {
        let paymentmethod = _.find(this.paymentMethods, (p)=>p.PaymentMethodCode==this.paymentCode)
        let p = {
            CurrencyCode: this.currency,
            PaymentMethodCode: this.paymentCode,
            PaymentMethodType: paymentmethod.PaymentMethodType,
            ParentPaymentID: this.parentPaymentID,
            PaymentAmount: this.amount,
            CollectedCurrencyCode: this.currency,
            CollectedPaymentAmount: this.amount,
            Status: 3,
            AuthorizationStatus: 4,
            PaymentMethod: paymentmethod,
            AuthorizationCode: this.authorizationCode
        }
        if (this.installments) p.Installments = parseInt(this.installments);
        if (p.PaymentMethodType==1) {
            p.AuthorizationStatus = 2;
            //p.Status = 1;
        }
        if (this.account) {
            p.AccountNumber = this.account;
        }
        this.booking.Payments.push(p)
        app.bookingSumUp(this.booking);
        this.$emit('close');
    },
    getAccount: async function() {
        if (this.paymentMethodType==2) {
            this.organizationNotFound = true;
            let res = await app.get('/api/organization/' + this.account);
            if (res) {
                this.organizationNotFound = false;
                if (res.PaymentAccountType==1) {
                    this.paymentAccountType = 1;
                    let credit = await app.get('/api/organization/get_credit/' + this.account);
                    if (credit) {
                        this.availableCredit = parseFloat(credit) - this.pendingAmount;
                    }
                }
            }
        } else {
            this.availableCredit = 0;
            if (!this.account) return;
            if (this.paymentMethodType==3 && this.account.length!=6) return;
            let res = await app.get('/api/payment/get_credit_shell', {Account: this.account});
            if (res) {
                this.availableCredit = -parseFloat(res) -  this.pendingAmount;
            }
        }
    }

  },
}
</script>

