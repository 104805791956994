<template>
    <div class="row">
        <div class="col-12">
            <label> {{station? station: 'Total'}} </label>
        </div>
        <div class="col-12">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th></th>
                        <th>Manifestado</th>
                        <th>Chequeado</th>
                        <th>Embarcado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>TOTAL</td>
                        <td>{{paxData.total}}</td>
                        <td>{{paxData.totalCHK}}</td>
                        <td>{{paxData.totalBRD}}</td>
                    </tr>
                    <tr>
                        <td>ADT</td>
                        <td>{{paxData.adt}}</td>
                        <td>{{paxData.adtCHK}}</td>
                        <td>{{paxData.adtBRD}}</td>
                    </tr>
                    <tr>
                        <td>CHD</td>
                        <td>{{paxData.chd}}</td>
                        <td>{{paxData.chdCHK}}</td>
                        <td>{{paxData.chdBRD}}</td>
                    </tr>
                    <tr>
                        <td>INF</td>
                        <td>{{paxData.inf}}</td>
                        <td>{{paxData.infCHK}}</td>
                        <td>{{paxData.infBRD}}</td>
                    </tr>
                    <tr>
                        <td>BAG</td>
                        <td>{{bag['bags']}}</td>
                        <td>{{bag['bagsCHK']}}</td>
                        <td>{{bag['bagsBRD']}}</td>
                    </tr>
                    <tr>
                        <td>KG</td>
                        <td>{{bag['weight']}}</td>
                        <td>{{bag['weightCHK']}}</td>
                        <td>{{bag['weightBRD']}}</td>
                    </tr>
                    <tr>
                        <td>ADT M</td>
                        <td>{{paxData.adtMale}}</td>
                        <td>{{paxData.adtMaleCHK}}</td>
                        <td>{{paxData.adtMaleBRD}}</td>
                    </tr>
                    <tr>
                        <td>ADT F</td>
                        <td>{{paxData.adtFemale}}</td>
                        <td>{{paxData.adtFemaleCHK}}</td>
                        <td>{{paxData.adtFemaleBRD}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-12">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th>SSR</th>
                        <th>Manifestado</th>
                        <th>Chequeado</th>
                        <th>Embarcado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="code of SSRs">
                        <td>{{code}}</td>
                        <td>{{paxData[code]}}</td>
                        <td>{{paxData[code + '.CHK']}}</td>
                        <td>{{paxData[code + '.BRD']}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'legsummary',
  props: ['paxlist', 'station'],
  components: {
  },
  data  () {
    return {
        SSRs: []
    }
  },
  mounted: async function() {
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    paxData () {
        let res = {
            total: 0,
            totalCHK: 0,
            totalBRD: 0,
            adt: 0,
            chd: 0,
            inf: 0,
            adtCHK: 0,
            chdCHK: 0,
            infCHK: 0,
            adtBRD: 0,
            chdBRD: 0,
            infBRD: 0,
            adtMale: 0,
            adtFemale: 0,
            adtMaleCHK: 0,
            adtFemaleCHK: 0,
            adtMaleBRD: 0,
            adtFemaleBRD: 0,
        };
        for (let p of this.paxlist) {
            if (this.station && p.Segment.ArrivalStation!=this.station) continue
            res.total += 1;
            if (p.LiftStatus==1 || p.LiftStatus==2) res.totalCHK += 1;
            if (p.LiftStatus==2) res.totalBRD += 1;
            if (this.isADT(p.Passenger.PaxType)) res.adt += 1;
            if (p.Passenger.PaxType=='CHD') res.chd += 1;
            if (p.Passenger.Infant==true) res.inf += 1;
            if (this.isADT(p.Passenger.PaxType) && (p.LiftStatus==1 || p.LiftStatus==2)) res.adtCHK += 1;
            if (p.Passenger.PaxType=='CHD' && (p.LiftStatus==1 || p.LiftStatus==2)) res.chdCHK += 1;
            if (p.Passenger.Infant==true && (p.LiftStatus==1 || p.LiftStatus==2)) res.infCHK += 1;
            if (this.isADT(p.Passenger.PaxType) && p.LiftStatus==2) res.adtBRD += 1;
            if (p.Passenger.PaxType=='CHD' && p.LiftStatus==2) res.chdBRD += 1;
            if (p.Passenger.Infant==true && p.LiftStatus==2) res.infBRD += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='MALE') res.adtMale += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='MALE' && (p.LiftStatus==1 || p.LiftStatus==2)) res.adtMaleCHK += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='MALE' && p.LiftStatus==2) res.adtMaleBRD += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='FEMALE') res.adtFemale += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='FEMALE' && (p.LiftStatus==1 || p.LiftStatus==2)) res.adtFemaleCHK += 1;
            if (this.isADT(p.Passenger.PaxType) && p.Passenger.Gender=='FEMALE' && p.LiftStatus==2) res.adtFemaleBRD += 1;
            for (let fee of p.SegmentFees) {
                if (this.SSRs.indexOf(fee.FeeCode)==-1) {
                    this.SSRs.push(fee.FeeCode);
                    res[fee.FeeCode] = 0;
                    res[fee.FeeCode + '.CHK'] = 0;
                    res[fee.FeeCode + '.BRD'] = 0;
                }
                res[fee.FeeCode] += 1;
                if (p.LiftStatus==1 || p.LiftStatus==2) res[fee.FeeCode + '.CHK'] += 1;
                if (p.LiftStatus==2) res[fee.FeeCode + '.BRD'] += 1;
            }
            for (let ssr of p.SSRs) {
                if (this.SSRs.indexOf(ssr.SSRCode)==-1) {
                    this.SSRs.push(ssr.SSRCode);
                    res[ssr.SSRCode] = 0;
                    res[ssr.SSRCode + '.CHK'] = 0;
                    res[ssr.SSRCode + '.BRD'] = 0;
                }
                res[ssr.SSRCode] += 1;
                if (p.LiftStatus==1 || p.LiftStatus==2) res[ssr.SSRCode + '.CHK'] += 1;
                if (p.LiftStatus==2) res[ssr.SSRCode + '.BRD'] += 1;
            }
        }
        return res;
    },

    bag () {
        let bags = 0;
        let bagsCHK = 0;
        let bagsBRD = 0;
        let weight = 0;
        let weightCHK = 0;
        let weightBRD = 0;
        for (let pax of this.paxlist) {
            if (this.station && pax.Segment.ArrivalStation!=this.station) continue
            for (let baggage of pax.Baggages) {
                for (let legBag of baggage.LegBaggages) {
                    if (legBag.SegmentID==pax.SegmentID && legBag.LegNumber==pax.LegNumber && legBag.BaggageStatus==1) {
                        bags += 1;
                        weight += baggage.Weight;
                        if (pax.LiftStatus==1 || pax.LiftStatus==2) {
                            bagsCHK += 1
                            weightCHK += baggage.Weight;
                        };
                        if (pax.LiftStatus==2) {
                            bagsBRD += 1
                            weightBRD += baggage.Weight;
                        };
                    }
                }
            }
        }
        return {
            bags: bags,
            bagsCHK: bagsCHK,
            bagsBRD: bagsBRD,
            weight: weight,
            weightCHK: weightCHK,
            weightBRD: weightBRD,
        };
    },

  },
  methods: {
    isADT (paxType) {
        if (paxType=='ADT' || paxType=='NREV' || paxType=='CBUS') {
            return true;
        }
    }
  },
}
</script>

