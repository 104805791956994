<template>
    <div>
      <report-window endpoint="/reports/tr_pax_list"
          :fields="fields"
          :title="title"
          :headers="headers"
          :templates="templates"
          perPage="500"
          :afterRun="afterRun"
          :headerFields="headerFields"
          :buttons="buttons"
      ></report-window>
      <boardings-print :show.sync="showBoardings" :list="list" v-if="showBoardings"></boardings-print>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import boardingsPrint from '@/components/tools/BoardingsPrint'

import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'trpaxlist',
  components: {

    ReportWindow,
    boardingsPrint,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        showBoardings: false,
        list: [],
        fields: [
            {name: 'FlightNr', editor: 'text', label: 'Vuelo', columns: 1, required: true},
            {name: 'FlightDate', editor: 'date', label: 'Fecha', defValue: moment(new Date()).format("YYYY-MM-DD")
                , required: true, columns: 1},
            {name: 'DepartureStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Desde'},
            {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Hacia'},
            {name: 'RecordLocator', editor: 'text', label: 'Localizdor'}
        ],
        title: 'Listado Travel Rock',
        headers: {
            RecordLocator: 'Localizador'
        },
        templates: [
        ],
        headerFields: [],
        buttons: [
            {callback: this.printBoardings, label: 'Imprimir Boardings', buttonClass: '', id: 'printBoardings',
                disabled: false},
            {callback: this.blankBoardings, label: 'Boardings en Blanco', buttonClass: '', id: 'blankBoardings',
                disabled: false},
        ]
    }
  },
  methods: {
      afterRun (report) {
          if (report.list && report.list.length>0) {
              this.headerFields = []
              this.headerFields.push({label: 'fecha vuelo', value: moment(report.list[0].DepartureDate).format('DD/MM/YYYY')})
              this.headerFields.push({label: 'hora vuelo', value: moment(report.list[0].STD).format("HH:mm")})
              this.headerFields.push({label: 'numero de vuelo', value: report.list[0].FlightNumber})
              this.headerFields.push({label: 'cliente', value: 'Travel Rock'})
              this.headerFields = Object.assign([], this.headerFields);
          }
      },
      printBoardings (report) {
          this.list = report.list;
          this.showBoardings = true;
      },
      blankBoardings (report) {
          this.list = [{}, {}, {}, {}];
          this.showBoardings = true;
      },
  }

}
</script>

