<template>
  <div class="modal fade printable" id="tickets" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog history-modal" role="document">
      <div class="modal-content">
        <div class="modal-body itinerary-body" v-if="!showPreview">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th></th>
                        <th>Pago</th>
                        <th>Pasajero</th>
                        <th>Conceptos</th>
                        <th>Segmentos</th>
                        <th>Monto</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ticket of tickets">
                        <td><input type="checkbox" v-model="selected[ticket.id]" @change="setSelected(ticket.id)"></td>
                        <td>{{ticket.PaymentID}}</td>
                        <td>{{getPassenger(ticket.PassengerID)}}</td>
                        <td v-if="detail">
                            <p v-for="c in ticket.Charges">
                                {{c.TicketCode? c.TicketCode: 'Tarifa'}}
                            </p>
                        </td>
                        <td v-else>
                            {{getConcepts(ticket).join(', ')}}
                        </td>
                        <td v-if="detail">
                            <p v-for="c of ticket.Charges">
                                {{getSegments(c)}}
                            </p>
                        </td>
                        <td v-else>
                            {{getSegmentsByTicket(ticket).join(', ')}}
                        </td>
                        <td v-if="detail">
                            <p v-for="c of ticket.Charges">
                                {{c.CurrencyCode}} {{parseFloat(c.ChargeAmount).toFixed(2)}}
                            </p>
                        </td>
                        <td v-else>
                            {{getTicketTotal(ticket)}}
                        </td>
                        <td>{{getDate(ticket.CreatedUTC)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-body itinerary-body" v-else>
            <div class="row">
                <div class="col-12" align="center"><img src="https://www.andesonline.com/wp-content/themes/andes/images/logo.png" class="company-logo"></div>
            </div>
            <br>
            <br>
            <div class="row">
                <h4 class="text-center">ANDES LÍNEAS AÉREAS S.A</h4>
            </div>
            <hr>
            <br>
            <div class="row">
                <h4 class="text-center">CONSTANCIA DE CRÉDITO VÁLIDA POR DOCUMENTACIÓN EMITIDA R.G 1415/03</h4>
            </div>
            <hr>
            <br>
            <div class="row">
                <div class="col-6">
                    <span>Constancia Nº: {{firstPax.pax.PassengerID}}</span>
                </div>
                <div class="col-6">
                    <span>Fecha de Emisión: {{getCurrentDate}}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12">
                    <span><b>A - Datos del Emisor</b></span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <span>Denominación: Andes Líneas Aéreas S.A.</span>
                </div>
                <div class="col-6">
                    <span>CUIT: 30-70925173-9</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Domicilio: TACUARÍ 1353 Piso 2 Dpto G CP 1430 C.A.B.A</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12">
                    <span><b>B - Datos del Receptor</b></span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <span>Denominación:
                        <a @click="editPaxName" v-if="paxName">{{paxName}}</a>
                        <a @click="editPaxName" v-else-if="!paxNameIn">EDITAR</a>
                        <input type="text" @change="paxName = $event.target.value" v-else>
                    </span>
                </div>
                <div class="col-6">
                    <span>CUIT/CUIL:
                        <a @click="editDocument" v-if="documentId">{{documentId}}</a>
                        <a @click="editDocument" v-else-if="!documentIdIn">EDITAR</a>
                        <input type="text" @change="documentId = $event.target.value" v-else>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Domicilio:
                        <a @click="editAddress" v-if="address">{{address}}</a>
                        <a @click="editAddress" v-else-if="!addressIn">EDITAR</a>
                        <input type="text" @change="address = $event.target.value" v-else>
                    </span>
                </div>
            </div>
            <br>
            <hr>
            <div class="row">
                <div class="col-12">
                    <span><b>C - Datos del Crédito Fiscal a Computar en el Impuesto al Valor Agregado</b></span>
                </div>
                <div class="col-12">
                    <span><b>Identificación del Comprobante que origina el cómputo del crédito</b></span>
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table table-striped table-sm table-bordered">
                    <thead>
                        <tr class="table-secondary">
                            <th>Reserva</th>
                            <th>Fecha</th>
                            <th>Nombre Pasajero</th>
                            <th>Tarifa</th>
                            <th>QN</th>
                            <th>DL</th>
                            <th>XR</th>
                            <th>TQ</th>
                            <th>Adicionales</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="passenger of selectedPax">
                            <td>{{booking.RecordLocator}}</td>
                            <td>{{getDate(booking.CreatedUTC)}}</td>
                            <td>{{passenger.pax.FirstName}} {{passenger.pax.LastName}}</td>
                            <td>{{getConceptAmount(passenger.tickets, '')}}</td>
                            <td>{{getConceptAmount(passenger.tickets, 'QN')}}</td>
                            <td>{{getConceptAmount(passenger.tickets, 'DL')}}</td>
                            <td>{{getConceptAmount(passenger.tickets, 'XR')}}</td>
                            <td>{{getConceptAmount(passenger.tickets, 'TQ')}}</td>
                            <td>{{getConceptAmount(passenger.tickets, null, ['', 'QN', 'DL', 'XR', 'TQ'])}}</td>
                            <td>{{getTicketsTotal(passenger.tickets)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <table class="table table-striped table-sm table-bordered">
                        <tbody>
                            <tr>
                                <td>Importe del Comprobante</td>
                                <td>{{finalTotal}}</td>
                            </tr>
                            <tr>
                                <td>Importe del Crédito Fiscal</td>
                                <td>{{fiscalCredit}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button v-if="allData" type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button v-if="showPreview" type="button" class="btn btn-primary" @click="showPreview = !showPreview">Volver</button>
            <button v-if="!showPreview" type="button" :disabled="!isSelected" class="btn btn-primary" @click="showPreview = !showPreview">Crédito Fiscal</button>
            <button type="button" class="btn btn-secondary" @click="detail = !detail" v-if="!detail">Detalle</button>
            <button type="button" class="btn btn-secondary" @click="detail = !detail" v-else>Resumen</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'tickets',
  mixins: [escape],
  data  () {
    return {
        selected: {},
        detail: false,
        paxName: null,
        documentId: null,
        address: null,
        showPreview: false,
        addressIn: false,
        paxNameIn: false,
        documentIdIn: false,
    }
  },
  mounted: async function() {
    for (let payment of this.booking.Payments) {
        for (let ticket of payment.Tickets) {
            this.selected[ticket.id] = false;
        }
    }
    this.$nextTick(function() {
        $('.modal.printable').on('shown.bs.modal', function () {
            $('.modal-dialog', this).addClass('focused');
            $('body').addClass('modalprinter');

        }).on('hidden.bs.modal', function () {
            $('.modal-dialog', this).removeClass('focused');
            $('body').removeClass('modalprinter');
        });
    });

  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    tickets () {
        let res = [];
        for (let payment of this.booking.Payments) {
            for (let ticket of payment.Tickets) {
                res.push(ticket);
            }
        }
        return res;
    },
    isSelected () {
        for (let i in this.selected) {
            if (this.selected[i]) return true;
        }
        return false;
    },
    selectedPax () {
        let res = {};
        for (let i in this.selected) {
            if (this.selected[i]) {
                let ticket = _.find(this.tickets, (t)=> t.id==i)
                if (ticket) {
                    if (!res[ticket.PassengerID]) {
                        res[ticket.PassengerID] = {pax: _.find(this.booking.Passengers, (p)=>p.PassengerID==ticket.PassengerID), tickets: []};
                    }
                    res[ticket.PassengerID].tickets.push(ticket);
                }
            };
        }
        return res;
    },
    firstPax () {
        for (let id in this.selectedPax) {
            let pax = this.selectedPax[id];
            return pax;
        }
    },
    getCurrentDate () {
        return moment().format("DD/MM/YYYY");
    },
    finalTotal () {
        let res = 0;
        for (let id in this.selectedPax) {
            let pax = this.selectedPax[id];
            res += parseFloat(this.getTicketsTotal(pax.tickets))
        }
        return res.toFixed(2);
    },
    fiscalCredit () {
        let res = 0;
        for (let id in this.selectedPax) {
            let pax = this.selectedPax[id];
            for (let ticket of pax.tickets) {
                for (let charge of ticket.Charges) {
                    if (!charge.ChargeAmount) continue;
                    if (charge.TicketCode=='DL') {
                        res += parseFloat(charge.ChargeAmount);
                    }
                    if (['', 'DL', 'TQ', 'QN'].indexOf(charge.TicketCode)==-1) {
                        res += (1 - 1/1.21) * parseFloat(charge.ChargeAmount);
                    }
                }
            }
        }
        return res.toFixed(2);
    },
    allData () {
        return this.address && this.documentId && this.paxName;
    }

  },
  methods: {
    getDate (d) {
        return app.getDate(d)
    },
    getTime (d) {
        return app.getTime(d)
    },
    getConcepts (ticket) {
        let res = [];
        for (let i in ticket.Charges) {
            let charge = ticket.Charges[i];
            let ticketCode;
            if (charge.TicketCode) {
                ticketCode = charge.TicketCode;
            } else {
                ticketCode = 'Tarifa';
            }
            if (res.indexOf(ticketCode)==-1) {
                res.push(ticketCode);
            }
        }
        return res;
    },
    getTicketTotal (ticket) {
        let res = 0;
        for (let i in ticket.Charges) {
            let charge = ticket.Charges[i];
            if (!charge.ChargeAmount) continue;
            res += parseFloat(charge.ChargeAmount);
        }
        return res.toFixed(2);
    },
    getSegments (charge) {
        let res = '';
        if (charge.DepartureStation && charge.ArrivalStation) {
            res = charge.DepartureStation + '/' + charge.ArrivalStation;
        }
        if (charge.DepartureDate) res = moment(charge.DepartureDate).format("DD MMM") + ' ' + res;
        if (charge.FlightNumber) res = res + ' ' + charge.FlightNumber;
        return res;
    },
    getSegmentsByTicket (ticket) {
        let res = [];
        for (let i in ticket.Charges) {
            let charge = ticket.Charges[i];
            let segment = this.getSegments(charge);
            if (segment) {
                if (res.indexOf(segment)==-1) {
                    res.push(segment);
                }
            }

        }
        return res;

    },
    getPassenger (paxID) {
        let pax = _.find(this.booking.Passengers, (p) => p.PassengerID==paxID);
        if (pax) {
            return pax.LastName + ', ' + pax.FirstName;
        }
        return '';
    },
    setSelected () {
        this.selected = Object.assign({}, this.selected);
    },
    print () {
        window.print();
    },
    getConceptAmount (tickets, concept, notConcepts) {
        let res = 0;
        for (let ticket of tickets) {
            for (let i in ticket.Charges) {
                let charge = ticket.Charges[i];
                if (!charge.ChargeAmount) continue;
                if (!notConcepts && charge.TicketCode==concept) {
                    res += parseFloat(charge.ChargeAmount);
                }
                if (notConcepts && notConcepts.indexOf(charge.TicketCode)==-1) {
                    res += parseFloat(charge.ChargeAmount);
                }
            }
        }
        return res.toFixed(2);
    },
    getTicketsTotal (tickets) {
        let res = 0;
        for (let ticket of tickets) {
            res += parseFloat(this.getTicketTotal(ticket));
        }
        return res.toFixed(2);
    },
    editAddress () {
        this.addressIn = true;
        this.address = null;
    },
    editDocument () {
        this.documentIdIn = true;
        this.documentId = null;
    },
    editPaxName () {
        this.paxNameIn = true;
        this.paxName = null;
    },
  },
}
</script>

