<template>
    <div>

      <report-window endpoint="/reports/paxlist" :fields="fields" :title="title" :headers="headers" :templates="templates" perPage="500"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'paxlist',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FlightNr', editor: 'text', label: 'Vuelo', columns: 1},
        {name: 'ClassOfService', editor: 'text', label: 'Clase', columns: 1},
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'DepartureStation', relation: 'station', optionLabels: 'id'
            , editor: 'select', label: 'Desde'},
        {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
            , editor: 'select', label: 'Hacia'}
            ],
        title: 'Listado de Pasajeros',
        headers: {
            RecordLocator: 'Localizador'
        },
        templates: [
            {name: 'STD', callback (row) {return moment(row.STD).format("HH:mm")}},
            {name: 'STA', callback (row) {return moment(row.STA).format("HH:mm")}},
            {name: 'LiftStatus', callback (row) {
                return {
                    0: 'NO CHEQUEADO',
                    1: 'CHECKIN',
                    2: 'EMBARCADO',
                    3: 'NO SHOW',
                    4: 'ENDOSADO'
                }[row.LiftStatus]
            }}
        ]
    }
  },
}
</script>

