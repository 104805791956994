<template>
    <div>

      <abm v-if="endpoint" :endpoint="'/api/' + endpoint + '/'" :params="params" :tablename="endpoint"></abm>

    </div>
</template>



<script>
import '@/css/app.css';

import abm from '@/components/tools/abm'
import { mapState } from 'vuex';
export default {
  name: 'index',
  props: ['endpoint', 'params'],
  components: {

    abm
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
}
</script>

