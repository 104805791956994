<template>
    <router-link v-if="access"  v-bind:to="'/' + endpoint">
      <a class="nav-item nav-link" @click="redirect">{{label}}</a>
    </router-link>
</template>

<script>
import { mapState } from 'vuex';
import Index from '@/components/tools/Index'
export default {
  name: 'vuemenuitem',
  props: ['endpoint','label','accessgroup'],
  data () {
      return {
        access: false,
      };
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  mounted: async function() {
     if (this.accessgroup){
         this.access = app.canAccess(this.user, 'view','GET', this.accessgroup)
     }else{
         this.access = app.canAccess(this.user, 'view','GET', '/' + this.endpoint)
     }
  },
  methods: {
    redirect () {
      this.$router.push({name: this.endpoint, params: { endpoint: this.endpoint }})
    }
  },
}

</script>


