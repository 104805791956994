import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap-datepicker';
import 'bootstrap-3-typeahead';
import './css/bootstrap.min.css';
import './css/bootstrap-datepicker3.min.css';
import Vue from 'vue';
import App from './App';
import VueRouter from 'vue-router';
import router from './router';
import store from './store/index';
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;
import app from '@/api/AppTools';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
    faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faStar, faPen,
    faSquare, faEdit, faCommentDots, faTimes, faQuestionCircle, faPrint, faTicketAlt, faEnvelope, faDivide}
    from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
    faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faStar, faPen,
    faSquare, faEdit, faCommentDots, faTimes,  faQuestionCircle, faPrint, faTicketAlt, faEnvelope, faDivide)
Vue.component('font-awesome-icon', FontAwesomeIcon);

import blockUI from '@/components/tools/BlockUI';
Vue.component('block-ui', blockUI);


Vue.filter('getDateFormat', window.app.getDateFormat);
Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV!='production';


new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})


