<template>
    <div v-if="seatMap" class="mt-2">
        <div class="form-row">
            <div class="col seat-nr" >
                <span><b></b></span>
            </div>
            <div v-for="c in columns" class="col seat-letter" >
                <span ><b>{{c}}</b></span>
            </div>
            <div class="col seat-nr" >
                <span><b></b></span>
            </div>
        </div>
        <div v-for="r in seatMapRows" class="form-row">
            <div class="col seat-nr" >
                <span><b>{{r}}</b></span>
            </div>
            <a v-for="c in columns" class="col" :class="getSeatClass(r, c)"
                :title="getPaxData(r, c)" @click="assign(r, c)">
                <span v-if="seatMap[r+c] && seatMap[r+c].Status=='BLOCKED'">B</span>
                <span v-else>{{getPaxInfo(r, c)}}</span>
            </a>
            <div class="col seat-nr" >
                <span><b>{{r}}</b></span>
            </div>
        </div>
        <div class="form-row">
            <div class="col seat-nr" >
                <span><b></b></span>
            </div>
            <div v-for="c in columns" class="col seat-letter" >
                <span ><b>{{c}}</b></span>
            </div>
            <div class="col seat-nr" >
                <span><b></b></span>
            </div>
        </div>
        <br>
        <div class="form-row" v-if="canUnblockSeatmap">
            <button type="button" class="btn btn-primary" @click="$emit('showFree')">
            Desbloquear</button>
        </div>

    </div>
</template>

<script>
import '@/css/app.css';
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'seatmap',
  components: {},
  props: ['seatMap', 'paxList', 'currentPax', 'free'],
  data  () {
    return {
        canUnblockSeatmap: false,
        columns: ['A', 'B', 'C', '', 'D', 'E', 'F'],
    }
  },
  mounted () {
    this.canUnblockSeatmap = app.canAccess(this.user, 'api','POST', '/checkin/unblock_seatmap/', false);
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    /*columns () {
        if (!this.seatMap) return [];
        return _.uniq(_.map(this.seatMap, 'Column'));
    },*/
    seatMapRows () {
        if (!this.seatMap) return [];
        return _.uniq(_.map(this.seatMap, 'Row')).sort((a, b) => a - b);
    }
  },
  methods: {
    getSeatClass (r, c) {
        let seat = _.find(this.seatMap, function(s) {
            let unit = r + c;
            return unit==s.UnitDesignator;
        });
        let pax = _.find(this.paxList, function(s) {
            let unit = r + c;
            return unit==s.UnitDesignator;
        });
        if (seat && this.currentPax && seat.UnitDesignator==this.currentPax.UnitDesignator) return 'seat seat-current-pax';
        if (seat && seat.Status=='AVAILABLE') return 'seat seat-available';
        if (seat && seat.LiftStatus==0) return 'seat seat-not-available-not-check';
        if (seat) return 'seat seat-not-available';
        return 'non-seat';
    },
    getPaxData (r, c) {
        let pax = _.find(this.paxList, function(s) {
            let unit = r + c;
            return unit==s.UnitDesignator;
        });
        if (pax) return pax.Passenger.LastName + ', ' + pax.Passenger.FirstName + ' (' + pax.RowNr + ')';
        return '';
    },
    assign (r, c) {
        if (this.seatMap[r+c].Status!='AVAILABLE') return;
        this.$emit('assign', r, c);
    },
    getPaxInfo (r, c) {
        let seat = this.seatMap[r + c];
        if (!seat) return '';
        if (seat.Infant) return 'I';
        if (seat.PaxType=='CHD') return 'C';
        return '';
    },


  },
}
</script>

