<template>
    <home endpoint="flight" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
var moment = require('moment');
export default {
  name: 'flight',
  components: {
    home,
  },
  methods: {
    setSTD (row, FlightDate) {
        app.setSTD(row, FlightDate);
    },
    setSTA (row, FlightDate) {
        app.setSTA(row, FlightDate);
    },

  },
  data  () {
    let self = this;
    return {
      params: {
        serverside: true,
        fields: app.getFlightFields(),
        title: 'Vuelo',
        title2: 'Vuelo',
        afteredit: app.flighAfteredit()
      }
    }
  },
}
</script>

