<template>
  <div class="modal fade bd-example-modal-lg printable" id="flightsummary" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg history-modal" role="document">
      <div class="modal-content summary-modal">
        <div class="modal-body row" v-if="ready">
            <div class="col-4" v-for="station of stations" v-if="stations.length>1">
                <legsummary :paxlist="paxlist" :station="station"></legsummary>
            </div>
            <div class="col-4" >
                <legsummary :paxlist="paxlist"></legsummary>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
import legsummary from '@/components/checkin/LegSummary'
export default {
  name: 'flightsummary',
  props: ['paxlist'],
  components: {
    legsummary
  },
  data  () {
    return {
        stations: [],
        ready: false
    }
  },
  mounted: async function() {
    for (let p of this.paxlist) {
        if (this.stations.indexOf(p.Segment.ArrivalStation)==-1) {
            this.stations.push(p.Segment.ArrivalStation);
        }
        this.ready = true;
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  methods: {
  },
}
</script>

