import Vue from 'vue'
import Vuex from 'vuex';
var moment = require('moment');
Vue.use(Vuex);


const store = new Vuex.Store({
  state: {
    user: null,
    loaded: false,
    stations: null,
    fees: null,
    booking: null,
    processing: false,
  },
  mutations: {
    setUser (state, user) {
        state.user = Object.assign({}, user);
    },
    setStations (state, value) {
        state.stations = value;
    },
    setFees (state, value) {
        state.fees = value;
    },
    setBooking (state, value) {
        state.booking = Object.assign({}, value);
    },
    setProcessing (state, value) {
        state.processing = value;
    },

  },
  getters: {
    user: state => {
      return state.user;
    },
    stations: state => {
      return state.stations;
    },
    fees: state => {
      return state.fees;
    },
    booking: state => {
      return state.booking;
    },
  },

  actions: {
    async setStations ({commit}) {
        commit('setStations', await app.get('/api/station/'));
    },
    async setFees ({commit}) {
        commit('setFees', await app.get('/api/fee/'));
    },

  }

})

export default store;
