<template>
    <home endpoint="fee" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'fee',
  components: {
    home,
  },
  data  () {
    let self = this;
    return {
      params: {
        fields: [
          {name: 'FeeCode', label: 'Código', required: true},
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'Description', label: 'Descripción', required: true},
          {name: 'FeeClass', label: 'Clase', editor: 'select', required: true, defValue: 0,
              options: [
                    {value: 0, label: 'PASSENGER'},
                    {value: 1, label: 'SEGMENT'},
                    {value: 2, label: 'BOOKING'},
                    ]},
          {name: 'BasePrice', label: 'Precio', editor: 'number'},
          {name: 'Lid', label: 'Max. Por Vuelo', editor: 'number'},
          /*{name: 'FlexQuantity', label: 'Cantidad Variable', editor: 'checkbox'},
          {name: 'PriceByMarket', label: 'Precio por Mercado', editor: 'checkbox'},*/
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          //{name: 'InActive', label: 'Inactivo', editor: 'checkbox'},
          ],
        title: 'Cargos',
        title2: 'Cargo',
        primaryKey: 'FeeCode',
      }
    }
  },
}
</script>

