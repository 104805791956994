<template>
  <div id="app" class="app-index">
    <navbar v-if="user && user.id"></navbar>
    <router-view></router-view>
    <block-ui v-if="processing"/>
    <footer></footer>
  </div>
</template>

<script>
import Navbar from '@/components/tools/Navbar';
import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    'navbar': Navbar
  },
  data  () {
      return {}
  },
  computed: {
    ...mapState({
        user: state => state.user,
        processing: state => state.processing,
    }),
  },
  mounted: async function () {
    if (this.user)  {
        this.$store.dispatch('setStations');
        this.$store.dispatch('setFees');
    }
  },
}
</script>
