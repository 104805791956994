<template>
    <div>
        <div class="container">
            <div class="form-row">
                <div class="form-group col-md-2 row report-header">
                    <div class="col-10 text-center">
                        <h5>Buscar Reserva</h5>
                    </div>
                    <div class="col-md-10">
                        <label>Localizador</label>
                        <input v-model="RecordLocator" name="RecordLocator" :disabled="ifNames || ifEmail || ifDocNumber || ifBookingID"
                        class="form-control" type="text" placeholder="" id="RecordLocator">
                    </div>
                    <div class="col-md-10">
                        <label>Apellido</label>
                        <input v-model="LastName" name="LastName" :disabled="ifRecordLocator || ifEmail || ifDocNumber || ifBookingID"
                        class="form-control" type="text" placeholder="" id="LastName">
                    </div>
                    <div class="col-md-10">
                        <label>Nombre</label>
                        <input v-model="FirstName" name="FirstName" :disabled="ifRecordLocator || ifEmail || ifDocNumber || ifBookingID"
                        class="form-control" type="text" placeholder="" id="FirstName">
                    </div>
                    <div class="col-md-10">
                        <label>Email</label>
                        <input v-model="Email" name="Email" :disabled="ifRecordLocator || ifNames || ifDocNumber || ifBookingID"
                        class="form-control" type="text" placeholder="" id="Email">
                    </div>
                    <div class="col-md-10">
                        <label>Documento</label>
                        <input v-model="DocNumber" name="DocNumber" :disabled="ifRecordLocator || ifNames || ifEmail || ifBookingID"
                        class="form-control" type="text" placeholder="" id="DocNumber">
                    </div>
                    <div class="col-md-10">
                        <label>Booking ID</label>
                        <input v-model="BookingID" name="BookingID" :disabled="ifRecordLocator || ifEmail || ifDocNumber || ifNames"
                        class="form-control" type="text" placeholder="" id="BookingID">
                    </div>

                    <div class="col-md-10">
                        <div class="spacer"></div>
                        <button type="button" class="btn btn-primary button-row" @click="searchBookings">Buscar</button>
                    </div>
                    <div class="col-md-10">
                        <div class="spacer"></div>
                        <button type="button-row" class="btn btn-primary" @click="newBooking">Nueva Reserva</button>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <v-client-table :data="list" :columns="columnsNames" :options="options" v-if="!processing && list">
                    </v-client-table>
                    <div v-if="processing">
                        <img class="loading-gif" src="@/img/loading.gif">
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import '@/css/app.css';

import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
var moment = require('moment');
export default {
  name: 'booking_search',
  components: {

  },
  data  () {
    return {
        RecordLocator: null,
        LastName: null,
        FirstName: null,
        DocNumber: null,
        Email: null,
        BookingID: null,
        processing: false,
        list: null,
        columnsNames: ['RecordLocator', 'Passenger', 'Market', 'FlightDate', 'Status'],
        options: {
            filterable: false,
            perPageValues: [10],
            perPage: 10,
                headings: {},
                //destroyEventBus: true,
                texts: app.vueTableText(),
                templates: {},
                skin: 'table table-striped  table-sm table-bordered',
            }

    }
  },
  mounted () {
    this.options.templates = this.getTemplates;
    this.options.headings = this.getHeadings;
    let self = this;
    Event.$on('vue-tables.row-click', async function (data) {
        self.processing = true;
        let res = await app.get('/api/booking/' + data.row.BookingID)
        if (res) {
            self.$store.commit('setBooking', res);
            self.processing = false;
            self.$router.push({ name: 'booking'})
        }
    });

  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    ifRecordLocator () {
        return this.RecordLocator!=null && this.RecordLocator.length>0;
    },
    ifDocNumber () {
        return (this.DocNumber!=null && this.DocNumber.length>0);
    },
    ifBookingID () {
        return (this.BookingID!=null && this.BookingID.length>0);
    },
    ifEmail () {
        return this.Email!=null && this.Email.length>0;
    },
    ifNames  () {
        if (this.FirstName || this.LastName) return true;
    },
    myfields () {
        return [
            {name: 'Status', label: 'Estado'},
            {name: 'FlightDate', label: 'Fecha'},
            {name: 'FlightNumber', label: 'Vuelo'},
            {name: 'RecordLocator', label: 'Localizador'},
            {name: 'Passenger', label: 'Pasajero'},
            {name: 'Market', label: 'Ruta'},
        ]
    },
    getTemplates () {
        let self = this;
        let res = {}
        res.Passenger = function(t, row) {
            return row.LastName + ', ' + row.FirstName;
        }
        res.Market = function(t, row) {
            if (row.DepartureStation && row.ArrivalStation) {
                return row.DepartureStation + '-' + row.ArrivalStation;
            }
            return ""
        }
        res.FlightDate = function(t, row) {
            if (row.DepartureDate) {
                return moment(row.DepartureDate).format("DD/MM/YYYY");
            }
            return ""
        }
        let status = {
            '1': 'Hold',
            '2': 'Confirmado',
            '3': 'Cerrado',
            '4': 'Cancelado'
            }
        res.Status = function(t, row) {
            return status[row.Status];
        }
        return res;
    },
    getHeadings () {
        let res = {};
        for (let f of this.myfields) {
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    },


  },
  methods: {
    setInvalidClass (id, fieldname) {
        let div = $("#" + id)[0];
        div.classList.remove('is-invalid')
        if (!this[fieldname]) {
            div.classList.add('is-invalid')
            return true;
        }
        return false;
    },
    searchBookings: async function() {
        let required = false;
        if (!this.RecordLocator && !(this.FirstName && this.LastName) && !this.Email && !this.DocNumber && !this.BookingID) {
            if (this.setInvalidClass('RecordLocator', 'RecordLocator')) required = true;
            if (this.setInvalidClass('FirstName', 'FirstName')) required = true;
            if (this.setInvalidClass('LastName', 'LastName')) required = true;
            if (this.setInvalidClass('Email', 'Email'));
            if (this.setInvalidClass('BookingID', 'BookingID'));
            if (required) return;
        }
        this.processing = true;
        let data = {
            RecordLocator: this.RecordLocator,
            Email: this.Email,
            DocNumber: this.DocNumber,
            FirstName: this.FirstName,
            LastName: this.LastName,
            BookingID: this.BookingID,
        }
        let res = await app.get('/api/booking/search', data)
        if (res) {
            this.list = res;
            this.processing = false;
        }
    },
    newBooking () {
        let booking = app.newBooking();
        this.$store.commit('setBooking', booking);
        this.$router.push({ name: 'booking'})
    }
  },
  beforeDestroy() {
        Event.$off('vue-tables.row-click');
  },

}
</script>

