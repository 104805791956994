<template>
  <div class="modal fade bd-example-modal-lg" id="accessgroupwindow" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" v-if="current">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
              Permisos de Usuario {{current.Description}}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-if="errors.length>0" class="alert alert-danger" role="alert">
            <div v-for="error of errors">
                {{error}}
            </div>
        </div>
        <div class="modal-body" v-if="current">
          <div class="form-row">
              <div class="col-md-3">
                  <div class="form-group">
                      <label for="Description" >Descripción</label>
                      <input v-model="current.Description" name="Description" class="form-control "
                      type="text" id="Description" >
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="form-group">
                      <label for="RoleCode">Role Code</label>
                      <input v-model="current.RoleCode" name="RoleCode" class="form-control "
                      type="text" id="RoleCode" >
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="form-check">
                      <input v-model="current.Closed" name="Closed" class="form-check-input" type="checkbox">
                      <label for="Closed" class="form-check-label">Cerrado</label>
                  </div>
              </div>
          </div>
          <div class="form-row">
              <div class="col-md-12">
                  <div class="form-group">
                      <label for="Permissions" >Permisos</label>
                      <jsonview :permissions.sync="current.permissions" :accessgroups="accessGroups"
                      :pjson.sync="current.Permissions" v-if="accessGroups" :id="current.id"></jsonview>
                  </div>
              </div>
          </div>
          <!--div class="form-row">
              <div class="col-md-12">
                  <div class="form-group">
                      <textarea v-model="current.Permissions" rows="3" class="form-control"></textarea>
                  </div>
              </div>
          </div-->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="save(current)">Guardar cambios</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">Cerrar</button>
          <button type="button" class="btn btn-danger" @click="remove(current)">Eliminar</button>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import jsonview from '@/components/tools/JsonView'
export default {
    name: 'accessgroupwindow',
    props: ['endpoint', 'currentrecord', 'accessGroups'],
    components: {
      jsonview
    },
    data  () {
        return {
            current: null,
            errors: [],
            canEdit: false,
            canDelete: false,
            processing: false,
            fieldOptions: {},
            getInvalidClass: {},
            myfields: ['Description'],
        }
    },
    computed:{
    },
    created (){
    },
    mounted  () {
        this.current = _.cloneDeep(this.currentrecord);
        this.canEdit = app.canAccess(this.user, 'api','POST', this.endpoint)
        this.canDelete = app.canAccess(this.user, 'api','DELETE', this.endpoint)
        app.addEscapeKey(this, '#accessgroupwindow')
    },
    watch: {
        current: {
            handler (oldValue,newValue) {
                        if (oldValue && newValue) {
                            if (oldValue.Permissions!=newValue.Permissions) {
                                this.current.permissions = JSON.parse(this.current.Permissions)
                            }
                        }
                    },
                    deep: true,
        },
        currentrecord () {
            this.current = _.cloneDeep(this.currentrecord);
        },

    },
    methods: {
        save (record) {
            app.saveRecord(this, record, '#accessgroupwindow')
        },
        remove (record) {
            app.remove(this,record)
        },
        addError   (msg) {
            app.addError(this, msg)
        },
    }
}
</script>

