<template>
    <div class="input-group date date-picker" :id="'date-picker' + getId">
        <input type="text" class="form-control" v-model="value" :id="'date-picker-input'+id" :readonly="readonly"
        :disabled="disabled" @change="setDate" autocomplete="off" :class="classes">
        <span class="input-group-addon">
            <i class="glyphicon glyphicon-calendar"></i>
        </span>
    </div>
</template>

<script>
var moment = require('moment');
export default {
    name: 'date-picker',
    props: ['def', 'id', 'disabled', 'readonly', 'disablePast', 'addClass', 'label', 'labelClass', 'record', 'startDate'],
    data () {
      return {
          value: null,
          show: true,
      };
    },
    computed: {
        getId () {
            if (!this.id) return this._uid;
            return this.id;
        },
        classes () {
            let r = [];
            if (this.value) r.push('fill');
            if (this.addClass) r.push(this.addClass);
            return r.join(' ');
        }
    },
    mounted () {
      if (this.readonly) return;
      if (this.def) this.value = moment(this.def, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.onMount();
    },
    watch: {
        def () {
            if (this.def)  {
                this.value = moment(this.def, "YYYY-MM-DD").format("DD/MM/YYYY");
            } else {
                this.value = null;
            }
        },
        record: {
            handler () {
                if (this.id != 'ToDate' && this.id != 'EndDate') return;
                let date = this.getStartDate();
                let dp = $('#date-picker' + this.getId);
                dp.datepicker( "option", 'startDate', date );
                this.show = false;
                setTimeout(() => {
                    this.show = true;
                    this.onMount();
                }, 1)
            },
            deep: true,
        },
    },
    methods: {
        onMount () {
            let self = this;
            this.$nextTick(() => {
                let date = self.getStartDate();
                date.setDate(date.getDate());
                $('#date-picker' + this.id).datepicker({
                    format: 'dd/mm/yyyy',
                    todayHighlight: true,
                    autoclose: true,
                    startDate: date,
                    language: "en",
                }).on(
                    'changeDate', () => {
                    self.value = $('#date-picker-input' + self.id).val();
                    self.setDate();
                  }
                )
                /*.on('show', () => {
                    if ($('.datepicker').length>0) {
                        var topd = parseInt($('.datepicker').css('top').replace('px', ''));
                        topd += 80;
                        $('.datepicker').css('top', topd);
                    }
                });*/
            })

        },
        setDate () {
            let d = moment(this.value, "DD-MM-YYYY").format("YYYY-MM-DD");
            if (d=='Invalid date') {
                d = null;
                this.value = null;
            };
            this.$emit('update:def', d);
            this.$emit('changedate');
        },
        getStartDate () {
            let date;
            if (this.disablePast) {
                date = new Date();
            } else if (this.startDate) {
                date = new Date(moment(this.startDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else if (this.record && this.record.FromDate && this.id == 'ToDate') {
                date = new Date(moment(this.record.FromDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else if (this.record && this.record.StartDate && this.id == 'EndDate') {
                date = new Date(moment(this.record.StartDate).add(1 ,'days').format("YYYY-MM-DD"));
            } else {
                date = new Date('1900-01-01');
            }
            return date;
        }
    },
}
</script>
