<template>
    <div>
        <div class="container" v-if="ready">
            <div class="form-row">
                <div class="form-group col-2 form-row report-header">
                    <div class="col-10 text-center">
                        <h5>Agregar por Período</h5>
                    </div>
                  <div class="col-md-10">
                      <label for="FlightNumber" >Número de Vuelo</label>
                      <input v-model="FlightNumber" name="FlightNumber" field-editor="FlightNumber"
                      class="form-control" type="text" placeholder="" id="FlightNumber">
                  </div>
                  <div class="col-md-10">
                      <label for="FromDate">Desde</label>
                      <datepicker id="FromDate" :def.sync="FromDate" @changedate="checkDate('FromDate')"></datepicker>
                  </div>
                  <div class="col-md-10">
                      <label for="ToDate">Hasta</label>
                      <datepicker id="ToDate" :def.sync="ToDate" @changedate="checkDate('ToDate')"></datepicker>
                  </div>
                  <div class="col-md-10">
                      <selectinput fieldname="Status" :fieldOptions="fieldOptions" label="Estado" id="Status"
                      :current_value.sync="Status">
                      </selectinput>
                  </div>
                  <div class="col-md-10 mb-3">
                      <selectinput fieldname="CustomerID" :fieldOptions="fieldOptionsCalc" label="Cliente" id="CustomerID"
                      :current_value.sync="CustomerID">
                      </selectinput>
                  </div>
                  <div class="col-md-5 ml-2" v-for="(row, id) of WeekDays">
                      <input type="checkbox" :id="row.label" v-model="row.value" >
                      <label class="form-check-label">{{row.label}}</label>
                  </div>
                  <div class="col-md-10 mt-3">
                    <div class="spacer"></div>
                    <button type="button" class="btn btn-primary" @click="addManyFlight" :disabled="!allSelected">
                        Crear Vuelos
                    </button>
                  </div>
                </div>
                <div class="col-10">
                    <div class="col-12 mb-3" v-if="flightFields">
                        <div class="">
                            <h5>Legs</h5>
                        </div>
                        <div v-if="inventoryLegsFields">
                          <div class="form-row" v-for="(row, rowNr) of InventoryLegs">
                              <div v-for="rowField of rowFields" class="form-group col">
                                  <rowinputfield :rowField="rowField" :defValue.sync="InventoryLegs[rowNr][rowField.name]" :arrayField="inventoryLegsFields"
                                    :fieldOptions="flightFieldsOptions" :row="InventoryLegs[rowNr]" :rowNr="rowNr" @afteredit="afterEdit"
                                    ></rowinputfield>
                              </div>
                              <div class="form-group col-md-1">
                                 <button type="button" class="btn btn-primary row-delete-button" rel="tooltip"
                                 data-placement="top" title="Borrar" @click="removeRow(rowNr)">
                                    <font-awesome-icon icon="trash-alt"/>
                                 </button>
                              </div>
                          </div>
                          <div class="row form-group col-md-12 mt-2">
                              <button type="button" class="btn btn-primary" @click="addRow()">Agregar</button>
                          </div>
                        </div>

                    </div>
                    <div class="col-12 border-top">
                        <flight-fares :record="record" ref="FlightFares" class="w-100 mx-0"></flight-fares>
                    </div>
                    <div class="col-12 border-top">
                        <v-client-table :data="list" :columns="columnsNames" :options="options" v-if="!processing && list"
                            @row-click="onRowClick">
                        </v-client-table>
                        <div v-if="processing">
                            <img class="loading-gif" src="@/img/loading.gif">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import '@/css/app.css';

import { mapState } from 'vuex';
import datepicker from '@/components/tools/DatePicker';
import selectinput from '@/components/tools/SelectInput';
import Vue from 'vue/dist/vue.esm'
import rowinputfield from '@/components/tools/RowInputField'
import flightFares from '@/components/tools/FlightFares';

import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);

var moment = require('moment');
export default {
  name: 'add-many',
  components: {
    datepicker,
    selectinput,
    rowinputfield,
    flightFares,
  },
  data  () {
    return {
        current: null,
        ready: false,
        InventoryLegs: [],
        datalist: [],
        fields: app.getFlightFields(),
        params: {
            serverside: true,
            title: 'Vuelo',
            title2: 'Vuelo',
            afteredit: app.flighAfteredit()
        },
        FlightNumber: null,
        FromDate: moment(new Date()).format("YYYY-MM-DD"),
        ToDate: moment(new Date()).format("YYYY-MM-DD"),
        Status: null,
        CustomerID: null,
        list: null,
        processing: false,
        fieldOptionsCalc: {},
        flightFields: null,
        flightFieldsOptions: null,
        inventoryLegsFields: null,
        rowFields: [],
        WeekDays: {},
        fieldOptions: {
            WeekDay: [
                {value: '0', label: 'Lunes'},
                {value: '1', label: 'Martes'},
                {value: '2', label: 'Miércoles'},
                {value: '3', label: 'Jueves'},
                {value: '4', label: 'Viernes'},
                {value: '5', label: 'Sábado'},
                {value: '6', label: 'Domingo'},
            ],
            Status: [
                {value: 'CONFIRMED', label: 'Confirmado'},
                {value: 'SUSPENDED', label: 'Suspendido'},
                {value: 'CANCELED', label: 'Cancelado'},
            ]
        },
        columnsNames: ['FlightNumber', 'FlightDate', 'WeekDay', 'Status'],
        options: {
            perPageValues: [5, 10, 15, 20, 25, 50],
            filterable: false,
            perPage: 50,
                headings: {},
                //destroyEventBus: true,
                texts: app.vueTableText(),
                templates: {},
                skin: 'table table-striped  table-sm table-bordered',
            }
     }
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    myfields  () {
        return app.myfields(this.fields);
    },
    getTemplates () {
        let self = this;
        let res = {}
        for (let field of this.myfields) {
            res[field.name] = function(t, row) {
                    return self.getDisplayValue(row, field);
                }
        }
        return res;
    },
    getHeadings () {
        let res = {};
        for (let f of this.myfields) {
            if (f.type=='password') continue;
            if (f.hidden) continue;
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    },
    allSelected () {
        if (!this.FlightNumber) return false;
        if (!this.Status) return false;
        for (let row of this.InventoryLegs) {
            if (row.DepartureStation == row.ArrivalStation) return false;
            if (!row.DepartureStation) return false;
            if (!row.ArrivalStation) return false;
            if (!row.STDTime) return false;
            if (!row.STATime) return false;
            if (!row.SalesConfigurationId) return false;
            if (!row.LegStatus) return false;
        }
        for (let id in this.WeekDays) {
            if (this.WeekDays[id] && this.WeekDays[id].value) return true;
        }
    },
    record () {
        return {
            FromDate: this.FromDate,
            ToDate: this.ToDate,
            InventoryLegs: this.InventoryLegs,
            invalid: {
                FlightFares: false
            }
        }
    }
  },
  async mounted () {
    this.options.templates = this.getTemplates;
    this.options.headings = this.getHeadings;
    this.fieldOptionsCalc = await app.calculateFieldOptions(this);
    for (let day of this.fieldOptions.WeekDay) {
        this.WeekDays[day.value] = {value: null, label: day.label}
    }
    this.WeekDays = Object.assign({}, this.WeekDays);
    this.flightFields = app.myfields(app.getFlightFields())
    this.flightFieldsOptions = await app.calculateFieldOptions(this, null, this.flightFields);
    this.inventoryLegsFields = _.find(this.flightFields, (r) => r.name == 'InventoryLegs');
    this.rowFields = _.filter(this.inventoryLegsFields.rowFields, (r) => {
        if (r.name == 'STD') return false;
        if (r.name == 'STA') return false;
        return true;
    })
    this.ready = true;
  },
  methods: {
    showRecord: async function(c) {
      let syncVersion = await app.get('/api/get_sync_version/flight/' + c.id);
      if (syncVersion && syncVersion!=c.syncVersion) {
        c = await app.get('/api/flight/' + c.id);
        if (c)  {
            c.original_id = c.id;
            this.current = c;
        }
      } else {
        c.original_id = c.id;
        this.current = c;
      }
      this.$nextTick(function () {
        $('#modal-abm-window').modal('show')
      })
    },
    closeCurrent () {
       $('#modal-abm-window').modal('hide');
       this.current = false;
    },
    checkDate (fieldname) {
        if (this.$refs.FlightFares) this.$refs.FlightFares.getFares()
        /*if (moment(this[fieldname]) < moment(new Date())) {
            let self = this;
            this.$nextTick(function() {
                self[fieldname] = moment(new Date()).format("YYYY-MM-DD");
            })
        }*/
    },
    setInvalidClass (id, fieldname) {
        let div = $("#" + id)[0];
        div.classList.remove('is-invalid')
        if (!this[fieldname]) {
            div.classList.add('is-invalid')
            return true;
        }
        return false;
    },
    getDisplayValue (record, field) {
        return app.getDisplayValue(this.fieldOptions, record, field)
    },
    onRowClick (data) {
        this.showRecord(data.row);
    },
    async addManyFlight () {
      let required = false;
      if (this.setInvalidClass('date-picker-inputToDate', 'ToDate')) required = true;
      if (this.setInvalidClass('date-picker-inputFromDate', 'ToDate')) required = true;
      if (this.setInvalidClass('FlightNumber', 'FlightNumber')) required = true;
      if (this.setInvalidClass('Status', 'Status')) required = true;
      if (required) return;
      let data = {
          FlightNumber: this.FlightNumber,
          Status: this.Status,
          CustomerID: this.CustomerID,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
          WeekDays: this.WeekDays,
          legs: this.InventoryLegs
      }
      this.processing = true;
      let res = await app.postJSON('/api/flight/add_many', data, this);
      if (res) {
          this.list = res.flights;
          this.processing = false;
          if (!res.sync) alert('Error al sincronizar. Deben sincronizarse manualmente')
      }

    },
    removeRow (rowNr) {
        this.InventoryLegs.splice(rowNr, 1);
        this.InventoryLegs = Object.assign([], this.InventoryLegs);
    },
    addRow () {
        let row = {}
        for (let frow of this.rowFields){
            row[frow.name] = null;
        }
        this.InventoryLegs.push(row);
    },
    afterEdit () {
        if (this.$refs.FlightFares) this.$refs.FlightFares.getFares()
    }
  },
}
</script>

