<template>
    <div>

      <report-window endpoint="/reports/cash_summary" :fields="fields" :title="title" :headers="headers"
      :templates="templates" :columnsClasses="columnsClasses"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'cashsummary',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'UserId', editor: 'text', label: 'Usuario', columns: 1},
        {name: 'LocationId', relation: 'location', optionLabels: 'id'
            , editor: 'select', label: 'Ubicación', required: true},
        {name: 'Mode', label: 'Expandir', editor: 'select', defValue: '0',
            options: [
                {value: '0', label: 'No'},
                {value: '1', label: 'Si'},
                ]},
            ],
        title: 'Cierre de Caja',
        headers: {Name: 'Nombre', PaymentMethodCode: 'Forma de Pago', Date: 'Fecha', CollectedCurrencyCode: 'Moneda', Amount: 'Monto'},
        templates: [
            {name: 'Date', callback (row) {
                if (row.Date) return moment(row.Date).format("DD/MM/YYYY");
                return '';
            }},
            {name: 'Amount', callback (row) {return app.numberFormat(parseFloat(row.Amount));}},
        ],
        columnsClasses: {Amount: 'text-right'},

    }
  },
}
</script>

