<template>
    <div class="app-notification-wrapper">
       <div class="alert alert-success alert-dismissible fade show app-notification fadeOut" v-if="notifications.length>0">
          <div v-for="n,idx of notifications">
              <strong>{{n.msg}}</strong></br>
          </div>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'appnotification',
    props: ['notifications'],
    methods: {
        close (record) {
            this.$emit('removenotifications')
        },
    }
}
</script>
