<template>
  <div class="modal fade printable" id="fee-print" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog history-modal" role="document">
      <div class="modal-content">
        <div class="modal-body itinerary-body">
            <div class="row">
                 <div class="col-12" align="center"><img src="https://www.andesonline.com/wp-content/themes/andes/images/logo.png" class="company-logo"></div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col-6"><h5><b>Código de la Reserva:</b> {{this.booking.RecordLocator}}</h5></div>
            </div>
            <br>
            <div class="row">
                <div class="col-3"><b>Pasajero</b></div>
                <div class="col-4"><b>Vuelo</b></div>
                <div class="col-3"><b>Concepto</b></div>
                <div class="col-1"><b class="amount-right">Moneda</b></div>
                <div class="col-1"><b class="amount-right">Importe</b></div>
            </div>
            <hr>
            <div class="row" v-for="(charge, idx) of selectedFees">
                <div class="col-3">
                    <span v-if="idx==0">{{charge.Passenger}} </span>
                    <span v-else></span>
                </div>
                <div class="col-4"><span>{{charge.Info}}</span></div>
                <div class="col-3"><span>{{getFeeName(charge.FeeCode)}}</span></div>
                <div class="col-1"><span class="amount-right">{{charge.CurrencyCode}}</span></div>
                <div class="col-1"><span class="amount-right">{{parseFloat(charge.ChargeAmount).toFixed(2)}}</span></div>
            </div>
            <hr>
            <br>
            <br>
            <!--div class="row">
                <div class="col-2"><b>Forma de Pago:</b></div>
                <div class="col-2">{{payment.PaymentMethod.Name}}</div>
            </div>
            <div class="row">
                <div class="col-2"><b>COSTO TOTAL:</b></div>
                <div class="col-2">{{payment.CurrencyCode}} {{parseFloat(payment.PaymentAmount).toFixed(2)}}</div>
            </div-->
            <div class="row">
                <div class="col-12">
                  <br/>
                  Seg&uacute;n RG 1415/03 de AFIP, en su Apartado I Inc. e):
                  "Las empresas que presten servicios de transporte p&uacute;blico de pasajeros est&aacute;n exceptuadas de emitir comprobantes,
                  esta excepci&oacute;n alcanza a la venta de pasajes a&eacute;reos realizadas por las Agencias de Viajes y Turismo".
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col-12 itinerary-footer">0810-777-2633 - www.andesonline.com</div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'feeprint',
  mixins: [escape],
  props: ['selectedFees'],
  components: {
  },
  data  () {
    return {
    }
  },
  mounted: async function() {
    this.$nextTick(function() {
        $('.modal.printable').on('shown.bs.modal', function () {
            $('.modal-dialog', this).addClass('focused');
            $('body').addClass('modalprinter');

        }).on('hidden.bs.modal', function () {
            $('.modal-dialog', this).removeClass('focused');
            $('body').removeClass('modalprinter');
        });
    });

  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
        fees: state => state.fees,
    }),
  },
  methods: {
    getDate (d) {
        return app.getDate(d)
    },
    getTime (d) {
        return app.getTime(d)
    },
    getSegments (charge) {
        let res = [];
        for (let segment of charge.segments) {
            let flightNr = segment.FlightNumber? segment.FlightNumber: '';
            res.push(moment(segment.Legs[0].STD).format("DD MMM") + ' ' + flightNr
                + ' ' + segment.DepartureStation + '-' + segment.ArrivalStation);
        }
        return res.join(', ');
    },
    getPassenger (paxID) {
        let pax = _.find(this.booking.Passengers, (p) => p.PassengerID==paxID);
        if (pax) {
            return pax.LastName + ', ' + pax.FirstName;
        }
        return '';
    },
    print () {
        window.print();
    },
    getFeeName (code) {
        let fee = _.find(this.fees, (f)=>f.FeeCode == code);
        if (fee) return fee.Name;
        return code;
    }
  },
}
</script>

