<template>
    <home endpoint="customer" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'customer',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'Logo', label: 'Logo'},
        ],
        title: 'Clientes',
        title2: 'Cliente'
      }
    }
  },
}
</script>

