<template>
    <home endpoint="feeprice" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'feeprice',
  components: {
    home,
  },
  data  () {
    let self = this;
    return {
      params: {
        fields: [
          {name: 'FeeCode', relation: 'fee', optionLabels: 'Name'
                , editor: 'select', label: 'Cargo', required: true, id: 'FeeCode'},
          {name: 'DepartureStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Desde', required: true},
          {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Hacia', required: true},
          {name: 'BasePrice', label: 'Precio Base', editor: 'number'},
          {name: 'UnitPrice', label: 'Precio Unitario', editor: 'number'},
          ],
        title: 'Precio de Extras',
        title2: 'Precio de Extras',
      }
    }
  },
}
</script>

