<template>
    <div>
        <div class="container">
            <div class="form-row">
                <div class="form-group col-md-2 row report-header">
                    <div class="col-10 text-center">
                        <h5>Buscar Vuelos</h5>
                    </div>
                    <div class="col-md-10">
                        <label for="DepartureDate">Fecha</label>
                        <datepicker id="DepartureDate" :def.sync="DepartureDate"></datepicker>
                    </div>
                    <div class="col-md-10">
                        <label>Origen</label>
                        <input v-model="DepartureStation" name="DepartureStation"
                        class="form-control" type="text" placeholder="" id="DepartureStation">
                    </div>
                    <div class="col-md-10">
                        <label>Vuelo Nr</label>
                        <input v-model="FlightNumber" name="FlightNumber"
                        class="form-control" type="text" placeholder="" id="FlightNumber">
                    </div>
                    <div class="col-md-10">
                        <div class="spacer"></div>
                        <button type="button" class="btn btn-primary" @click="searchFlights">Buscar Vuelos</button>
                    </div>
                </div>
                <div class="form-group col-md-8">
                    <v-client-table :data="list" :columns="columnsNames" :options="options" v-if="!processing && list">
                    </v-client-table>
                    <div v-if="processing">
                        <img class="loading-gif" src="@/img/loading.gif">
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import '@/css/app.css';
import datepicker from '@/components/tools/DatePicker'

import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
var moment = require('moment');
export default {
  name: 'checkin',
  components: {

    datepicker
  },
  data  () {
    return {
        //DepartureDate: moment("2019-02-27").format("YYYY-MM-DD"),
        DepartureDate: moment(new Date()).format("YYYY-MM-DD"),
        FlightNumber: null,
        DepartureStation: null,
        processing: false,
        list: null,
        columnsNames: ['RowNr', 'DepartureStation', 'ArrivalStation', 'DepartureDate', 'FlightNumber', 'LegStatus',
            'STD', 'STA', 'CNT', 'NO_SHOW', 'CHECKIN'],
        options: {
            filterable: false,
            perPageValues: [10],
            perPage: 10,
                headings: {},
                //destroyEventBus: true,
                texts: app.vueTableText(),
                templates: {},
                skin: 'table table-striped  table-sm table-bordered',
            }

    }
  },
  mounted () {
    this.options.templates = this.getTemplates;
    this.options.headings = this.getHeadings;
    let self = this;
    Event.$on('vue-tables.row-click', function (data) {
        self.$router.push({ name: 'checkinflight', params: { inventoryleg: data.row }})
    });

  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    myfields () {
        return [
            {name: 'RowNr', label: 'Nr'},
            {name: 'DepartureStation', label: 'Desde'},
            {name: 'ArrivalStation', label: 'Hacia'},
            {name: 'FlightNumber', label: 'Vuelo Nr'},
            {name: 'LegStatus', label: 'Estado'},
            {name: 'STD', label: 'Salida'},
            {name: 'DepartureDate', label: 'Fecha'},
            {name: 'STA', label: 'Llegada'},
            {name: 'CNT', label: 'Pasajeros'},
            {name: 'NO_SHOW', label: 'No Show'},
            {name: 'CHECKIN', label: 'Chequeados'},
        ]
    },
    getTemplates () {
        let self = this;
        let res = {}
        let status = {
            'OPEN': '',
            'CLOSED': 'CLSD',
            'CANCELED': 'CNLX',
            'CLOSE_PENDING': 'CLSP',
            'SUSPENDED': 'SPND'
            }
        res.LegStatus = function(t, row) {
            return status[row.LegStatus];
        }
        res.STD = function(t, row) {
            return moment(row.STD).format("HH:mm");
        }
        res.STA = function(t, row) {
            return moment(row.STA).format("HH:mm");
        }
        return res;
    },
    getHeadings () {
        let res = {};
        for (let f of this.myfields) {
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    },


  },
  methods: {
    searchFlights: async function() {
        this.processing = true;
        let data = {
            DepartureDate: this.DepartureDate
        }
        if (this.FlightNumber) data.FlightNumber = this.FlightNumber;
        if (this.DepartureStation) data.DepartureStation = this.DepartureStation;
        let res = await app.get('/api/checkin/get_flights_by_date', data)
        if (res) {
            let c = 1;
            for (let row of res) {
                row.RowNr = c;
                c++;
            }
            this.list = res;
            this.processing = false;
        }
    },
  },
  beforeDestroy() {
        Event.$off('vue-tables.row-click');
  },
}
</script>

