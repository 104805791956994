<template>
    <div>
        <div class="text-center">
            <h5>Resumen</h5>
        </div>
        <div class="flight-info" v-if="summary">
            <div class="form-row">
                <span class="col-md-6" v-for="(v, r) in LiftStatusValues">
                    {{LiftStatusValues[r]}}: {{summary.PaxStatus[r]}}
                </span>
                <span class="col-md-12">
                    TOTAL: {{total}}
                </span>
            </div>
            <br>
            <div class="form-row text-center mt-3">
                <div class="col-12">
                    <a href="#" @click="showSummary" class="text-center"> <h6><u>Cierre de Vuelo</u></h6> </a>
                </div>
            </div>
        </div>
        <flightsummary v-if="summaryReport" @close="closeSummary" :paxlist="paxlist"></flightsummary>
    </div>
</template>

<script>
import '@/css/app.css';
import { mapState } from 'vuex';
import flightsummary from '@/components/checkin/FlightSummary';
var moment = require('moment');
export default {
  name: 'checkinflightinfo',
  components: {
    flightsummary
  },
  props: ['summary', 'paxlist'],
  data  () {
    return {
        LiftStatusValues: {
            0: 'NO CHEQUEADO',
            1: 'CHECKIN',
            2: 'EMBARCADO',
            3: 'NO SHOW',
            4: 'ENDOSADO'
        },
        summaryReport: false
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    total () {
        let res = 0;
        for (let i in this.summary.PaxStatus) {
            res += this.summary.PaxStatus[i];
        }
        return res;
    },
  },
  methods: {
    showSummary () {
        this.summaryReport = true;
        this.$nextTick( function() {
            $('#flightsummary').modal('show')
        });
    },
    closeSummary () {
        this.summaryReport = false;
        $('#flightsummary').modal('hide')
    },

  },
}
</script>
