<template>
      <div class="modal-content">
        <div class="modal-body">
            <div class="form-row">
                <div class="col-md-2">
                    <label >Apellido</label>
                    <input v-model="pax.LastName" class="form-control" type="text" id="LastName" :disabled="!canChangeName">
                </div>
                <div class="col-md-2">
                    <label >Primer Nombre</label>
                    <input v-model="pax.FirstName" class="form-control" type="text" id="FirstName" :disabled="!canChangeName">
                </div>
                <div class="col-md-2">
                    <label >Segundo Nombre</label>
                    <input v-model="pax.MiddleName" class="form-control" type="text" id="MiddleName" :disabled="!canChangeName">
                </div>
                <div class="col-md-2">
                    <label >Documento</label>
                    <input v-model="pax.DocNumber" class="form-control" type="text" id="DocNumber">
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="DocTypeCode" :fieldOptions="fieldOptions" label="Tipo de Documento"
                    :current_value.sync="pax.DocTypeCode" :required="true" id="DocTypeCode">
                    </selectinput>
                </div>
                <div class="col-md-2">
                    <label for="FromDate">Fecha de Nacimiento</label>
                    <datepicker id="DOB" :def.sync="pax.DOB" ></datepicker>
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="PaxType" :fieldOptions="fieldOptions" label="Tipo de Pasajero"
                    :current_value.sync="pax.PaxType" :required="true" id="PaxType">
                    </selectinput>
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="Gender" :fieldOptions="fieldOptions" label="Género"
                    :current_value.sync="pax.Gender" :required="true" id="Gender">
                    </selectinput>
                </div>
                <div class="col-md-4">
                    <label>Comentario</label>
                    <input v-model="pax.Comment" class="form-control" type="text" id="Comment">
                </div>
            </div>
            <div class="spacer"></div>
            <hr></hr>
            <div class="spacer"></div>
            <div class="form-row">
                <div class="col-md-2">
                    <input type="checkbox" id="Infant" v-model="pax.Infant" @change="setInfant()">
                    <label for="Infant" class="form-check-label">Infante</label>
                </div>
            </div>
            <div class="form-row" v-if="hasInf">
                <div class="col-md-2">
                    <label>Apellido</label>
                    <input v-model="pax.Infants[0].LastName" class="form-control" type="text" id="InfantLastName">
                </div>
                <div class="col-md-2">
                    <label>Primer Nombre</label>
                    <input v-model="pax.Infants[0].FirstName" class="form-control" type="text" id="InfantFirstName">
                </div>
                <div class="col-md-2">
                    <label>Segundo Nombre</label>
                    <input v-model="pax.Infants[0].MiddleName" class="form-control" type="text" id="InfantMiddleName">
                </div>
                <div class="col-md-2">
                    <label >Documento</label>
                    <input v-model="pax.Infants[0].DocNumber" class="form-control" type="text" id="InfantDocNumber">
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="DocTypeCode" :fieldOptions="fieldOptions" label="Tipo de Documento"
                    :current_value.sync="pax.Infants[0].DocTypeCode" :required="true" id="InfantDocTypeCode">
                    </selectinput>
                </div>
                <div class="col-md-2">
                    <label>Fecha de Nacimiento</label>
                    <datepicker id="InfantDOB" :def.sync="pax.Infants[0].DOB"></datepicker>
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="Gender" :fieldOptions="fieldOptions" label="Género"
                    :current_value.sync="pax.Infants[0].Gender" :required="true" id="InfantGender">
                    </selectinput>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
import selectinput from '@/components/tools/SelectInput'
import datepicker from '@/components/tools/DatePicker'
var moment = require('moment');
export default {
  name: 'editpassenger',
  props: ['currentpax', 'paxkey'],
  components: {
    selectinput,
    datepicker
  },
  data  () {
    return {
        pax: {
            FirstName: this.currentpax.FirstName,
            MiddleName: this.currentpax.MiddleName,
            LastName: this.currentpax.LastName,
            DOB: this.currentpax.DOB,
            Gender: this.currentpax.Gender,
            DocNumber: this.currentpax.DocNumber,
            DocTypeCode: this.currentpax.DocTypeCode,
            PaxType: this.currentpax.PaxType,
            Infant: this.currentpax.Infant,
            Infants: this.currentpax.Infants,
            Comment: this.currentpax.Comment,
        },
        fieldOptions: {
            DocTypeCode: [
                {label: "DNI", value: "DNI"},
                {label: "Pasaporte", value: "P"},
            ],
            PaxType: [
                {label: "ADT", value: "ADT"},
                {label: "CHD", value: "CHD"},
            ],
            Gender: [
                {label: "Masculino", value: "MALE"},
                {label: "Femenino", value: "FEMALE"},
            ]

        }
      }
  },
  mounted: async function() {
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    hasInf () {
        return this.pax.Infants.length>0;
    },
    notCHD () {
        if (this.pax.PaxType!='ADT') return true;
        //if (!this.pax.DOB) return true;
        //let years = moment().diff(this.pax.DOB, 'years', false)
        //if (years<18) return true;

    },
    canChangeName () {
        if (!this.currentpax.PassengerID) return true;
        if (app.canAccess(this.user, 'api','POST', '/booking/change_name/', false)) return true;
    }

  },
  watch: {
  },
  methods: {
    save  (){
        let required = false
        if (this.setInvalidClass('FirstName', 'FirstName')) required = true;
        if (this.setInvalidClass('LastName', 'LastName')) required = true;
        if (this.setInvalidClass('DocNumber', 'DocNumber')) required = true;
        if (this.setInvalidClass('DocTypeCode', 'DocTypeCode')) required = true;
        if (this.setInvalidClass('date-picker-inputDOB', 'DOB')) required = true;
        if (this.setInvalidClass('PaxType', 'PaxType')) required = true;
        //if (this.setInvalidClass('Gender', 'Gender')) required = true;
        if (this.pax.Infant) {
            if (this.infnatSetInvalidClass('InfantFirstName', 'FirstName')) required = true;
            if (this.infnatSetInvalidClass('InfantLastName', 'LastName')) required = true;
            if (this.infnatSetInvalidClass('date-picker-inputInfantDOB', 'DOB')) required = true;
            //if (this.infnatSetInvalidClass('InfantGender', 'Gender')) required = true;
            if (this.infnatSetInvalidClass('InfantDocNumber', 'DocNumber')) required = true;
            if (this.infnatSetInvalidClass('InfantDocTypeCode', 'DocTypeCode')) required = true;
        }
        if (required) return;
        if (moment().diff(this.pax.DOB, 'years', false)<0) {
            alert('Edad Incorrecta');
            return;
        }
        if (this.pax.Infant) {
            if (moment().diff(this.pax.DOB, 'years', false)<18) {
                alert('Solo se permite INF para mayores de 18 años');
                return;
            }
            if (this.pax.PaxType!='ADT') {
                alert('Solo se permite INF para pasajeros ADT');
                return;
            }
        }
        if (moment().diff(this.pax.DOB, 'years', false)<12 && this.pax.PaxType=='ADT') {
            alert('Pasajeros ADT deben ser mayores de 12 años');
            return;
        }
        if (moment().diff(this.pax.DOB, 'years', false)>12 && this.pax.PaxType=='CHD') {
            alert('Pasajeros CHD deben ser menores de 12 años');
            return;
        }
        if (this.pax.Infant && this.pax.Infants[0].DOB && moment().diff(this.pax.Infants[0].DOB, 'years', false)>12) {
            alert('Los Infantes  deben ser menores de 2 años');
            return;
        }
        if (moment().diff(this.pax.DOB, 'years', false)>110 ) {
            alert('Fecha de Nacimiento incorrecta');
            return;
        }
        this.$emit('updatepax', this.pax, this.paxkey);
        this.$emit('close');
    },
    setInvalidClass (id, fieldname) {
        let div = $("#" + id)[0];
        div.classList.remove('is-invalid')
        if (this.pax[fieldname]==null || this.pax[fieldname].length==0){
            div.classList.add('is-invalid')
            return true;
        }
        return false;
    },
    infnatSetInvalidClass (id, fieldname) {
        let div = $("#" + id)[0];
        div.classList.remove('is-invalid')
        if (this.pax.Infants[0][fieldname]==null || this.pax.Infants[0][fieldname].length==0){
            div.classList.add('is-invalid')
            return true;
        }
        return false;
    },

    setInfant () {
        if (!this.hasInf && this.pax.Infant) {
            let inf = {
                PassengerID: this.currentpax.PassengerID,
                DOB: null,
                FirstName: null,
                MiddleName: null,
                LastName: null,
                Gender: null,
                Nationality: null,
                PaxType: 'INF'
            }
            this.pax.Infants.push(inf);
            this.pax = Object.assign({}, this.pax);
        }
        if (!this.pax.Infant) {
            this.pax.Infants = [];
        }
    }

  },
}
</script>

