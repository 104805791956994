<template>
    <div>

      <report-window endpoint="/reports/sales_by_location" :fields="fields" :title="title" :headers="headers"
      :templates="templates" :columnsClasses="columnsClasses"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'salesbylocation',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
         ],
        title: 'Ventas por Ubicación',
        headers: {Amount: 'Monto', LocationCode: 'Ubicación'},
        templates: [
            {name: 'Amount', callback (row) {return app.numberFormat(parseFloat(row.Amount));}},
        ],
        columnsClasses: {Amount: 'text-right'},

    }
  },
}
</script>

