<template>
    <home endpoint="classofservice" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'classofservice',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
          {name: 'id', label: 'Código', required: true},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          {name: 'FareTypeId', label: 'Tipo de Tarifa', editor: 'select', required: true,
              relation: 'faretype', optionLabels: 'Name'},
          {name: 'ProductClassId', label: 'Tipo de Producto', editor: 'select', required: true,
              relation: 'productclass', optionLabels: 'Name'},
          {name: 'TripType', label: 'Tipo de Viaje', editor: 'select', required: true, defValue: 0,
              options: [
                    {value: 0, label: 'One Way'},
                    {value: 1, label: 'Round Trip'},
                    ]},
          {name: 'NonTaxes', label: 'No Aplica Impuestos', editor: 'checkbox'},
          ],
        title: 'Clases de Servicio',
        title2: 'Clase de Servicio'
      }
    }
  },
}
</script>

