<template>
    <div>
      <report-window endpoint="/reports/baglist" :fields="fields" :title="title" :headers="headers" :templates="templates"></report-window>
    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'baglist',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FlightNr', editor: 'text', label: 'Vuelo', columns: 1, required: true},
        {name: 'FlightDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
            ],
        title: 'Listado de Equipajes',
        headers: {
            BaggageStatus: 'Estado'
        },
        templates: [
            {name: 'BaggageStatus', callback (row) {
                return {
                    0: '',
                    1: '',
                    2: 'Removido',
                    3: '',
                    4: ''
                }[row.BaggageStatus]
            }}

        ]
    }
  },
}
</script>

