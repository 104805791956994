<template>
    <home endpoint="organization" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'organization',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
          {name: 'id', label: 'Código', required: true},
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'PaymentMethodCode', relation: 'paymentmethod', optionLabels: 'Name'
              , editor: 'select', label: 'Forma de Pago', id: 'PaymentMethodCode'},
          {name: 'PaymentAccountType', label: 'Tipo de Cuenta Corriente', editor: 'select', defValue: '0',
              options: [
                {value: '0', label: 'Cuenta Corriente'},
                {value: '1', label: 'Límite de Credito'},
              ]},
          {name: 'CreditLimit', label: 'Límite de Crédito', editor: 'number'},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          ],
        title: 'Organización',
        title2: 'Organización'
      }
    }
  },
}
</script>

