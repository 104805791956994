<template>
    <home endpoint="fare" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
var moment = require('moment');
export default {
  name: 'fare',
  components: {
    home,
  },
  data  () {
    let self = this;
    return {
      params: {
        fields: [
          {name: 'id', hidden: 'true'},
          {name: 'DepartureStation', label: 'Desde', editor: 'select', required: true,
              relation: 'station', optionLabels: 'id'},
          {name: 'ArrivalStation', label: 'Hacia', editor: 'select', required: true,
              relation: 'station', optionLabels: 'id'},
          {name: 'StartDate', label: 'Inicio', required: true, editor: 'date'},
          {name: 'EndDate', label: 'Fin', editor: 'date'},
          {name: 'ClassOfServiceId', label: 'Clase', editor: 'select', required: true,
              relation: 'classofservice', optionLabels: 'id'},
          {name: 'Amount', label: 'Monto', editor: 'number'},
          {name: 'CurrencyId', label: 'Moneda', editor: 'select', required: true,
              relation: 'currency', optionLabels: 'id'},
          {name: 'DirectionType', label: 'Direccion', editor: 'select', required: true, defValue: 0,
              options: [
                    {value: 0, label: 'Desde - Hacia'},
                    {value: 1, label: 'Entre'},
                    ]
               },
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'}
          ],
        title: 'Tarifa',
        title2: 'Tarifa',
      }
    }
  },
}
</script>

