<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="342.000000pt" height="340.000000pt" viewBox="0 0 342.000000 340.000000"  preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,340.000000) scale(0.100000,-0.100000)" fill="#ffffff">
      <path d="M463 3321 c-59 -15 -97 -45 -123 -96 l-25 -50 -3 -360 c-4 -389 4 -501 42 -613 19 -55 216 -521 286 -677 10 -22 57 -131 104 -242 47 -111 101 -228 120 -260 79 -134 207 -228 376 -275 19 -6 121 -13 225 -17 l190 -6 3 -147 3 -148 -304 0 c-193 0 -316 -4 -338 -11 -44 -14 -96 -71 -101 -111 -13 -84 43 -161 124 -173 24 -3 387 -5 808 -3 732 3 766 4 793 22 39 27 61 73 61 131 0 57 -19 91 -69 122 -27 16 -63 19 -350 23 l-320 5 0 145 0 145 325 5 c366 6 382 9 464 82 25 22 113 129 195 238 127 168 151 205 156 244 11 68 -2 112 -42 157 -72 79 5 74 -1049 77 l-942 3 -27 62 c-15 34 -33 79 -41 100 l-13 37 683 0 683 0 44 23 c24 13 57 42 74 66 25 36 29 50 29 109 0 104 -45 165 -139 191 -27 7 -280 11 -793 11 l-753 0 -41 88 c-22 48 -45 101 -51 117 -7 18 -13 192 -16 447 l-6 416 -30 43 c-46 65 -134 99 -212 80z"/>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'seat',
}
</script>
