<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-10">
                <div class="card flight-card" v-for="(c, i) in booking.Comments">
                    <input type="checkbox" class="form-check-input" v-bind:checked="selected==i"
                        @change="setSelected(i)">
                    <a>
                        <div class="card-header card-row">
                            <div class="form-row">
                                Usuario: {{c.CreatedUserCode}}
                            </div>
                            <div class="form-row">
                                {{c.CommentText}}
                            </div>
                            <div class="form-row">
                                Fecha: {{getDateTime(c.CreatedUTC)}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-row">
                    <!--button type="button" class="btn btn-primary" @click="addComment" data-toggle="modal"
                    data-target="#newcomment">Agregar</button-->
                    <button type="button" class="btn btn-primary button-row" @click="addComment">Agregar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" :disabled="selected==null" @click="deleteComments">Eliminar</button>
                </div>

            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <newcomment v-if="newcomment" @close="close"></newcomment>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import newcomment from '@/components/booking/NewComment'
var moment = require('moment');
export default {
  name: 'bookingcomments',
  components: {
    newcomment
  },
  data  () {
    return {
        newcomment: false,
        selected: null
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    addComment () {
        this.newcomment = true;
        this.$nextTick(function () {
          //$('#newpayment').modal('show')
        })
    },
    close () {
        this.newcomment = false;
        //this.$emit("update:booking", Object.assign({}, this.booking));
        //$('#newpayment').modal('hide');

    },
    getDateTime (d) {
        return app.getLocalDateTime(d);
    },
    setSelected (i){
        if (this.selected==i) {
            this.selected = null;
        } else {
            this.selected = i;
        }
    },
    deleteComments () {
        if (this.selected!=null) {
            this.booking.Comments.splice(this.selected, 1);
            this.selected = null;
        }

    }

  },
}
</script>

