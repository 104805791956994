<template>
  <!--div class="modal fade bd-example-modal-lg" id="newfee" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"-->
      <div class="modal-content">
        <div class="modal-body" v-if="fieldOptions && !processing">
            <div class="form-row">
                <div class="col-md-2">
                    <selectinput fieldname="FeeCode" :fieldOptions="fieldOptions" label="Cargo"
                    :current_value.sync="feeCode"></selectinput>
                </div>
                <div class="col-md-2">
                    <selectinput fieldname="CurrencyCode" :fieldOptions="fieldOptions" label="Moneda"
                    :current_value.sync="currency"></selectinput>
                </div>
                <div class="col-md-2">
                    <label>Monto</label>
                    <input v-model="amount" class="form-control" type="number" :disabled="!amountEditable">
                </div>
                <div class="col-md-2" v-if="feeClass==1">
                    <label>%</label>
                    <input v-model="percentage" class="form-control" type="number">
                </div>
            </div>
            <div class="form-row" v-if="feeClass==0 || feeClass==1">
                <div class="col-md-3">
                    <selectinput fieldname="PassengerID" :fieldOptions="fieldOptions" label="Pasajero"
                    :current_value.sync="paxID"></selectinput>
                </div>
            </div>
            <br>
            <div class="form-row" v-if="feeClass==1 && paxID && segments.length>0">
                <div class="col-md-6">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr class="table-secondary">
                                <th></th>
                                <th>Origen</th>
                                <th>Destino</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(segment, idx) of segments">
                                <td><input type="checkbox" v-model="segment.selected"></td>
                                <td>{{segment.departureStation}}</td>
                                <td>{{segment.arrivalStation}}</td>
                                <td>{{segment.departureDate}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save" :disabled="!completed">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    <!--/div>
  </div-->
</template>

<script>
import { mapState } from 'vuex';
import selectinput from '@/components/tools/SelectInput'
var moment = require('moment');
export default {
  name: 'newfee',
  components: {
    selectinput
  },
  data  () {
    return {
        amount: null,
        percentage: null,
        currency: 'ARS',
        feeCode: null,
        feeCodes: [],
        paxID: null,
        segmentID: null,
        passengers: [],
        processing: false,
        fees: [],
        segments: [],
        currencies: [
            {label: 'ARS', value: 'ARS'}
        ],
    }
  },
  mounted: async function() {
    this.processing = true;
    let res = await app.get('/api/fee/');
    for (let i in this.booking.Passengers) {
        let pax = this.booking.Passengers[i];
        this.passengers.push({value: i, label: pax.LastName + ', ' + pax.FirstName})
    }
    if (res) {
        for (let r of res) {
            if (r.InActive) continue;
            this.fees.push({value: r.FeeCode, label: r.Name})
        }
        this.fees.sort(this.compare)
        this.feeCodes = res;
        this.processing = false;
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    completed () {
        if ((this.feeClass==1 || this.feeClass==0) && !this.paxID) return false;
        if (this.feeClass==1 && !this.segmentSelected) return false;
        if (this.amount && this.feeCode) return true;
    },
    fee () {
        return _.find(this.feeCodes, (p)=>p.FeeCode==this.feeCode);
    },
    segmentSelected () {
        let res = false;
        for (let s of this.segments) {
            if (s.selected) return true;
        }
        return res;
    },
    feeClass () {
        let res = 2;
        if (this.fee) return this.fee.FeeClass;
        return res;
    },
    fieldOptions  () {
        return {FeeCode: this.fees,  CurrencyCode: this.currencies, PassengerID: this.passengers, SegmentID: this.segments};
    },
    amountEditable () {
        if (this.fee && this.fee.BasePrice) {
            return false;
        }
        return true;
    }
  },
  methods: {
    getNewFee (fee) {
        return {
            FeeCode: this.feeCode,
            FeeType: fee.FeeType,
            FeeOverride: false,
            isNew: true,
            Status: 'HK',
            Charges: [{
                ChargeNumber: 1,
                ChargeCode: this.feeCode,
                TicketCode: this.feeCode,
                ChargeType: 6,
                CurrencyCode: this.currency,
                ForeignCurrencyCode: this.currency,
                ChargeAmount: this.amount,
                ForeignAmount: this.amount,
                ChargeDetail: fee.Name,
            }]
        }

    },
    save: async function() {
        if (this.feeClass==2) {
            let f = this.getNewFee(this.fee);
            f.FeeNumber = app.getNextFeeNumber(this.booking.Fees);
            f.Charges[0].FeeNumber = f.FeeNumber;
            this.booking.Fees.push(f);
        }
        if (this.feeClass==0) {
            let f = this.getNewFee(this.fee);
            f.FeeNumber = app.getNextFeeNumber(this.booking.Passengers[this.paxID].Fees);
            f.Charges[0].FeeNumber = f.FeeNumber;
            this.booking.Passengers[this.paxID].Fees.push(f);
        }
        if (this.feeClass==1) {
            for (let j in this.segments) {
                let segment = this.segments[j];
                if (segment.selected) {
                    let res = await this.checkFeeLidBySegment(this.booking.Passengers[this.paxID].Segments[j]);
                    if (res) {
                        alert('Inventario no disponible');
                        return;
                    }
                    let f = this.getNewFee(this.fee);
                    f.FeeNumber = app.getNextFeeNumber(this.booking.Passengers[this.paxID].Segments[j].Fees);
                    f.Charges[0].FeeNumber = f.FeeNumber;
                    this.booking.Passengers[this.paxID].Segments[j].Fees.push(f);
                }
            }

        }
        app.bookingSumUp(this.booking);
        this.$emit('close');
    },
    checkFeeLidBySegment: async function(segment) {
        if (!this.fee.Lid) return false;
        for (let leg of segment.Legs) {
            let res = await app.get('/api/booking/max_lid_by_fee', {FeeCode: this.feeCode, InventoryLegID: leg.InventoryLegID} )
            if (res) return true;
        }
    },
    setAmount  (){
        if (!this.percentage) return;
        let base = 0;
        for (let j in this.segments) {
            let segment = this.segments[j];
            if (segment.selected) {
                for (let charge of this.booking.Passengers[this.paxID].Segments[j].Charges) {
                    if (!charge.ChargeCode) base += parseFloat(charge.ChargeAmount);
                    if (charge.ChargeCode=='DL1') base += parseFloat(charge.ChargeAmount);
                    if (charge.ChargeCode=='DL') base += parseFloat(charge.ChargeAmount);
                    if (charge.ChargeCode=='QN') base += parseFloat(charge.ChargeAmount);
                }
            }
        }
        this.amount = (base * this.percentage / 100).toFixed(2);
    },
    compare (a,b) {
      if (a.label < b.label)
        return -1;
      if (a.label > b.label)
        return 1;
      return 0;
    },
  },
  watch: {
    paxID  () {
        let res = [];
        if (this.paxID) {
            let pax = this.booking.Passengers[this.paxID];
            if (pax) {
                for (let s of pax.Segments) {
                    res.push({segmentID: s.SegmentID, selected: false,
                        label: s.DepartureStation + '-' + s.ArrivalStation + ' ' + moment(s.DepartureDate).format('DD/MM/YYYY'),
                        departureDate: moment(s.DepartureDate).format('DD/MM/YYYY'),
                        departureStation: s.DepartureStation, arrivalStation: s.ArrivalStation});
                }
            }
        }
        this.segments = res;
    },
    percentage () {
        this.setAmount();
    },
    segments: {
            handler () {
                        this.setAmount();
                    },
                    deep: true,
    },
    fee () {
        if (this.fee && this.fee.BasePrice) {
            this.amount = this.fee.BasePrice;
        }
    }
  }
}
</script>

