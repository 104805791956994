<template>
  <div>

    <div class="container">
      <div class="form-row">
        <div class="form-group col-md-2 form-row">
          <div class="col-md-10">
            <label for="originFlightNumber">Número de Vuelo (origen)</label>
            <input v-model="originFlightNumber" name="FlightNumber" field-editor="originFlightNumber"
                   class="form-control" type="text" placeholder="" id="originFlightNumber">

            <label for="FromDate">Fecha (vuelo origen)</label>
            <datepicker id="FromDate" :def.sync="FromDate" @changedate="checkDate('FromDate')"></datepicker>
            <div class="invalid-feedback">
              Campo requerido
            </div>

            <selectinput fieldname="DepartureStation" :fieldOptions="stationOptions" label="Origen"
                         :current_value.sync="originDepartureStation" id="OriginDepartureStation" addClass="station-select">
            </selectinput>

            <selectinput fieldname="ArrivalStation" :fieldOptions="stationOptions" label="Destino"
                         :current_value.sync="originArrivalStation" id="OriginArrivalStation" class="station-select">
            </selectinput>

            <label for="destinationFlightNumber">Número de Vuelo (destino)</label>
            <input v-model="destinationFlightNumber" name="FlightNumber" field-editor="destinationFlightNumber"
                   class="form-control" type="text" placeholder="" id="destinationFlightNumber">

            <label for="ToDate">Fecha (vuelo destino)</label>
            <datepicker id="ToDate" :def.sync="ToDate" @changedate="checkDate('ToDate')"></datepicker>
            <div class="invalid-feedback">
              Campo requerido
            </div>


            <selectinput fieldname="DepartureStation" :fieldOptions="stationOptions" label="Origen"
                         :current_value.sync="destinationDepartureStation" id="DestinationDepartureStation" addClass="station-select">
            </selectinput>

            <selectinput fieldname="ArrivalStation" :fieldOptions="stationOptions" label="Destino"
                         :current_value.sync="destinationArrivalStation" id="DestinationArrivalStation" class="station-select">
            </selectinput>


            <div class="spacer"></div>
            <button type="button" class="btn btn-primary" @click="searchFlights">Buscar</button>
          </div>
        </div>
        <div class="form-group col-md-6">
          <div v-if="errorMessage">
            {{errorMessage}}
          </div>
          <div v-if="processing">
            <img class="loading-gif" src="@/img/loading.gif">
          </div>

          <div class="row" v-else>
            <div class="col">
              <h1 v-if="updatedBookings.length">Se movieron {{updatedBookings.length}} pasajeros exitosamente.</h1>
              <div v-else>
                <div v-if="this.originFlight">Origen - Estado: {{this.originFlight.Status}} - pasajeros {{paxsToMove}}
                </div>
                <div v-if="this.destinationFlight">Destino - Estado: {{this.destinationFlight.Status}} - Asientos
                  disponibles {{this.destinationFlight.availability}}
                </div>
                <div v-if="this.bookings.length">Seleccionados: {{paxSelected}}</div>
                <p v-if="this.bookings.length" @click="unselectAll" class="unselect">Seleccionar/Deseleccionar todos</p>

                <div class="row align-items-baseline" v-for="b in bookings" v-if="bookings.length">
                  <div class="col-1">
                    <input type="checkbox" v-model="b.checked"/>
                  </div>
                  <div class="col">
                    <p class="mb-0">
                      {{b.bookingId}} {{b.recordLocator}} - pasajeros {{b.paxs}}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col offset-6"></div>
                  <button type="button" class="btn btn-primary" @click="movePassengers" v-if="this.bookings.length">
                    Mover Pasajeros
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import '@/css/app.css';

  import {mapState} from 'vuex';
  import datepicker from '@/components/tools/DatePicker'
  import recordwindow from '@/components/tools/RecordWindow';
  import selectinput from '@/components/tools/SelectInput'
  import Vue from 'vue/dist/vue.esm'
  import {ClientTable, Event} from 'vue-tables-2'

  let theme;
  Vue.use(ClientTable, [theme = 'bootstrap5']);

  var moment = require('moment');
  export default {
    name: 'move-passengers',
    components: {

      datepicker,
      selectinput
    },
    data  () {
      return {
        originFlightNumber: null,
        destinationFlightNumber: null,
        FromDate: moment(new Date()).format("YYYY-MM-DD"),
        ToDate: moment(new Date()).format("YYYY-MM-DD"),
        result: "",
        processing: false,
        originFlight: null,
        destinationFlight: null,
        originDepartureStation: null,
        originArrivalStation: null,
        destinationDepartureStation: null,
        destinationArrivalStation: null,
        bookings: [],
        error: null,
        updatedBookings: [],
        errorMessage: "",
        selectStatus: true
      }
    },
    computed: {
    ...mapState({
        user: state => state.user,
        stations: state => state.stations,
    }),
      stationOptions  () {
        let stationList = [];
        for (let station of this.stations) {
          stationList.push({label: station.id, value: station.id})
        }
        return {DepartureStation: stationList, ArrivalStation: stationList};
      },
      originInventoryLegIds  () {
        if (!this.originFlight) {
          return [];
        }
        return this.originFlight.InventoryLegs.map(l => l.InventoryLegID);
      },
      paxsToMove  () {
        let total = 0;
        // for (var id of this.originInventoryLegIds) {
          for (var b of this.bookings) {
            total += b.paxs;
          // }
        }

        return total;
      },
      paxSelected  () {

        let total = 0;
        // for (var id of this.originInventoryLegIds) {
          for (var b of this.bookings) {
            if (b.checked) {
              total += b.paxs;
            }
          // }
        }

        return total;

      }
    },
    methods: {
      checkDate  (fieldname) {
        if (moment(this[fieldname]) < moment()) {
          alert('Fecha Incorrecta');
          let self = this;
          this.$nextTick(function () {
            self[fieldname] = moment(new Date()).format("YYYY-MM-DD");
          })
        }
      },
      searchFlights: async function () {
        this.updatedBookings = []
        let hasErrors = false;
        if (_.isEmpty(this.originFlightNumber)) {
          $("#originFlightNumber")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#originFlightNumber")[0].classList.remove('is-invalid');
        }

        if (_.isEmpty(this.destinationFlightNumber)) {
          $("#destinationFlightNumber")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#destinationFlightNumber")[0].classList.remove('is-invalid');
        }

        if (_.isEmpty(this.originDepartureStation)) {
          $("#OriginDepartureStation")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#OriginDepartureStation")[0].classList.remove('is-invalid');
        }
        if (_.isEmpty(this.originArrivalStation)) {
          $("#OriginArrivalStation")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#OriginArrivalStation")[0].classList.remove('is-invalid');
        }

        if (_.isEmpty(this.destinationDepartureStation)) {
          $("#DestinationDepartureStation")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#DestinationDepartureStation")[0].classList.remove('is-invalid');
        }
        if (_.isEmpty(this.destinationArrivalStation)) {
          $("#DestinationArrivalStation")[0].classList.add('is-invalid');
          hasErrors = true;
        } else {
          $("#DestinationArrivalStation")[0].classList.remove('is-invalid');
        }

        if (hasErrors) {
          return
        }

        // al menos origen o destino deben coincidir
        if (this.originDepartureStation != this.destinationDepartureStation &&
            this.originArrivalStation != this.destinationArrivalStation) {
          $("#OriginDepartureStation")[0].classList.add('is-invalid');
          $("#OriginArrivalStation")[0].classList.add('is-invalid');
          $("#DestinationDepartureStation")[0].classList.add('is-invalid');
          $("#DestinationArrivalStation")[0].classList.add('is-invalid');
          this.errorMessage = "Deben coincidir al menos o las estaciones de salida o las de llegada.";
          return;
        }

        this.errorMessage = "";


        this.processing = true;
        // origin flight -----------------------------------------------------------------------------------
        var data = {
          flightNumber: this.originFlightNumber,
          date: this.FromDate,
          fromStation: this.originDepartureStation,
          toStation: this.originArrivalStation
        }

        var res = await app.get('/api/flight/get_flight', data);
        if (res) {
          this.originFlight = res;
        }

        // target flight -----------------------------------------------------------------------------------
        data = {
          flightNumber: this.destinationFlightNumber,
          date: this.ToDate,
          fromStation: this.destinationDepartureStation,
          toStation: this.destinationArrivalStation,
        }

        res = await app.get('/api/flight/get_flight', data);
        if (res) {
          this.destinationFlight = res;
        }

        // bookings flight ---------------------------------------------------------------------------------
        data = {
          flightId: this.originFlight.id,
          fromStation: this.originDepartureStation,
          toStation: this.originArrivalStation,
        };

        var res = await app.get('/api/booking/bookings_by_flight', data);
        if (res) {
          for (var b of res) {
            b.checked = false;
          }
          this.bookings = res;
        }

        this.processing = false;
      },
      movePassengers: async function () {

        var bookingIds = []
        for (var b of this.bookings) {
          if (b.checked) {
            bookingIds.push(b.bookingId);
          }
        }

        var data = {
          fromFlightId: this.originFlight.id,
          toFlightId: this.destinationFlight.id,
          bookingIds: bookingIds,
          fromStation: this.originDepartureStation,
          toStation: this.originArrivalStation
        }

        this.processing = true;
        var res = await app.postJSON('/api/booking/move_passengers_by_flight', data)
        if (res) {
          this.updatedBookings = res.updated_bookings;
        }
        this.processing = false;
      },
      unselectAll () {
        this.selectStatus = !this.selectStatus;
        for (var b of this.bookings) {
          b.checked = this.selectStatus;
        }
      }
      // getAvailabilityText  (f, il) {
      //
      //   return il.DepartureStation + " - " + il.ArrivalStation + " - asientos disponibles: " + f.availability[il.InventoryLegID];
      // }
    },
  }
</script>

