<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-10">
                <div class="card">
                    <div class="card-header table-responsive-md">
                        <table class="table table-striped table-sm table-bordered">
                            <thead>
                                <tr class="table-secondary">
                                    <th></th>
                                    <th>Número</th>
                                    <th>Método</th>
                                    <th>Detalles</th>
                                    <th>Aut.</th>
                                    <th>Estado</th>
                                    <th>Monto</th>
                                    <!--th>Recibido</th-->
                                    <th>Cuotas</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(p, idx) of booking.Payments">
                                    <td><input type="checkbox" @change="setSelected(idx)" v-bind:checked="selected==idx"></td>
                                    <td>{{p.PaymentID}}</td>
                                    <td>{{p.PaymentMethod.Name}}</td>
                                    <td>{{getDetail(p)}}</td>
                                    <td>{{p.AuthorizationCode}}</td>
                                    <td>{{getStatusName(p.AuthorizationStatus)}}</td>
                                    <td :class="getAmountClass(p.PaymentAmount)">{{p.CurrencyCode}} {{parseFloat(p.PaymentAmount).toFixed(2)}}</td>
                                    <!--td :class="getAmountClass(p.CollectedPaymentAmount)">{{p.CollectedCurrencyCode}} {{parseFloat(p.CollectedPaymentAmount).toFixed(2)}}</td-->
                                    <td>{{p.Installments}}</td>
                                    <td>{{getDate(p.CreatedUTC)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-md-2">
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" :disabled="selected==null || (hasPaymentId && !canDeletePayment)" @click="deletePayment">Eliminar</button>
                </div>
                <div class="form-row">
                    <!--button type="button" class="btn btn-primary" @click="addPayments" data-toggle="modal"
                    data-target="#newpayment">Agregar</button-->
                    <button type="button" class="btn btn-primary button-row" :disabled="booking.BalanceDue<=0" @click="addPayments">Agregar</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="addRefund"
                        :disabled="selected==null || !hasPaymentId || isRefund  || !canRefundPayment || !refundAmount">Devolución</button>
                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-primary button-row" @click="addHold"
                    :disabled="booking.Status==2 || !canSetHold">Hold</button>
                </div>
                <div class="form-row" v-if="canSetStatus">
                    <button type="button" class="btn btn-primary button-row" @click="setStatus"
                    :disabled="selected==null || !hasPaymentId || !canSetStatus">Cambiar Estado</button>
                </div>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <newpayment v-if="newpayment" :refund="refund" @close="close"></newpayment>
        <holdtime v-if="hold" @close="closeHold"></holdtime>
        <paymenttickets v-if="paymentToPrint" :payment="paymentToPrint" @close="closePrintTickets"></paymenttickets>
        <selectvalue v-if="selectStatus" @save="setNewStatus" @close="closeSetStatus"
          text="Elegir Estado" :fieldOptions="statusList"></selectvalue>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
import newpayment from '@/components/booking/NewPayment';
import holdtime from '@/components/booking/HoldTime';
import paymenttickets from '@/components/booking/PaymentTickets';
import selectvalue from '@/components/tools/SelectValue';
export default {
  name: 'bookingpayments',
  components: {
    newpayment,
    holdtime,
    paymenttickets,
    selectvalue
  },
  data  () {
    return {
        selected: null,
        newpayment: false,
        refund: null,
        hold: null,
        paymentToPrint: false,
        canRefundPayment: false,
        canSetHold: false,
        canDeletePayment: false,
        canSetStatus: false,
        selectStatus: false,
        statusList: [
            {value: 2, label: 'Pendiente'},
            {value: 4, label: 'Aprobado'},
            {value: 5, label: 'Declinado'}
        ]
    }
  },
  mounted () {
    this.canRefundPayment = app.canAccess(this.user, 'api','POST', '/booking/refund_payment/', false);
    this.canDeletePayment = app.canAccess(this.user, 'api','POST', '/booking/delete_payment/', false);
    this.canSetHold = app.canAccess(this.user, 'api','POST', '/booking/set_hold/', false);
    this.canSetStatus = app.canAccess(this.user, 'api','POST', '/booking/set_payment_status/', false);
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    hasPaymentId () {
        if (this.selected!=null && this.booking.Payments[this.selected] && this.booking.Payments[this.selected].PaymentID) return true;
    },
    isRefund () {
        if (this.selected!=null && this.booking.Payments[this.selected] && this.booking.Payments[this.selected].PaymentAmount<0) return true;
    },
    hasRefund () {
        let total = 0;
        for (let p of this.booking.Payments) {
            if (p.ParentPaymentID==this.booking.Payments[this.selected].PaymentID) {
                total += parseFloat(p.PaymentAmount);
            }
        }
        if (total>=parseFloat(this.booking.Payments[this.selected].PaymentAmount)) return true;
    },
    refundAmount () {
        if (this.selected==null) return 0;
        let total = parseFloat(this.booking.Payments[this.selected].PaymentAmount);
        for (let p of this.booking.Payments) {
            if (p.ParentPaymentID==this.booking.Payments[this.selected].PaymentID) {
                total += parseFloat(p.PaymentAmount);
            }
        }
        return total>0;
    },

  },
  methods: {
    getDate (d) {
        return moment(d).format("DD/MM/YYYY HH:MM");
    },
    deletePayment () {
        this.booking.Payments.splice(this.selected, 1);
        app.bookingSumUp(this.booking);
        this.selected = null;
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },
    setSelected (idx) {
        if (this.selected==idx) {
            this.selected = null;
        } else {
            this.selected = idx;
        }
    },
    addPayments () {
        this.newpayment = true;
        this.$nextTick(function () {
          //$('#newpayment').modal('show')
        })
    },
    addRefund () {
        if (!this.canRefundPayment) {
            alert('Sin permiso para esta acción');
            return;
        }
        this.newpayment = true;
        this.refund = this.selected;
        this.$nextTick(function () {
          //$('#newpayment').modal('show')
        })
    },
    close () {
        this.newpayment = false;
        this.refund = null;
        //this.$emit("update:booking", Object.assign({}, this.booking));
        //$('#newpayment').modal('hide');
    },
    getAmountClass (amount) {
        if (amount<0) return 'payment-refund';
        return "";
    },
    addHold () {
        if (!this.canSetHold) {
            alert('Sin permiso para esta acción');
            return;
        }
        this.hold = true;
        this.$nextTick(function () {
          $('#holdtime').modal('show');
        })
    },
    closeHold () {
        this.hold = false;
        $('#holdtime').modal('hide');
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },
    showPrintTickets () {
        this.paymentToPrint = this.booking.Payments[this.selected];
        this.$nextTick(function () {
          $('#payment-tickets').modal('show');
        })
    },
    closePrintTickets () {
        this.paymentToPrint = null;
        $('#payment-tickets').modal('hide');
    },
    getStatusName (s) {
        return app.getAuthorizationStatusNames()[s]
    },
    getDetail (p) {
        if (p.AuthorizationStatus==12) {
            if (p.MercadoPagoPayment && p.MercadoPagoPayment.Message) {
                return p.MercadoPagoPayment.Message;
            }
        }
        return p.AccountNumber;
    },
    setStatus () {
        this.selectStatus = true;
        this.$nextTick(function() {
          $('#selectValue').modal('show');
        })
    },
    closeSetStatus () {
        this.selectStatus = null;
        $('#selectValue').modal('hide');
    },
    setNewStatus (i) {
        this.booking.Payments[this.selected].AuthorizationStatus = i;
        this.booking.Payments[this.selected].Status = 3;
        app.bookingSumUp(this.booking);
        this.closeSetStatus();
    },


  }
}
</script>

