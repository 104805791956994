<template>
  <div>
      <div v-if="rowField.editor=='textarea'" >
          <label :for="rowField.name" v-if="rowNr==0">{{rowField.label}}</label>
          <textarea v-model="value" :name="rowField.name" class="form-control" :class="rowField.classes"  rows="3"
          :disabled="rowField.disabled || evalCondition(row ,rowField.disbledCondition)"
          @change="afterEdit(null, null, arrayField.name, rowField.name , rowNr)"
          :readonly="rowField.readonly" :id="arrayField.name + '-' + rowField.name + '-' + rowNr"></textarea>
      </div>
      <div v-else-if="rowField.editor=='select'" >
          <selectinput :fieldname="rowField.name" :fieldOptions="fieldOptions" :label="rowNr? '': rowField.label"
          @change="afterEdit(null, null, arrayField.name, rowField.name , rowNr)" :required="rowField.required"
          :current_value.sync="value" :disabled="rowField.disabled" :id="arrayField.name + '-' + rowField.name + '-' + rowNr">
          </selectinput>
      </div>
      <div v-else-if="rowField.editor=='date'" >
          <label :for="rowField.name" v-if="rowNr==0">{{rowField.label}}</label>
          <datepicker :id="arrayField.name + '-' + rowField.name + '-' + rowNr" :def.sync="value"
          @changedate="afterEdit(null, null, arrayField.name, rowField.name , rowNr)"></datepicker>
      </div>
      <div v-else-if="rowField.editor=='number'" >
          <label :for="rowField.name" v-if="rowNr==0">{{rowField.label}}</label>
          <input v-model="value" :name="rowField.name" 
          class="form-control" :class="rowField.classes"  type="number" :id="arrayField.name + '-' + rowField.name + '-' + rowNr"
          :disabled="rowField.disabled" :readonly="rowField.readonly" @change="afterEdit(null, null, arrayField.name, rowField.name , rowNr)">
      </div>
      <div v-else-if="rowField.editor!='select'" >
          <label :for="rowField.name" v-if="rowNr==0">{{rowField.label}}</label>
          <input v-model="value" :name="rowField.name" 
          class="form-control" :class="rowField.classes"  type="text" :id="arrayField.name + '-' + rowField.name + '-' + rowNr"
          :disabled="rowField.disabled" :readonly="rowField.readonly" @change="afterEdit(null, null, arrayField.name, rowField.name , rowNr)" >
      </div>
  </div>
</template>


<script>
import selectinput from '@/components/tools/SelectInput'
import datepicker from '@/components/tools/DatePicker'
import { mapState } from 'vuex';
export default {
    name: 'rowinputfield',
    props: [ 'rowField', 'arrayField', 'defValue', 'fieldOptions', 'row', 'rowNr'],
    components: {
      selectinput,
      datepicker,
    },
    data  () {
        return {
            value: null,
        }
    },
    mounted () {
        this.value = this.defValue;
    },
    watch: {
        defValue () {
            this.value = this.defValue;
        }
    },
    methods: {
        afterEdit (newValue, id, fieldname, rowfieldname, rownr) {
            let self = this;
            this.$nextTick(function() {
                self.$emit('update:defValue', this.value);
                this.$emit('afteredit', newValue, id, fieldname, rowfieldname, rownr);
            });
        }
    }
}
</script>
