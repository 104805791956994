<template>
    <div>
        <div class="container container-checkin">
            <div class="form-row">
                <div class="form-group col-md-7 form-row" >
                    <div class="padding-15 form-row" v-if="!processing">
                        <div class="col-md-8 commands" v-if="leg">
                            <h6 class="text-center">
                            {{leg.FlightNumber}} - {{formatDate(leg.DepartureDate)}} - {{getTime(leg.STD)}}
                            {{leg.DepartureStation}}/{{leg.ArrivalStation}} - {{leg.LegStatus}}
                            Puerta: {{leg.Gate}}
                            </h6>

                        </div>
                        <div class="col-8" v-for="station of stations" v-if="stations.length>1 && ready">
                            <legsummary :paxlist="list" :station="station"></legsummary>
                        </div>
                        <div class="col-8" v-if="ready">
                            <legsummary :paxlist="list"></legsummary>
                        </div>

                    </div>
                </div>
                <div class="col-md-1" v-if="!processing">
                    <button type="button" class="btn btn-primary" @click="update">Actualizar</button>
                </div>
                <div class="form-group col-md-2 padding-15" v-if="!processing">
                    <seatmap :seatMap="seatMap" :paxList="list"></seatmap>
                </div>
                <div v-if="processing">
                    <img class="loading-gif" src="@/img/loading.gif">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import '@/css/app.css';
;
import seatmap from '@/components/checkin/SeatMap';
import legsummary from '@/components/checkin/LegSummary'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'operflight',
  components: {

    seatmap,
    legsummary,
  },
  props: ['inventoryleg'],
  data  () {
    return {
        leg: null,
        processing: false,
        list: null,
        seatMap: null,
        help: false,
        seatMapRows: [],
        summary: null,
        LiftStatusValues: {
            0: 'NO CHEQUEADO',
            1: 'CHECKIN',
            2: 'EMBARCADO',
            3: 'NO SHOW',
            4: 'ENDOSADO'
        },
        stations: [],
        ready: false

    }
  },
  mounted: async function() {
    if (!this.inventoryleg) {
        this.$router.push('checkin');
    }
    this.getPassengers();
    this.getSeatMap();
    this.leg = this.inventoryleg;
    for (let i=1; i<36; i++) {
        this.seatMapRows.push(i);
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  methods: {
    update () {
        this.getPassengers();
        this.getSeatMap();
    },
    getSeatMap: async function() {
        let res = await app.get('/api/checkin/seat_map', {data: JSON.stringify([this.inventoryleg.InventoryLegID]), oper: true})
        if (res) {
            this.seatMap = res;
        }
    },
    getPassengers: async function() {
        this.processing = true;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
            DepartureStation: this.inventoryleg.DepartureStation,
        }
        let res = await app.get('/api/checkin/flight_list', data)
        if (res) {
            this.list = res.PassengerLegs;
            for (let p of this.list) {
                if (this.stations.indexOf(p.Segment.ArrivalStation)==-1) {
                    this.stations.push(p.Segment.ArrivalStation);
                }
            }
            this.ready = true;
            this.processing = false;
        }
    },
    formatDate (d) {
        return moment(d).locale('es').format('ddd DD MMM')
    },
    getTime (d) {
        return moment(d).format("HH:mm");
    },


  },
}
</script>

