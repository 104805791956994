<template>
    <div>

      <report-window endpoint="/reports/userhistory" :fields="fields" :title="title" :headers="headers" :templates="templates"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
var codes = require('@/historycodes.json');
export default {
  name: 'userhistory',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'UserName', editor: 'text', label: 'Usuario', columns: 1, required: true},
        {name: 'TransDate', editor: 'date', label: 'Fecha', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
            ],
        title: 'Historial de Usuario',
        headers: {
            RecordLocator: 'Localizador',
            HistoryCode: 'Acción',
            Detail: 'Detalle',
            CreatedUTC: 'Fecha/Hora UTC'
        },
        templates: [
            {name: 'HistoryCode', callback (row) {
                let t = codes[row.HistoryCode];
                if (t) return t;
                return "";
            }}

        ]
    }
  },
}
</script>

