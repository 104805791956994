<template>
    <home endpoint="salesconfiguration" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'salesconfiguration',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
          {name: 'id', hidden: 'true'},
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'Lid', label: 'Capacidad', required: true},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'},
          {name: 'SalesConfigurationClasses', editor: [], hideFromList: true, label: 'Clases',
            rowFields: [
                {name: 'ClassOfServiceId', relation: 'classofservice', optionLabels: 'id'
                    , editor: 'select', label: 'Clase', required: true},
                {name: 'Lid', label: 'Lid', editor: 'number', required: true}
            ]}
          ],
        title: 'Configuración de Venta',
        title2: 'Configuración de Venta'
      }
    }
  },
}
</script>

