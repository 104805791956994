<template>
    <div :class="containerClass">
        <form autocomplete="off" v-if="!params.hideHeader">
            <div class="form-row">
                <div class="form-group col-md-4"><h3>{{params.title}}</h3></div>
                <div class="form-group col-md-2" v-if="!params.readonly">
                    <button type="button" class="btn btn-primary" @click="addNew" data-toggle="modal"
                    :data-target="'#' + getModalId" :disabled="newDisabled" v-if="canAdd">
                    Nuevo</button>
                </div>
                <div class="form-group col-md-2" v-if="hasClosed">
                    <div class="form-check">
                        <input v-model="showClosed" name="showClosed" @change="fetchList()" type="checkbox" id="showClosed" class="form-check-input" />
                        <label for="showClosed" class="form-check-label">Incluir Cerrados</label>
                    </div>
                </div>
                <div class="form-group col-md-4" v-if="!params.serverside">
                    <input class="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" v-model="search">
                </div>
            </div>
        </form>

        <appnotification :notifications="notifications" @removenotifications="removeNotifications"></appnotification>

        <v-client-table :data="list" :columns="columnsNames" :options="options" v-if="!processing && !params.serverside && fieldOptions"
            @row-click="onRowClick">
        </v-client-table>

        <v-server-table :columns="columnsNames" :options="options" ref="ServerTable" v-if="!processing && params.serverside"
            @row-click="onRowClick">
        </v-server-table>

        <div v-if="processing">
            <img class="loading-gif" src="@/img/loading.gif">
        </div>

        <recordwindow v-if="current && getModalId=='modal-abm-window'" :endpoint="endpoint" :fields="params.fields" :title="params.title" :currentrecord="current"
        @remove="removeCurrent" @updatecurrent="updateCurrent" @close="clearCurrent" :title2="params.title2" :actions="params.actions" :afteredit="params.afteredit"
        @notification="addNotification" :cols="columns" @addcopy="addCopy" :cancopy="params.cancopy" :primarykey="params.primaryKey"></recordwindow>

        <accessgroupwindow v-if="current && getModalId=='accessgroupwindow'" :endpoint="endpoint" :currentrecord="current"
        @remove="removeCurrent" @updatecurrent="updateCurrent" @close="clearCurrent" @notification="addNotification"
        @addcopy="addCopy" :cancopy="params.cancopy" :accessGroups="accessGroups"></accessgroupwindow>

    </div>

</template>


<script>
import recordwindow from '@/components/tools/RecordWindow';
import accessgroupwindow from '@/components/tools/AccessGroupWindow';
import appnotification from '@/components/tools/Notification';
import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ServerTable, ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
Vue.use(ServerTable, [theme = 'bootstrap5']);
export default {
    name: 'abm',
    props: ['endpoint', 'params', 'tablename'],
     components: {
      recordwindow,
      appnotification,
      accessgroupwindow,
      ClientTable,
      ServerTable
    },


    data  () {
        let self = this;
        return {
            datalist: [],
            current: null,
            errors: [],
            notifications: [],
            processing: false,
            fieldOptions: null,
            sortfield: null,
            search:'',
            showClosed: false,
            columns: 2,
            newDisabled: true,
            canAdd: false,
            accessGroups: null,
            options: {
                filterable: this.params.serverside,
                perPageValues: [5, 10, 15, 20, 25, 50],
                perPage: this.params.perPage ? this.params.perPage : 15,
                headings: {},
                destroyEventBus: true,
                requestFunction: async function (data) {
                    let f = Object.assign({}, data)
                    f.fields = self.myfields;
                    return await app.get(self.endpoint, {getCount: true, toJSON: true, data: JSON.stringify(f)}).then(function(d) {
                        let datalist = _.each(d.query, (c) => {
                            c.original_id = c[self.params.primaryKey] ? c[self.params.primaryKey]: c.id;
                        })
                            return {data: datalist, count: d.count};
                        }).catch(function (e) {
                            this.dispatch('error', e);
                        }.bind(this));
                },
                texts: app.vueTableText(),
                templates: {},
                /*rowClassCallback (row) {
                    return 'table-sm';
                }*/
                skin: 'table table-striped  table-sm table-bordered',
            }
        }
    },
    computed:{
        ...mapState({
            user: state => state.user,
        }),
        getModalId (){
            if (this.params.modaltarget) return this.params.modaltarget;
            return 'modal-abm-window';
        },
        hasClosed  () {
            for (let f of this.params.fields) {
                if (f === 'Closed' || f.name === 'Closed') return true;
            }
            return false;
        },
        editorRows  () {
            return _.chunk(_.filter(this.myfields, (c) => !c.hidden), this.columns)
        },
        myfields  () {
            return app.myfields(this.params.fields);
        },
        list  () {
            return app.abmList(this);
        },
        columnsNames (){
            let res = [];
            for (let f of this.myfields) {
                if (f.type=='password') continue;
                if (f.hideFromList) continue;
                if (f.hidden) continue;
                res.push(f.name);
            }
            return res;
        },
        getHeadings () {
            let res = {};
            for (let f of this.myfields) {
                if (f.type=='password') continue;
                if (f.hidden) continue;
                if (f.label) {
                    res[f.name] = f.label;
                }
            }
            return res;
        },
        getTemplates () {
            let self = this;
            let res = {}
            for (let field of self.myfields) {
                res[field.name] = function(t, row) {
                        return self.getDisplayValue(row, field);
                    }
            }
            return res;

        },
        containerClass () {
            if (!this.params.hideHeader) return 'container';
            return '';
        }
    },
    created  () {
        this.newDisabled = true;
    },
    mounted: async function () {
        this.processing = true;
        window.abm = this
        if (!this.params.serverside) {
            await this.fetchList();
        }
        this.fieldOptions = await app.calculateFieldOptions(this, this.params.serverside);
        if (Object.keys(this.fieldOptions)) this.newDisabled = false;
        if (this.params.cols) this.columns = this.params.cols;
        this.canAdd = app.canAccess(this.user, 'api','POST', this.endpoint)
        this.options.headings = this.getHeadings;
        this.options.templates = this.getTemplates;
        this.processing = false;
        if (this.endpoint=='/api/accessgroup/') {
            let res = await app.get('/api/accessgroup/');
            if (res) this.accessGroups = res;
        }

    },
    watch: {
        current (){
            let self = this;
            this.$nextTick(function () {
                app.updateCurrentColor(self)
            });
        },
    },
    methods: {
        showRecord: async function(c) {
          let pkey = c[this.params.primaryKey] ? c[this.params.primaryKey]: c.id;
          let syncVersion = await app.get('/api/get_sync_version/' + this.tablename + '/' + pkey);
          if (syncVersion && syncVersion!=c.syncVersion) {
            c = await app.get('/api/' + this.tablename + '/' + pkey);
            if (c)  {
                c.original_id = pkey;
                this.current = c;
                app.updateCurrent(this, c);
            }
          } else {
            this.current = c;
          }
          this.$nextTick(function () {
            $('#' + this.getModalId).modal('show')
          })
        },
        toggleSort (field){
            app.toggleSort(this,field)
        },
        removeCurrent (id){
            if (!this.params.serverside) {
                this.datalist = _.filter(this.datalist, (c) => c.id != id);
            } else {
                this.$refs.ServerTable.refresh()
            }
        },
        updateCurrent (record){
            if (!this.params.serverside) {
                app.updateCurrent(this,record);
            } else {
                this.$refs.ServerTable.refresh()
            }
        },
        updateField (fieldname,value,id){
            app.updateField (this, fieldname, value, id)
        },
        clearCurrent (){
            $('#' + this.getModalId).modal('hide')
            app.clearCurrent(this);
        },
        addCopy (record){
            app.addCopy(this, record);
        },
        getDisplayValue (record, field) {
            return app.getDisplayValue(this.fieldOptions, record, field, this.params.serverside)
        },
        addNew  () {
            if (!this.canAdd){
                alert('Sin permiso para esta acción');
                return;
            }
            app.addNew(this);
            this.$nextTick(function () {
              $('#' + this.getModalId).modal('show')
            })
        },
        fetchList: async function () {
            if (!this.params.serverside) {
                await app.fetchList(this)
            }
        },
        addNotification   (msg) {
            app.addNotification(this, msg, true)
        },
        removeNotifications (){
            this.notifications = [];
        },
        onRowClick (data) {
            this.showRecord(data.row);
        }
    },
    beforeDestroy() {
        Event.$off('vue-tables.row-click');
    },
}
</script>
