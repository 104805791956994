<template>
  <div class="modal fade printable" id="fee-values" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog history-modal" role="document">
      <div class="modal-content">
        <div class="modal-body row">
          <div class="col-6 offset-3">
            <table class="table table-striped table-sm table-bordered">
             <tbody>
             <tr >
              <td>AVIH</td>
              <td>Animal en bodega</td>
              <td>$ 500,00</td>
             </tr>
             <tr >
              <td >BAG1</td>
              <td >1 Valija hasta 15 kilos</td>
              <td >$ 700,00</td>
             </tr>
             <tr >
              <td >BAG2</td>
              <td >2 Valijas hasta 15 kilos</td>
              <td >$ 1.400,00</td>
             </tr>
             <tr >
              <td >BAG3</td>
              <td >3 Valijas hasta 15 kilos</td>
              <td >$ 2.100,00</td>
             </tr>
             <tr >
              <td >BAG4</td>
              <td >4 Valijas hasta 15 kilos</td>
              <td >$ 2.800,00</td>
             </tr>
             <tr >
              <td >BAG5</td>
              <td >5 Valijas hasta 15 kilos</td>
              <td >$ 3.500,00</td>
             </tr>
             <tr >
              <td >BLND</td>
              <td >Blind</td>
              <td >0.00</td>
             </tr>
             <tr >
              <td >CAON</td>
              <td >Carry On de 0 a 5 Kilos</td>
              <td >0.00</td>
             </tr>
             <tr >
              <td >CHD</td>
              <td >UMNR</td>
              <td >$ 1.200,00</td>
             </tr>
             <tr >
              <td >CHK</td>
              <td >Checkin Aeropuerto</td>
              <td >$ 200,00</td>
             </tr>
             <tr >
              <td >COCH</td>
              <td >Cochecito</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >EXB2</td>
              <td >Exceso de Equipaje de 6 a 10</td>
              <td >$ 250,00</td>
             </tr>
             <tr >
              <td >EXB3</td>
              <td >Exceso de Equipaje de 11 a 15</td>
              <td >$500,00</td>
             </tr>
             <tr >
              <td >EXB4</td>
              <td >Exceso de Equipaje de 16 a 20</td>
              <td >$ 750,00</td>
             </tr>
             <tr >
              <td >EXB5</td>
              <td >Exceso de Equipaje de 21 a 25</td>
              <td >$ 1.250,00</td>
             </tr>
             <tr >
              <td >EXB6</td>
              <td >Exceso de Equipaje de 26 a 30</td>
              <td >$ 1.500,00</td>
             </tr>
             <tr >
              <td >EXB7</td>
              <td >Exceso de Equipaje de 31 a 40</td>
              <td >$ 2.000,00</td>
             </tr>
             <tr >
              <td >EXB8</td>
              <td >Exceso de Equipaje de 41 a 50</td>
              <td >$ 2.500,00</td>
             </tr>
             <tr >
              <td >EXB9</td>
              <td >Exceso de Equipaje de 51 a 60</td>
              <td >$ 3.000,00</td>
             </tr>
             <tr >
              <td >EXC1</td>
              <td >Exceso de Equipaje de 61 a 70</td>
              <td >$ 3.500,00</td>
             </tr>
             <tr >
              <td >EXC2</td>
              <td >Exceso de Equipaje de 71 a 80</td>
              <td >$ 4.000,00</td>
             </tr>
             <tr >
              <td >HCON</td>
              <td >Carry On de 5 a 7 Kilos</td>
              <td >$ 600,00</td>
             </tr>
             <tr >
              <td >INF</td>
              <td>&nbsp;</td>
              <td>$ 0,00</td>
             </tr>
             <tr >
              <td >MAAS</td>
              <td >MEET AND ANSIST</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >MEDF</td>
              <td >MEDIF</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >OTHS</td>
              <td >Otros Mensajes</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >OXWN</td>
              <td >concentrador</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >PREG</td>
              <td >embarazada</td>
              <td >$ 0,00</td>
             </tr>
             <tr >
              <td >UB1</td>
              <td >Mejora valija de 15 a 23 kilos</td>
              <td >$ 200,00</td>
             </tr>
             <tr >
              <td >UB2</td>
              <td >Mejora 2 valija de 15 a 23 kilos</td>
              <td >$ 400,00</td>
             </tr>
             <tr >
              <td >UB3</td>
              <td >Mejora 3 valija de 15 a 23 kilos</td>
              <td >$ 600,00</td>
             </tr>
             <tr >
              <td >UB4</td>
              <td >Mejora 4 valija de 15 a 23 kilos</td>
              <td >$ 800,00</td>
             </tr>
             <tr >
              <td >UB5</td>
              <td >Mejora 5 valija de 15 a 23 kilos</td>
              <td >$ 1.000,00</td>
             </tr>
             <tr >
              <td >PG</td>
              <td >Upgrade Equipaje de 15 a 23 Kilos</td>
              <td >&nbsp;</td>
             </tr>
             <tr >
              <td >WCHR</td>
              <td >Wheelchair</td>
              <td >$0,00</td>
             </tr>
             <tr >
              <td >WCHS</td>
              <td >Whellstep</td>
              <td >$0,00</td>
             </tr>
             <tr >
              <td >WEAP</td>
              <td >Armaen Bodega</td>
              <td >$0,00</td>
             </tr>
            </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'feevalues',
}
</script>

