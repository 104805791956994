<template>
    <div>

      <report-window endpoint="/reports/sales_by_location_payment" :fields="fields" :title="title" :headers="headers"
      :templates="templates" :columnsClasses="columnsClasses"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'salesbylocationpayment',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'PaymentMethodCode', relation: 'paymentmethod', optionLabels: 'Name', id: 'PaymentMethodCode'
            , editor: 'select', label: 'Medio de Pago'},
        {name: 'LocationCode', relation: 'location', optionLabels: 'Name', id: 'id'
            , editor: 'select', label: 'Ubicación'},
         ],
        title: 'Ventas por Ubicación / Medio de Pago',
        headers: {Amount: 'Monto', PaymentMethodCode: 'Medio de Pago', LocationCode: 'Ubicación', CreatedUTC: 'Fecha'},
        templates: [
            {name: 'Amount', callback (row) {return app.numberFormat(parseFloat(row.Amount));}},
        ],
        columnsClasses: {Amount: 'text-right'},

    }
  },
}
</script>

