<template>
  <div class="modal fade" id="confirm-seats" tabindex="-1" role="dialog" aria-labelledby="confirmSeatsLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmSeatsLabel">Confirmar</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Asignar Asientos?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Cancelar</button>
          <button type="button" class="btn btn-success" @click="save">Asignar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
export default {
    name: 'confirm-seats',
    mixins: [escape],
    methods: {
        save () {
            this.$emit('save')
            this.close();
        },
    }
}
</script>
