<template>
    <div>
      <report-window endpoint="/reports/creditfile" :fields="fields" :title="title" :headers="headers" :templates="templates"></report-window>
    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'creditfile',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'Code', editor: 'text', label: 'Código', columns: 1, required: true},
            ],
        title: 'Credit File',
        headers: {},
        templates: []
    }
  },
}
</script>

