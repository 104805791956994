<template>
  <div>
      <div v-for="(per,key1) in json">
          <div class="form-group card">
              <div class="row access-group-row">
                  <!--input :value="key1" class="form-control access-group-input" type="text"
                  style="margin-left: 20px; width: 20%;" @change="changeKey(2,$event.target.value,key1)"-->

                    <selectinput fieldname="key1" :fieldOptions="fieldOptions" label="" :id="[1, key1]"
                    :current_value.sync="key1" @change="changeKeySelect" addClass="json-select" :disabled="true">
                    </selectinput>


                  <button type="button" class="btn btn-success json-button" @click="addLevel(1,key1)">
                    <font-awesome-icon icon="plus"/>
                  </button>
                  <button type="button" class="btn btn-danger json-button" @click="removeLevel(1,key1)">
                    <font-awesome-icon icon="trash-alt"/>
                  </button>
              </div>
              <div v-for="(route,key2) in per">
                  <div class="row access-group-row" v-if="key1!='access_group'">

                      <!--input :value="key2" class="form-control access-group-input" type="text"
                      style="margin-left: 30px; width: 20%" @change="changeKey(2,$event.target.value,key1,key2)"-->

                      <selectinput :fieldname="key1 + '_key2'" :fieldOptions="fieldOptions" label="" :id="[2, key1, key2]"
                        :current_value.sync="key2" @change="changeKeySelect" addClass="json-select-key2" :sort="true">
                      </selectinput>


                      <button type="button" class="btn btn-success json-button" @click="addLevel(2,key1,key2)">
                        <font-awesome-icon icon="plus"/>
                      </button>
                      <button type="button" class="btn btn-danger json-button" @click="removeLevel(2,key1,key2)">
                        <font-awesome-icon icon="trash-alt"/>
                      </button>
                      <div v-for="(methods,key3) in route" class="col-sm-12">
                          <div class="row access-group-row" style="margin-left: 40px;">
                              <div class="col-sm-4 row" >
                                  <!--input :value="key3" class="form-control col-sm-9 access-group-input" type="text"
                                  @change="changeKey(3,$event.target.value,key1,key2,key3)"-->

                                    <button type="button" class="btn btn-danger json-button" @click="removeLevel(3,key1,key2,key3)">
                                        <font-awesome-icon icon="trash-alt"/>
                                    </button>
                                    <selectinput fieldname="key3" :fieldOptions="fieldOptions" label="" :id="[3, key1, key2, key3]"
                                    :current_value.sync="key3" @change="changeKeySelect" addClass="json-select" :sort="true">
                                    </selectinput>

                              </div>

                              <div v-if="key3=='methods'" class="col-sm-2 row" v-for="(methods,key4) in methods" >
                                  <div class="col-sm-12" >
                                      <button type="button" class="json-button" :class="getButtonClass(key1,key2,key3,key4)"
                                          @click="setClass(key1,key2,key3,key4)">
                                          {{methodsLabel[key4]}}
                                      </button>
                                  </div>
                              </div>

                              <div v-if="key3=='filter'" class="col-sm-8 row" >
                                  <div class="col-sm-12" >
                                      <input :value="json[key1][key2][key3]['VALUE']"
                                      class="form-control col-sm-9 access-group-input" type="text"
                                      @change="changeKey(4,$event.target.value,key1,key2,key3)">
                                  </div>
                              </div>

                              <div v-if="key3=='filterIDs'" class="col-sm-8 row" >
                                  <div class="col-sm-12" >
                                      <input :value="json[key1][key2][key3]['VALUE']"
                                      class="form-control col-sm-9 access-group-input" type="text"
                                      @change="changeKey(4,$event.target.value,key1,key2,key3)">
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
                  <div class="row access-group-row" v-else>
                    <div class="access-group-input" style="margin-left: 30px; width: 20%">
                        <selectinput fieldname="AcessGroupId" :fieldOptions="fieldOptions" label="" :id="[2, key1, key2]"
                        :current_value.sync="key2" @change="changeKeySelect">
                        </selectinput>
                    </div>
                  </div>
              </div>
          </div>
      </div>
        <div class="form-row">
            <button type="button" class="btn btn-primary" @click="addFirstLevel" :disabled="countKeys()">Agregar</button>
        </div>
        <selectvalue v-if="selectFirstLevel" @save="setFirstLevel" @close="closeSelectFirstLevel"
          text="Elegir Opción" :fieldOptions="selectFirstLevel"></selectvalue>

  </div>
</template>

<script>
import selectinput from '@/components/tools/SelectInput'
import selectvalue from '@/components/tools/SelectValue';
export default {
    name: 'jsonview',
    props: ['permissions', 'pjson', 'accessgroups', 'id'],
    components: {
        selectinput,
        selectvalue
    },
    data  () {
        return {
            json: {},
            fieldOptions: {
                AcessGroupId: [],
                key1: [
                    {value: 'api', label: 'Acciones'},
                    {value: 'view', label: 'Vista'},
                    {value: 'access_group', label: 'Herencia'},
                ],
                key3: [
                    {value: 'methods', label: 'Métodos'},
                    {value: 'filter', label: 'Filtrar'},
                    {value: 'filterIDs', label: 'Códigos'},
                ],
                api_key2: [
                    {value: 'defaults', label: 'Por Defecto'},
                    {value: 'overbook_leg', label: 'Overbook Segmento'},
                    {value: 'overbook_class', label: 'Overbook Clase'},
                    {value: 'allow_past_fliths', label: 'Vuelos Pasados'},
                    {value: 'allow_all_fliths', label: 'Vuelos No Confirmados'},
                    {value: '/booking/view_booking_class/', label: 'Clases para Venta'},
                    {value: '/booking/delete_fee/', label: 'Eliminar Cargos'},
                    {value: '/booking/override_fee/', label: 'Override Cargos'},
                    {value: '/booking/refund_payment/', label: 'Devolver Pagos'},
                    {value: '/booking/delete_payment/', label: 'Eliminar Pagos'},
                    {value: '/booking/set_hold/', label: 'Reserva en Hold'},
                    {value: '/booking/set_payment_status/', label: 'Cambiar Estado de Pagos'},
                    {value: '/booking/move_flight/', label: 'Mover Vuelos'},
                    {value: '/booking/move_flight_legs/', label: 'Mover Legs'},
                    {value: '/booking/split', label: 'Dividir Reservas'},
                    {value: '/checkin/open_flight', label: 'Abrir Vuelos'},
                    {value: '/checkin/open_flight_pending', label: 'Abrir Vuelos Pendientes'},
                    {value: '/booking/change_name/', label: 'Cambiar Nombre'},
                    {value: '/checkin/checkin_actions/', label: 'Acciones de Checkin'},
                    {value: '/checkin/unblock_seatmap/', label: 'Desbloquear Mapa de Asientos'},
                    {value: '/checkin/set_std', label: 'Cambior STD en Checkin'},
                    {value: '/checkin/uncheck', label: 'Deschequear Pasajeros'},
                    {value: '/checkin/uncheck_all', label: 'Deschequear Todos los Pasajeros'},
                    {value: '/checkin/check_all', label: 'Chequear Todos los Pasajeros'},
                    {value: '/checkin/unboard_all', label: 'Desembarcar Todos los Pasajeros'},
                    {value: '/checkin/board_all', label: 'Embarcar Todos los Pasajeros'},
                    {value: '/booking/get_availability', label: 'Consultar Disponibilidad'},
                    {value: '/booking/create', label: 'Crear Reserva'},
                    {value: '/booking/passengers_info', label: 'Insertar Pasajeros'},
                    {value: '/booking/agency_payment', label: 'Pago Agencia'},
                    {value: '/booking/group/', label: 'Grupos en Reserva'},
                    {value: '/booking/import_pax/', label: 'Importar Pasajeros'},
                    {value: '/booking/override_fare/', label: 'Override Tarifa'},
                ],
                view_key2: [
                    {value: 'defaults', label: 'Por Defecto'},
                    {value: '/system_menu/', label: 'Menu Sistema'},
                    {value: '/fares_menu/', label: 'Menu Tarifas'},
                    {value: '/inventory_menu/', label: 'Menu Inventario'},
                    {value: '/sales_menu/', label: 'Menu Ventas'},
                    {value: '/reports_menu/', label: 'Menu Reportes'},
                    {value: '/routines_menu/', label: 'Menu Rutinas'},
                    {value: '/airport_menu/', label: 'Menu Aeropuerto'},
                ]
            },
            methodsLabel: {
                'DELETE': 'Eliminar',
                'POST': 'Editar',
                'GET': 'Leer'
            },
            selectFirstLevel: null,
        }
    },
    computed:{
    },
    created (){
    },
    mounted  () {
        this.json = this.permissions;
        for (let r of this.accessgroups) {
            if (r.id==this.id) continue;
            this.fieldOptions.AcessGroupId.push({label: r.Description, value: r.id})
        }
        for (let route of this.$router.options.routes) {
            if (['*', '/', '/login', '/home', '/changepassword'].indexOf(route.path)>=0) continue;
            this.fieldOptions.view_key2.push({value: route.path, label: route.label || route.path})
            this.fieldOptions.api_key2.push({value: route.path + '/', label: route.label})
        }
    },
    watch: {
        json: {
            handler () {
                    },
                    deep: true,
        },
        pjson: {
            handler (oldValue,newValue) {
                        if (oldValue && newValue) {
                            if (oldValue!=newValue) {
                                try {
                                    r = JSON.parse(this.pjson)
                                    this.json = r
                                    this.$emit('update:permissions', this.json);
                                }
                                catch (e)
                                { }

                            }
                        }
                    },
                    deep: true,
        },

    },
    methods: {
        getButtonClass (key1,key2,key3,key4){
            if (this.permissions[key1][key2][key3][key4]) return 'btn btn-success'
            return 'btn btn-danger'

        },
        setClass (key1,key2,key3,key4){
            this.json[key1][key2][key3][key4] = !this.json[key1][key2][key3][key4];
            this.json = Object.assign({},this.json);
            this.update();
        },
        update () {
            this.$emit('update:permissions', this.json);
            this.$emit('update:pjson', JSON.stringify(this.json));
        },
        setFirstLevel (value) {
            this.selectFirstLevel = null;
            $('#selectValue').modal('hide');
            this.json[value] = {};
        },
        closeSelectFirstLevel () {
            this.selectFirstLevel = null;
            $('#selectValue').modal('hide');
        },
        countKeys () {
            if (Object.keys(this.json).length==3) return true;
            return false;
        },
        addFirstLevel () {
            let values = [];
            for (let option of this.fieldOptions.key1) {
                if (Object.keys(this.json).indexOf(option.value)==-1) {
                    values.push(option);
                }
            }
            this.selectFirstLevel = values;
            this.$nextTick(function() {
                $('#selectValue').modal('show');
            })
        },
        addLevel (level,key1,key2){
            if (level==1) this.json[key1]['-'] = {}
            if (level==2) this.json[key1][key2]['-'] = {}
            this.json = Object.assign({},this.json);
            this.update();
        },
        removeLevel (level,key1,key2,key3){
            if (level==1) delete this.json[key1]
            if (level==2) delete this.json[key1][key2]
            if (level==3) delete this.json[key1][key2][key3]
            this.json = Object.assign({},this.json);
            this.update();
        },
        changeKey (level,newValue,key1,key2,key3){
            if (level==1){
                let obj = Object.assign({},this.json[key1]);
                delete this.json[key1];
                this.json[newValue] = obj;
            }
            if (level==2){
                let obj = Object.assign({},this.json[key1][key2]);
                delete this.json[key1][key2];
                this.json[key1][newValue] = obj;
            }
            if (level==3){
                let obj = Object.assign({},this.json[key1][key2][key3]);
                if (Object.keys(obj).length==0 && newValue=='methods'){
                    obj = {'DELETE': false, 'GET': false, 'POST': false}
                }
                if (Object.keys(obj).length==0 && newValue=='filter'){
                    obj = {'VALUE': null}
                }
                if (Object.keys(obj).length==0 && newValue=='filterIDs'){
                    obj = {'VALUE': null}
                }
                delete this.json[key1][key2][key3];
                this.json[key1][key2][newValue] = obj;
            }
            if (level==4){
                this.json[key1][key2][key3] = {'VALUE': newValue};
            }

            this.json = Object.assign({},this.json);
            this.update();
        },
        changeKeySelect (field_value, id, fieldname, level) {
            this.changeKey(id[0], field_value, id[1], id[2], id[3]);

        }

    }
}
</script>

