<template>
    <div>
        <div class="container" id="login">
            <form id="form" class="login-form" action="javascript:void(0)">
              <div class="form-group">
                <label for="username">Usuario</label>
                <input type="text" class="form-control" id="username" name="username" v-model="username">
              </div>
              <div class="form-group">
                <label for="password">Contraseña</label>
                <input type="password" class="form-control" id="password" name="password" v-model="password">
              </div>
              <button id="submit" @click="login" class="btn btn-primary">Ingresar</button>
            </form>
        </div>
    </div>
</template>

<script>
import '@/css/app.css';
export default {
  name: 'login',
  data () {
    return {
      username: null,
      password: null,
      serverurl: process.env.SERVER_ENDPOINT
    }
  },
  methods: {
   login  () {
     let params = {username: this.username, password: this.password}
     let self = this;
     jQuery.ajax({
          url: self.serverurl + '/login',
          data: params,
          method: 'post',
          crossDomain: true,
          xhrFields: {
              withCredentials: true
          },
          success  (result, status, xhr) {
              self.$store.commit('setUser', result);
              self.$store.dispatch('setStations');
              self.$store.dispatch('setFees');
              self.$router.push('/')
          },
          error  (error) {
              if (error.status==401) {
                 alert('credenciales incorrectas');
              } else if (error.status==0) {
                  alert('Error de Servidor')
              } else {
                  alert(error.statusText)
              }

          }
     })
    }
  }
}
</script>

