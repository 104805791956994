<template>
    <div>

      <report-window endpoint="/reports/flightload" :fields="fields" :title="title" :headers="headers"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'flihgtload',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'DepartureStation', relation: 'station', optionLabels: 'id'
            , editor: 'select', label: 'Desde'},
        {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
            , editor: 'select', label: 'Hacia'},
        {name: 'FlightNr', editor: 'text', label: 'Vuelo', columns: 1},
        {name: 'ByLeg', label: 'Detalle Por Tramo', editor: 'select', defValue: '0',
            options: [
                {value: '0', label: 'No'},
                {value: '1', label: 'Si'},
                ]},
        {name: 'IncludeCanceled', label: 'Incluir Cancelados', editor: 'select', defValue: '0',
            options: [
                {value: '0', label: 'No'},
                {value: '1', label: 'Si'},
                ]},
            ],

        title: 'Ocupación de Vuelo',
        headers: {FlightNumber: 'Vuelo', Market: 'Ruta', STD: 'Hora'}
    }
  },
}
</script>

