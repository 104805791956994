<template>
  <div class="modal fade printable" id="split-booking" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog history-modal" role="document">
      <div class="modal-content">
        <div class="modal-body itinerary-body">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th></th>
                        <th>Pasajero</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="pax of booking.Passengers">
                        <td><input type="checkbox" v-model="selected[pax.PassengerID]" @change="setSelected(pax.PassengerID)"></td>
                        <td>{{pax.LastName}} {{pax.FirstName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" :disabled="!isSelected" class="btn btn-primary" @click="save">Dividir Reserva</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'splitbooking',
  mixins: [escape],
  components: {
  },
  data  () {
    return {
        selected: {},
    }
  },
  mounted: async function() {
    for (let p of this.booking.Passengers) {
        this.selected[p.PassengerID] = false;
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    isSelected () {
        for (let i in this.selected) {
            if (this.selected[i]) return true;
        }
        return false;
    },
  },
  methods: {
    setSelected () {
        this.selected = Object.assign({}, this.selected);
    },
    save () {
        let res = [];
        for (let p of this.booking.Passengers) {
            if (this.selected[p.PassengerID]){
                res.push(p.PassengerID)
            }
        }
        this.$emit("split", res);
        this.close();
    },
  },
}
</script>

