<template>
    <div>

      <report-window endpoint="/reports/sales_by_classmarket" :fields="fields" :title="title" :headers="headers"
      :templates="templates" :columnsClasses="columnsClasses"></report-window>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'salesbyclassmarket',
  components: {

    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        fields: [
        {name: 'FromDate', editor: 'date', label: 'Desde', defValue: moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'ToDate', editor: 'date', label: 'Hasta', defValue:  moment(new Date()).format("YYYY-MM-DD")
            , required: true, columns: 1},
        {name: 'Mode', label: 'Expandir', editor: 'select', defValue: '0',
            options: [
                {value: '0', label: 'No'},
                {value: '1', label: 'Si'},
                ]},
        {name: 'DateMode', label: 'Filtrar por', editor: 'select', defValue: '0',
            options: [
                {value: '0', label: 'Fecha de Venta'},
                {value: '1', label: 'Fecha de Vuelo'},
                ]},
         {name: 'LiftStatus', label: 'Estado Segmento', editor: 'select', defValue: '0',
            options: [
                {value: null, label: 'Todos'},
                {value: '0', label: 'No Chequeado'},
                {value: '1', label: 'Chequeado'},
                {value: '2', label: 'Embarcado'},
                {value: '3', label: 'No Show'},
                {value: '4', label: 'Endosado'},
                ]},

         ],

        title: 'Ventas por Clase/Segmento',
        headers: {Amount: 'Total', DepartureStation: 'Origen', ArrivalStation: 'Destino', Fare: 'Tarifa'},
        templates: [
            {name: 'Amount', callback (row) {return app.numberFormat(parseFloat(row.Amount));}},
            {name: 'Fare', callback (row) {return app.numberFormat(parseFloat(row.Fare));}},
            {name: 'XR', callback (row) {return app.numberFormat(parseFloat(row.XR));}},
            {name: 'QN', callback (row) {return app.numberFormat(parseFloat(row.QN));}},
            {name: 'TQ', callback (row) {return app.numberFormat(parseFloat(row.TQ));}},
            {name: 'DL', callback (row) {return app.numberFormat(parseFloat(row.DL));}},
        ],
        columnsClasses: {Amount: 'text-right'},

    }
  },
}
</script>

