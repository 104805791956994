<template>
  <div>
      <div v-if="field.editor=='checkbox'" class="form-check">
          <input v-model="value" :name="field.name" class="form-check-input" :class="field.classes" type="checkbox"
          :disabled="field.disabled" :readonly="field.readonly" :id="field.name" @change="afterEdit(null, field.name, field.name)">
          <label :for="field.name" class="form-check-label">{{field.label}}</label>
      </div>
      <div v-else-if="field.editor=='textarea'" >
          <label :for="field.name" >{{field.label}}</label>
          <textarea v-model="value" :name="field.name" class="form-control" :class="field.classes"  rows="3"
          :disabled="field.disabled" :readonly="field.readonly" :id="field.name"
          @change="afterEdit(null, field.name, field.name)"></textarea>
      </div>
      <div v-else-if="field.type=='password' && !field.hidden" >
          <label :for="field.name" >{{field.label}}</label>
          <input v-model="value" :name="field.name" class="form-control" :class="field.classes" type="password"
          :disabled="field.disabled" :readonly="field.readonly" :id="field.name"
          @change="afterEdit(null, field.name, field.name)">
      </div>
      <div v-else-if="field.editor=='number'" >
          <label :for="field.name" >{{field.label}}</label>
          <numberinput :v.sync="value" :d="field.decimal?field.decimal: 0"
            @change="afterEdit(null, field.name, field.name)"
            :disabled="field.disabled" :readonly="field.readonly" :id="field.name"></numberinput>
      </div>
      <div v-else-if="field.editor=='select'" >
          <selectinput :fieldname="field.name" :fieldOptions="fieldOptions" :label="field.label"
          :current_value.sync="value" :required="field.required" :disabled="field.disabled" :addBlank="field.addBlank"
          @change="afterEdit(null, field.name, field.name)">
          </selectinput>
      </div>
      <div v-else-if="field.editor=='date'" >
          <label :for="field.name">{{field.label}}</label>
          <datepicker :id="field.name" :def.sync="value" @changedate="afterEdit(null, field.name, field.name)"></datepicker>
      </div>
      <div v-else-if="!fieldOptions[field.name] && !field.hidden && !Array.isArray(field.editor) && field.editor!='component' " >
          <label :for="field.name" >{{field.label}}</label>
          <input v-model="value" :name="field.name"  class="form-control" :class="field.classes"  type="text"
           :disabled="field.disabled" :readonly="field.readonly" :id="field.name"
           @change="afterEdit(null, field.name, field.name)">
      </div>
  </div>
</template>


<script>
import selectinput from '@/components/tools/SelectInput'
import datepicker from '@/components/tools/DatePicker'
import numberinput from '@/components/tools/NumberInput';
import { mapState } from 'vuex';
export default {
    name: 'inputfield',
    props: [ 'field', 'defValue', 'fieldOptions'],
    components: {
      selectinput,
      datepicker,
      numberinput
    },
    data  () {
        return {
            value: null,
        }
    },
    mounted () {
        this.value = this.defValue;
    },
    watch: {
        defValue () {
            this.value = this.defValue;
        }
    },
    methods: {
        afterEdit (newValue, id, fieldname) {
            let self = this;
            this.$nextTick(function() {
                self.$emit('update:defValue', self.value);
                self.$emit('afteredit', self.value, id, fieldname);
            });
        }
    }
}
</script>
