<template>
  <div class="modal fade bd-example-modal-lg" id="modal-abm-window" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" :class="modalClass">
      <div class="modal-content" v-if="current">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{title2}}
              <i v-if="!getId()">(Nuevo)</i>
              <i v-else-if="isModified">(Modificado)</i>
          </h5>
          <button type="button" class="close" aria-label="Close" @click="$emit('close')">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-if="errors.length>0" class="alert alert-danger" role="alert">
            <div v-for="error of errors">
                {{error}}
            </div>
        </div>
        <div class="modal-body">
          <form autocomplete="off">
          <ul class="nav nav-tabs" id="tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="fields-tab" data-toggle="tab" href="#fields" role="tab" aria-controls="fields" aria-selected="true">Datos</a>
            </li>
            <li class="nav-item" v-for="arrayField of arrayFields">
              <a class="nav-link" :id="'detail-tab-' + arrayField.name" data-toggle="tab" :href="'#details-' + arrayField.name"
                  role="tab" :aria-controls="'details-' + arrayField.name" aria-selected="false">{{arrayField.label}}</a>
            </li>
            <li class="nav-item" v-for="comp of componentFields" :class="{'tab-alert': current.invalid[comp.name]}">
                <a class="nav-link" :id="'detail-tab-' + comp.name" data-toggle="tab" :href="'#component-' + comp.name"
                    role="tab" :aria-controls="'component-' + comp.name" aria-selected="false">
                        {{comp.label}}
                 </a>
            </li>
          </ul>
          <div class="tab-content" id="tabsContent">
            <div class="tab-pane fade show active" id="fields" role="tabpanel" aria-labelledby="fields-tab">
                  <div v-for="editorRow of editorRows" class="form-row">
                      <div v-for="field of editorRow" :class="'form-group col-md-' + getColumns(field)" v-if="!field.hidden">
                          <inputfield :field="field" :defValue.sync="current[field.name]" :fieldOptions="fieldOptions"
                            @afteredit="afterEdit"></inputfield>
                      </div>
                  </div>
            </div>
            <div v-for="arrayField of arrayFields" class="tab-pane fade" :id="'details-' + arrayField.name" role="tabpanel"
              :aria-labelledby="'details-tab-' + arrayField.name" >
              <div class="form-row" v-for="(row,rowNr) of current[arrayField.name]" v-if="!current[arrayField.name][rowNr].Closed">
                  <input v-model="current[arrayField.name][rowNr]['id']" type="hidden">
                  <div v-for="rowField of arrayField.rowFields" :class="getRowColumns(rowField)" v-if="!rowField.hidden">
                      <rowinputfield :rowField="rowField" :defValue.sync="current[arrayField.name][rowNr][rowField.name]" :arrayField="arrayField"
                        :fieldOptions="fieldOptions" :row="current[arrayField.name][rowNr]" :rowNr="rowNr" @afteredit="afterEdit"></rowinputfield>
                  </div>
                  <div class="form-group col-md-1">
                     <button type="button" class="btn btn-primary row-delete-button" rel="tooltip"
                     data-placement="top" title="Borrar" @click="removeRow(arrayField.name, rowNr)">
                        <font-awesome-icon icon="trash-alt"/>
                     </button>
                  </div>
                  <div class="form-group col-md-12" v-if="current[arrayField.name][rowNr].ModifiedUserID">
                      <editedby :current="current[arrayField.name][rowNr]"></editedby>
                  </div>
              </div>
              <br>
              <button type="button" class="btn btn-primary" @click="addLine(arrayField.name)">Agregar</button>
            </div>
            <div v-for="comp of componentFields" class="tab-pane fade" :id="'component-' + comp.name" role="tabpanel"
              :aria-labelledby="'comp-tab-' + comp.name" >
                  <component :is="comp.component" :record="current" :ref="comp.name" :tabClass.sync="tabClass"></component>
            </div>
          </div>
          </form>
        </div>
        <div class="modal-footer">
          <editedby :current="currentrecord"></editedby>
          <button v-for="action in actions" type="button" class="btn btn-primary" @click="callAction(action.method)">{{action.label}}</button>
          <button type="button" class="btn btn-outline-secondary" @click="save(current)" v-if="canEdit">Guardar cambios</button>
          <button v-if="cancopy" type="button" class="btn btn-primary" @click="copy(current)">Copiar</button>
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#confirmDelete"
              v-if="canDelete">Eliminar</button>
        </div>
      </div>
    </div>
    <confirmdelete @confirmRemove="remove(current)"></confirmdelete>
  </div>
</template>


<script>
import * as _ from 'lodash';
import confirmdelete from '@/components/tools/ConfirmDelete';
import selectinput from '@/components/tools/SelectInput';
import datepicker from '@/components/tools/DatePicker';
import inputfield from '@/components/tools/InputField'
import rowinputfield from '@/components/tools/RowInputField'
import editedby from '@/components/tools/EditedBy';
import { mapState } from 'vuex';
export default {
    name: 'recordwindow',
    props: ['endpoint', 'fields', 'title', 'currentrecord', 'title2', 'cols', 'cancopy', 'actions', 'afteredit',
        'primarykey', 'modalClass'],
    components: {
      confirmdelete,
      selectinput,
      datepicker,
      rowinputfield,
      inputfield,
      editedby,
    },
    data  () {
        return {
            current: null,
            errors: [],
            processing: false,
            fieldOptions: {},
            getInvalidClass: {},
            modified: false,
            isMounted: false,
            canDelete: false,
            canEdit: false,
            tabClass: {},
        }

    },
    computed:{
        ...mapState({
            user: state => state.user,
        }),
        isModified (){
            return this.modified;
        },
        columns () {
            return 12 / this.cols;
        },
        editorRows  () {
            return [this.myfields];
            //return _.chunk(_.filter(this.myfields, (c) => !c.hidden), this.cols)
        },
        myfields  () {
            return app.myfields(this.fields);
        },
        arrayFields  () {
            return app.arrayFields(this.myfields);
        },
        componentFields  () {
            let res = []
            for (let f of this.myfields) {
                if (f.editor=='component'){
                    res.push(f)
                }
            }
            return res;

        }
    },
    created (){
    },
    mounted: async function () {
        this.current = _.cloneDeep(this.currentrecord);
        this.fieldOptions = await app.calculateFieldOptions(this);
        this.canEdit = app.canAccess(this.user, 'api','POST', this.endpoint)
        this.canDelete = app.canAccess(this.user, 'api','DELETE', this.endpoint)
        let self = this;
        app.addEscapeKey(this, '#modal-abm-window')
        setTimeout(() => {
            self.isMounted = true;
        }, 2000)
    },
    watch: {
        currentrecord () {
            this.current = _.cloneDeep(this.currentrecord);
        },
        current: {
            handler () {
                        if (this.isMounted) this.modified = true;
                    },
                    deep: true,
        },
    },
    methods: {
        copy (record) {
            let newrecord =  _.cloneDeep(record);
            newrecord.id = null;
            newrecord.original_id = null;
            this.$emit("addcopy", newrecord);
            $('#modal-abm-window').animate({
                scrollTop : 0
            }, 500);
        },
        getColumns (field) {
            if (field.columns) return field.columns;
            return this.columns;
        },
        getRowColumns (rowfield) {
            let classes = {'form-group': true};
            if (rowfield.columns) classes['col-md-' + rowfield.columns] = true;
            if (rowfield.class) classes[rowfield.class] = true;
            return classes;
        },

        save (record) {
            if (!this.canEdit){
                alert('Sin permiso para esta acción');
                return;
            }
            app.saveRecord(this, record, '#modal-abm-window')
        },
        remove (record) {
            app.remove(this,record)
        },
        addError   (msg) {
            app.addError(this, msg)
        },
        addLine (fieldname){
            app.addLine(this, fieldname);
        },
        evalCondition (row,condition) {
            var res = condition;
            for (fieldname in row){
                res = res.replace(fieldname, row[fieldname])
            }
            return eval(res);
        },
        callAction (method) {
            app.callAction(method, this);
        },
        afterEdit: async function(value, id, fieldname, rowfieldname, rownr) {
            if (this.afteredit && this.afteredit[fieldname]) {
                let self = this;
                await this.afteredit[fieldname](this.current, rowfieldname, rownr);
            }
            await this.$nextTick();
            this.refreshComps(id, fieldname, rowfieldname, rownr);
        },
        refreshComps (id, fieldname, rowfieldname, rownr) {
            for (let c of this.componentFields) {
                let ref = this.$refs[c.name];
                if (ref) ref[0].refresh(id, fieldname, rowfieldname, rownr);
            }
        },
        async removeRow (fieldname, rowNr) {
            this.current[fieldname].splice(rowNr, 1);
            this.current = Object.assign({}, this.current);
            if (this.afteredit && this.afteredit[fieldname]) {
                this.afteredit[fieldname](this.current);
            }
            await this.$nextTick();
            this.refreshComps();
        },
        getId () {
            if (this.primarykey) return this.current[this.primarykey];
            return this.current.id;
        }
    }
}
</script>
