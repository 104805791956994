<template>
    <div class="h-100">
        <div class="text-center">
            <h5>Pasajero</h5>
        </div>
        <div v-if="currentPax" class="pax-info">
            <div class="form-row">
                <span class="col-md-7">{{currentPax.Passenger.LastName}} {{currentPax.Passenger.FirstName}}</span>
                <span class="col-md-1"></span>
                <span class="col-md-4">{{currentPax.Booking.RecordLocator}}</span>
            </div>
            <div class="form-row" >
                <span class="col-md-7" v-if="currentPax.Passenger.Infant">
                    INF: {{currentPax.Infants[0].FirstName}} {{currentPax.Infants[0].LastName}}
                </span>
                <span class="col-md-7" v-else></span>
                <span class="col-md-1"></span>
                <span class="col-md-4">Pax: {{bookingPax}}</span>
            </div>
            <div class="form-row">
                <span class="col-7">Saldo: {{currentPax.Booking.BalanceDue}}</span>
                <span class="col-5">Tarifa: {{currentPax.Segment.ClassOfService + ' (' + currentPax.ProductClass.Name + ')'}}</span>
            </div>
            <div class="form-row">
                <span class="col-12">Fecha de Reserva: {{getLocalDate(currentPax.Booking.CreatedUTC)}}</span>
            </div>
            <div class="form-row">
                <span class="col-7">{{getCheckinStatus}}</span>
                <span class="col-5">Asiento: {{currentPax.UnitDesignator}}</span>
            </div>
            <div class="form-row">
                <span class="col-12">Fecha Nacimiento: {{getDate(currentPax.Passenger.DOB)}}</span>
            </div>
            <div class="form-row">
                <span class="col-12">SSRs: {{getSSRs}}</span>
            </div>
            <div class="form-row">
                <span class="col-12">Equipajes</span>
            </div>
            <div class="form-row" v-for="(bag,idx) of getBaggageTags(currentPax)">
                <span> {{idx+1}}. {{bag}}</span>
            </div>
        </div>
        <div v-if="currentPax" class="pax-info-buttons">
            <div class="form-row">
                <div class="col-md-6 align-self-end" v-if="this.currentPax.LiftStatus==0" >
                    <button type="button" class="btn btn-success button-checkin" :disabled="sending"
                    @click="$emit('checkin')">CHEQUEAR</button>
                </div>
                <div class="col-md-6 col-button-checkin" v-if="this.currentPax.LiftStatus==1">
                    <button type="button" class="btn btn-primary button-checkin" :disabled="sending"
                    @click="$emit('uncheck')">DES-CHEQUEAR</button>
                </div>
                <div class="col-md-6 col-button-checkin" v-if="this.currentPax.LiftStatus==1">
                    <button type="button" class="btn btn-success button-checkin" :disabled="sending"
                    @click="$emit('print')">IMPRIMIR</button>
                </div>
                <div class="col-md-6 col-button-checkin" v-if="this.currentPax.LiftStatus==1 && this.currentPax.Passenger.Infant">
                    <button type="button" class="btn btn-success button-checkin" :disabled="sending"
                    @click="$emit('printInf')">IMPRIMIR INF</button>
                </div>

                <div class="col-md-6 col-button-checkin" v-if="this.currentPax.LiftStatus==1">
                    <button type="button" class="btn btn-success button-checkin" :disabled="sending"
                    @click="$emit('board')">EMBARCAR</button>
                </div>
                <div class="col-md-6 col-button-checkin" v-if="this.currentPax.LiftStatus==2">
                    <button type="button" class="btn btn-primary button-checkin" :disabled="sending"
                    @click="$emit('unboard')">DESEMBARCAR</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/css/app.css';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
  name: 'checkinpaxinfo',
  components: {},
  props: ['currentPax', 'boarding', 'bookingPax', 'sending'],
  data  () {
    return {}
  },
  mounted () {
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    getCheckinStatus () {
        let liftstatus = {
            0: 'NO CHEQUEADO',
            1: 'CHECKIN',
            2: 'EMBARCADO',
            3: 'NO SHOW',
            4: 'ENDOSADO'
        }
        return liftstatus[this.currentPax.LiftStatus]
    },
    getSSRs () {
        let res = [];
        for (let fee of this.currentPax.SegmentFees) {
            if (res.indexOf(fee.FeeCode)==-1) {
                res.push(fee.FeeCode);
            }
        }
        for (let ssr of this.currentPax.SSRs) {
            if (res.indexOf(ssr.SSRCode)==-1) {
                res.push(ssr.SSRCode);
            }
        }
        return res.join(', ');
    },
  },
  methods: {
    getBaggageTags (pax) {
        let res = []
        for (let bag of pax.Baggages) {
            let f = _.find(bag.LegBaggages, function(b) {
                return b.SegmentID==pax.SegmentID && b.LegNumber==pax.LegNumber && b.BaggageStatus!=2;
            })
            if (f) {
                res.push(bag.OSTag + ' / ' + bag.Weight)
            }
        }
        return res;
    },
    getDate  (d) {
        return moment(d).format('DD/MM/YYYY');
    },
    getLocalDate (d) {
        return app.getLocalDate(d);
    }

  },
  watch: {
      currentPax () {
        if (this.boarding) return;
        let self = this;
        this.$nextTick(function() {
            if (self.currentPax && self.currentPax.ReturnInventorLeg && self.currentPax.ReturnInventorLeg.LegStatus=='CANCELED') {
                setTimeout(function(){
                    alert('Este pasejero tiene el regreso Cancelado');
                }, 500);

            }
        })

      }
  },

}
</script>

