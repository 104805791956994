<template>
    <div>

      <div class="container">
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control" v-model="password" name="password"  placeholder="Old Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control" v-model="newpassword" name="newpassword"  placeholder="New Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control" v-model="newpassword2" name="newpassword2"  placeholder="Re-Enter New Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <button type="button" id="submit" class="btn btn-primary" @click="change"
            :disabled="!checkNewPassword || !password">Change Password</button>
          </div>
        </div>
      </div>

    </div>
</template>



<script>
import '@/css/app.css';
import Navbar from '@/components/tools/Navbar';
import { mapState } from 'vuex';
export default {
  name: 'changepassword',
  components: {
    Navbar
  },
  data  () {
    return {
        password: '',
        newpassword: '',
        newpassword2: ''
    }
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    checkNewPassword () {
        return (this.newpassword.length>0 && this.newpassword==this.newpassword2 && this.password!=this.newpassword);
    }
  },
  methods: {
    change: async function() {
        let data = {
            newpassword: this.newpassword,
            newpassword2: this.newpassword2,
            oldpassword: this.password
        }
        let res = await app.postJSON('/change_password', data)
        if (res) {
            alert('Password modificado');
            this.$store.commit('setUser', res);
            this.$router.push('/');
        }
    }
  }
}
</script>

