<template>
    <div class="edit-by row">
        <div class="col-12" v-if="createdUserName">
            <label>Creado por: {{createdUserName}} {{getLocalDateTime(current.CreatedUTC)}}</label>
        </div>
        <div class="col-12" v-if="modifiedUserName">
            <label>Editado por: {{modifiedUserName}} {{getLocalDateTime(current.ModifiedUTC)}}</label>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'editedby',
    props: ['current'],
    data  () {
        return {
            modifiedUserName: null,
            createdUserName: null
        }
    },
    mounted: async function() {
        if (this.current.ModifiedUserID) {
            let user = await app.get('/api/user/' + this.current.ModifiedUserID)
            if (user) {
                this.modifiedUserName = user.UserName;
            }
        }
        if (this.current.CreatedUserID) {
            let user = await app.get('/api/user/' + this.current.CreatedUserID)
            if (user) {
                this.createdUserName = user.UserName;
            }
        }
    },
    methods: {
        getLocalDateTime (d) {
            return app.getLocalDateTime(d);
        }
    }
}
</script>
