<template>
    <home endpoint="currency" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'currency',
  components: {
    home,
  },
  data () {
    return {
      params: {
        fields: [
          {name: 'id', label: 'Código', required: true},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'}],
        title: 'Monedas',
        title2: 'Moneda'
      }
    }
  },
}
</script>

