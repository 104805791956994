<template>
  <div class="modal fade" id="confirmCancel" tabindex="-1" role="dialog" aria-labelledby="confirmCancelLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          Descartar modificaciones?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">No</button>
          <button type="button" class="btn btn-danger" @click="save">Si</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
export default {
    name: 'confirmcancel',
    mixins: [escape],
    methods: {
        save () {
            this.$emit('confirm');
            this.close();
        }
    }
}
</script>
