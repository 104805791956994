<template>
    <home endpoint="ssr" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
  name: 'ssr',
  components: {
    home,
  },
  data  () {
    let self = this;
    return {
      params: {
        fields: [
          {name: 'SSRCode', label: 'Código', required: true},
          {name: 'Name', label: 'Nombre', required: true},
          {name: 'Closed', label: 'Cerrado', editor: 'checkbox'}
          ],
        title: 'SSRs',
        title2: 'ssr',
        primaryKey: 'SSRCode',
      }
    }
  },
}
</script>

