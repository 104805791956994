<template>
    <div>
        <label v-if="label" :for="fieldname">{{label}}</label>
        <select v-model="field_value" :name="fieldname" :class="addClass"
        class="form-control select-input" :id="id || fieldname" :disabled="disabled" @change="$emit('change', field_value, id, fieldname)">
            <option v-if="addBlank" :value="null" :selected="null == field_value">
            </option>
            <option v-for="opt of fieldOptions[fieldname]" :value="opt.value"
            :selected="opt.value == field_value" :disabled="opt.disabled">
                {{opt.label}}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'selectinput',
    props: ['fieldname', 'fieldOptions', 'label', 'current_value', 'required', 'disabled', 'id', 'addClass',
        'addBlank', 'sort'],
    data  () {
        return {
            field_value: null,
        }
    },
    computed:{
    },
    created (){
        this.field_value = this.current_value;
    },
    mounted  () {
        this.field_value = this.current_value;
        if (this.sort) {

            this.fieldOptions[this.fieldname].sort(function compare( a, b ) {
                if ( a.label < b.label ){
                    return -1;
                }
                if ( a.label > b.label ){
                    return 1;
                }
                return 0;
            });
        }

    },
    watch: {
        field_value () {
            this.$emit('update:current_value', this.field_value);
        },
        current_value () {
            this.field_value = this.current_value;
        }
    },
    methods: {
    }
}
</script>
