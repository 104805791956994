<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="navbar-brand">
          <img src="@/img/logo3.png" width="100" height="35">
       </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item dropdown" v-if="systemMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Sistema
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <vuemenuitem endpoint="user" label="Usuarios"></vuemenuitem>
              <vuemenuitem endpoint="accessgroup" label="Perfiles de Usuario"></vuemenuitem>
              <vuemenuitem endpoint="currency" label="Monedas"></vuemenuitem>
              <vuemenuitem endpoint="location" label="Ubicación"></vuemenuitem>
              <vuemenuitem endpoint="paymentmethod" label="Formas de Pago"></vuemenuitem>
              <vuemenuitem endpoint="fee" label="Cargos"></vuemenuitem>
              <vuemenuitem endpoint="ssr" label="SSR"></vuemenuitem>
              <vuemenuitem endpoint="email" label="Configuración de Emails"></vuemenuitem>
              <vuemenuitem endpoint="organization" label="Organizacion"></vuemenuitem>
              <vuemenuitem endpoint="customer" label="Clientes"></vuemenuitem>
            </div>
          </li>

          <li class="nav-item dropdown" v-if="faresMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Tarifas
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <vuemenuitem endpoint="classofservice" label="Clases"></vuemenuitem>
              <vuemenuitem endpoint="salesconfiguration" label="Configuración"></vuemenuitem>
              <vuemenuitem endpoint="fare" label="Tarifas"></vuemenuitem>
              <vuemenuitem endpoint="faretype" label="Tipo de Tarifas"></vuemenuitem>
              <vuemenuitem endpoint="productclass" label="Clases de Productos"></vuemenuitem>
              <vuemenuitem endpoint="tax" label="Tasas e Impuestos"></vuemenuitem>
              <!--vuemenuitem endpoint="feeprice" label="Precio de Extras"></vuemenuitem-->
            </div>
          </li>

          <li class="nav-item dropdown" v-if="inventoryMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Inventario
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <vuemenuitem endpoint="station" label="Escalas"></vuemenuitem>
              <!--vuemenuitem endpoint="flight" label="Vuelos"></vuemenuitem-->
              <vuemenuitem endpoint="editflight" label="Vuelos"></vuemenuitem>
              <vuemenuitem endpoint="syncflight" label="Sincronizar Vuelos"></vuemenuitem>
              <vuemenuitem endpoint="movepassengers" label="Mover Pasajeros"></vuemenuitem>
            </div>
          </li>

          <li class="nav-item dropdown" v-if="salesMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ventas
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <vuemenuitem endpoint="booking_search" label="Reservas"></vuemenuitem>
              <!--router-link v-bind:to="'/booking'">
                  <a  class="nav-item nav-link" @click="newBooking">Nueva Reserva</a>
              </router-link-->
            </div>
          </li>

          <li class="nav-item dropdown" v-if="airportMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Aeropuerto
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <vuemenuitem endpoint="checkin" label="Checkin"></vuemenuitem>
              <a  href="#" class="nav-item nav-link" @click="configPrinter">Configurar Impresora</a>
              <vuemenuitem endpoint="flight_close" label="Cierre de Vuelo"></vuemenuitem>
            </div>
          </li>


          <li class="nav-item dropdown" v-if="reportsMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Reportes
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
                <vuemenuitem endpoint="reports/paxlist" label="Listado de Pasajeros"></vuemenuitem>
                <vuemenuitem endpoint="reports/trlist" label="Listado de Travel Rock"></vuemenuitem>
                <vuemenuitem endpoint="reports/cashsummary" label="Cierre de Caja"></vuemenuitem>
                <vuemenuitem endpoint="reports/flightload" label="Ocupación de Vuelo"></vuemenuitem>
                <vuemenuitem endpoint="reports/accountssummary" label="Cuenta Corriente"></vuemenuitem>
                <vuemenuitem endpoint="reports/salesbyclassmarket" label="Ventas por Clase/Segmento"></vuemenuitem>
                <vuemenuitem endpoint="reports/salesbypaymentmethod" label="Ventas por Medio de Pago"></vuemenuitem>
                <vuemenuitem endpoint="reports/baglist" label="Listado de Equipajes"></vuemenuitem>
                <vuemenuitem endpoint="reports/creditfile" label="Credit File"></vuemenuitem>
                <vuemenuitem endpoint="reports/userhistory" label="Historia de Usuario"></vuemenuitem>
                <vuemenuitem endpoint="reports/salesbylocation" label="Ventas Totales por Ubicación"></vuemenuitem>
                <vuemenuitem endpoint="reports/salesbylocationpayment" label="Ventas por Ubicación/Medio de Pago"></vuemenuitem>
                <vuemenuitem endpoint="reports/ticketsdetail" label="Detalle de Tickets"></vuemenuitem>
            </div>
          </li>

          <!--li class="nav-item dropdown" v-if="routinesMenu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Procesos
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            </div>
          </li-->


        </ul>

        <ul class="nav navbar-nav navbar-right">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{user.UserName}}
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#" @click="logout">Salir</a>
              <a class="dropdown-item" href="#" @click="$router.push('/changepassword')">Cambiar Contraseña</a>
              <a class="dropdown-item" href="#" @click="showSettings">Opciones</a>
              <a  href="#" class="dropdown-item" @click="openDevTools">Dev Tools</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
        <selectvalue v-if="printerList" @save="setPrinter" @close="closeSelectPrinter"
          text="Elegir Impresora" :fieldOptions="printerList"></selectvalue>
        <settingswindow v-if="settings" @close="closeSettings"></settingswindow>
        <inputvalue v-if="showinputvalue" @save="setPrinterByName" @close="showinputvalue = false" text="Ingresar Nombre de Impresora"
            :default="printerName"></inputvalue>


  </div>
</template>

<script>
import Vuemenuitem from '@/components/tools/Vuemenuitem'
import recordwindow from '@/components/tools/RecordWindow';
import selectvalue from '@/components/tools/SelectValue';
import settingswindow from '@/components/tools/SettingsWindow';
import inputvalue from '@/components/tools/InputValue';
import { mapState } from 'vuex';
export default {
    name: 'navbar',
    components: {
      Vuemenuitem,
      recordwindow,
      selectvalue,
      settingswindow,
      inputvalue
    },
    data  () {
        return {
            systemMenu: false,
            faresMenu: false,
            inventoryMenu: false,
            reportsMenu: false,
            routinesMenu: false,
            salesMenu: false,
            airportMenu: false,
            profile: false,
            servicesMenu: false,
            printerList: null,
            electron: null,
            printDirect: null,
            showinputvalue: false,
            printerName: null,
            settings: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted: async function () {
        window.navbar = this
        this.printDirect = localStorage.getItem("printDirect");
        this.printerName = localStorage.getItem("printerName");
        this.systemMenu = app.canAccess(this.user, 'view','GET', '/system_menu/')
        this.faresMenu = app.canAccess(this.user, 'view','GET', '/fares_menu/')
        this.inventoryMenu = app.canAccess(this.user, 'view','GET', '/inventory_menu/')
        this.salesMenu = app.canAccess(this.user, 'view','GET', '/sales_menu/')
        this.reportsMenu = app.canAccess(this.user, 'view','GET', '/reports_menu/')
        this.routinesMenu = app.canAccess(this.user, 'view','GET', '/routines_menu/')
        this.airportMenu = app.canAccess(this.user, 'view','GET', '/airport_menu/')
    },
    methods: {
        logout: async function() {
            let res = await app.get('/logout');
            if (res) {
              this.$store.commit('setUser', null);
              this.$router.push('/login');
            }
        },
        updateMe (record) {
            this.$store.commit('setUser', record);
        },
        newBooking () {
            if (this.$router.currentRoute=='booking') return;
            let booking = app.newBooking();
            this.$store.commit('setBooking', booking);
            this.$router.push({ name: 'booking'})
        },
        configPrinter: async function() {
            if (this.printDirect=='2') {
              this.showinputvalue = true;
              this.$nextTick(function() {
                $('#inputValue').modal('show');
              })

            } else {
                try {
                    this.electron = window.require("electron");
                } catch (e) {
                    alert('Opción no disponible para versión web');
                    return;
                }
                let printerList = await this.getPrinterList();
                if (printerList) {
                    let res = [];
                    for (let c of printerList) {
                      res.push({label: c.name, value: c.name});
                    }
                    this.printerList = res;
                    this.$nextTick(function() {
                      $('#selectValue').modal('show');
                    })
                }

            }
        },
        getPrinterList: async function() {
            return await app.getPrinterList(this.electron);
        },
        setPrinter (printerName) {
            this.printerList = null;
            $('#selectValue').modal('hide');
            this.setCurrentPrinter(printerName)
        },
        setPrinterByName (printerName) {
            this.showinputvalue = null;
            $('#inputValue').modal('hide');
            localStorage.setItem("printerName", printerName);
        },

        openDevTools () {
            try {
                this.electron = window.require("electron");
            } catch (e) {
                return;
            }
            app.openDevTools(this.electron)
        },
        closeSelectPrinter () {
            this.printerList = null;
            $('#selectValue').modal('hide');
        },
        setCurrentPrinter: async function(printerName) {
            let res = await app.setCurrentPrinter(this.electron, printerName)
            if (res) {
                console.log(store)
                alert(store)
            }
        },
        showSettings () {
            this.settings = true;
            this.$nextTick(function() {
                $('#settings-window').modal('show');
            });
        },
        closeSettings () {
            this.settings = false;
            $('#settings-window').modal('hide');
        },


    }
}
</script>


