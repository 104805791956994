<template>
    <div class="my-2">
        <div class="col-12 mb-2">
          Tarifas Faltantes
        </div>
        <table class="table table-striped table-bordered">
            <thead>
                <tr class="table-secondary">
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Clase</th>
                    <th>Desde</th>
                    <th>Hasta</th>
                    <th>Moneda</th>
                    <th>Monto</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(f, i) of fares">
                    <td v-if="f.type=='0'">{{f.row.DepartureStation}}</td>
                    <td v-else>{{f.row.ArrivalStation}}</td>
                    <td v-if="f.type=='0'">{{f.row.ArrivalStation}}</td>
                    <td v-else>{{f.row.DepartureStation}}</td>
                    <td>{{f.ClassOfService}}</td>
                    <td>
                        <datepicker :id="'StartDate-'+ i" :def.sync="f.StartDate"></datepicker>
                    </td>
                    <td>
                        <datepicker :id="'EndDate-'+ i" :def.sync="f.EndDate"></datepicker>
                    </td>
                    <td>
                        <selectinput fieldname="CurrencyCode" :fieldOptions="fieldOptions"
                        :current_value.sync="f.CurrencyCode"></selectinput>
                    </td>
                    <td>
                        <input v-model="f.Amount"  class="form-control" type="number">
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="create(f)" v-if="!f.id"
                            :disabled="!f.Amount || !f.CurrencyCode || !f.EndDate || !f.StartDate || processing">
                            Crear
                        </button>
                        <label v-else>OK</label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
var moment = require('moment');
import datepicker from '@/components/tools/DatePicker';
import selectinput from '@/components/tools/SelectInput';

export default {
    name: 'flight-fares',
    props: ['record', 'tabClass'],
    components: {
      datepicker,
      selectinput
    },
    data () {
        return {
            fares: [],
            fieldOptions: {
                CurrencyCode: [
                    { label: 'ARS', value: 'ARS'},
                    { label: 'USD', value: 'USD'},
                ]
            },
            processing: false,
        }
    },
    async mounted () {
        this.getFares();
    },
    methods: {
        refresh (id, fieldname, rowfieldname, rownr) {
            if (fieldname == 'InventoryLegs') {
                if (rowfieldname == 'DepartureStation' || rowfieldname == 'ArrivalStation' || rowfieldname == 'SalesConfigurationId') {
                    this.getFares()
                }
            }
            if (fieldname == 'FlightDate' || fieldname=='FromDate' || fieldname=='ToDate') {
                this.getFares()
            }
        },
        async getFares () {
            let rows = [];
            for (let row of this.record.InventoryLegs) {
                if (row.DepartureStation == row.ArrivalStation) continue;
                if (row.DepartureStation && row.ArrivalStation && row.SalesConfigurationId
                    && (this.record.FlightDate || this.record.FromDate || this.record.ToDate)) {
                    rows.push(row)
                }
            }
            if (rows.length) {
                let data = {
                    FlightDate: this.record.FlightDate,
                    FromDate: this.record.FromDate,
                    ToDate: this.record.ToDate,
                    rows
                }
                let res = await app.get('/api/flight/get_fares', {data: JSON.stringify(data)});
                if (res) {
                    if (res.length>0) {
                        this.record.invalid.FlightFares = true;
                        //tabClass.FlightFares = 'tab-alert';
                        this.$emit('update:record', Object.assign({}, this.record));
                    } else {
                        this.record.invalid.FlightFares = false;
                        //this.$emit('update:tabClass', '')
                        this.$emit('update:record', Object.assign({}, this.record));
                    }
                    this.fares = res;
                }
            }
        },
        getDate (d) {
            if (!d) return '';
            return moment(d).format('DD/MM/YYYY');
        },
        async create (f) {
            this.processing = true;
            let res = await app.postJSON('/api/flight/create_fares', f, this);
            if (res) {
                if (res.id) {
                    f.id = true;
                }
                this.processing = false;
            }

        }
    }
}
</script>

<style scoped>
td, th {
    padding: 2px !important;
    min-width: 100px !important;
    max-width: 100px !important;
    width: 100px !important;
}
.table {
    width: auto !important;
}
</style>