<template>
      <div class="modal-content">
        <div class="modal-header">

          <div class="form-row">
              <div class="col-md-2 form-row">
                  <div class="col-md-8">
                      <label for="FromDate" v-if="!move">Fecha Ida</label>
                      <label for="FromDate" v-else>Fecha</label>
                      <datepicker id="DepartureDate" :def.sync="DepartureDate"></datepicker>
                  </div>
                  <div class="col-md-4">
                      <label for="DepartureDaysOut">Días</label>
                      <input v-model="DepartureDaysOut" class="form-control" type="number">
                  </div>
              </div>
              <div class="col-md-2 form-row">
                  <div class="col-md-8">
                      <label for="ToDate">Fecha Vuelta</label>
                      <datepicker id="ReturnDate" :readonly="move" :def.sync="ReturnDate"></datepicker>
                  </div>
                  <div class="col-md-4">
                      <label for="ReturnDaysOut">Días</label>
                      <input v-model="ReturnDaysOut" class="form-control" type="number">
                  </div>
              </div>
              <div class="col-md-1">
                  <selectinput fieldname="DepartureStation" :fieldOptions="fieldOptions" label="Origen"
                    :current_value.sync="DepartureStation" id="DepartureStation" :disabled="move" addClass="station-select">
                    </selectinput>
              </div>
              <div class="col-md-1">
                  <selectinput fieldname="ArrivalStation" :fieldOptions="fieldOptions" label="Destino"
                    :current_value.sync="ArrivalStation" id="ArrivalStation" :disabled="move" class="station-select">
                    </selectinput>
              </div>
              <div class="col-md-1">
                  <label>ADT</label>
                  <input v-model="ADT" class="form-control" type="text" :readonly="booking.Passengers && booking.Passengers.length>0">
              </div>
              <div class="col-md-1">
                  <label>CHD</label>
                  <input v-model="CHD" class="form-control" type="text" :readonly="booking.Passengers && booking.Passengers.length>0">
              </div>
              <div class="col-md-2">
                  <button class="btn btn-primary button-search" :disabled="!dataCompleted" @click="search(false)">Buscar</button>
              </div>
              <div class="col-md-2">
                  <button class="btn btn-primary button-search" :disabled="!dataCompleted || !canOverBook" @click="search(true)">Overbook</button>
              </div>
          </div>


        </div>
        <div class="modal-body">
            <div v-if="processing" class="loading-div  search-body">
                <img class="loading-gif" src="@/img/loading.gif">
            </div>
            <div class="form-row" v-if="departureFlights && !processing">
                <label v-if="overbook" class="col-md-12 overbook text-center"> ****** Overbook ******</label>
                <label v-if="returnFlights">IDA</label>
                <div v-for="(date,d) of departureFlights" class="col-md-12">
                    <label>{{DepartureStation}} {{ArrivalStation}} {{getDate(d)}}</label>
                    <br>
                    <div class="col-md-12 flight-result" v-for="(f,fnumber) of date">
                        <span @click="showFares(f.FlightID)" class="show-fares" v-if="!faresByFlight[f.FlightID] && getFligtStatusClass(f)!='CANCELED'"> + </span>
                        <span @click="hideFares(f.FlightID)" class="show-fares" v-else> - </span>
                        {{getFligtData(fnumber, f)}} <span :class="getFligtStatusClass(f)">{{flightStatus[getFligtStatus(f)]}}</span>
                        <div class="form-row flight-result-fares" v-if="faresByFlight[f.FlightID]">
                            <div class="col-md-11 offset-md-1" v-for="fare of f.Fares" @click="selectFareDeparture(d, fnumber, f, fare)" :class="isSelectedDepartureFare(fare.SalesKey)" >
                                {{getFareFromat(fare)}}
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                </div>
                <div class="col-md-12 flight-result" v-if="Object.keys(departureFlights).length==0">
                    NO HAY VUELOS DISPONIBLES
                </div>
            </div>
            <div class="spacer"></div>
            <div class="form-row" v-if="returnFlights && !processing">
                <label>VUELTA</label>
                <div v-for="(date,d) of returnFlights" class="col-md-12">
                    <label>{{ArrivalStation}} {{DepartureStation}} {{getDate(d)}}</label>
                    <br>
                    <div class="col-md-12 flight-result" v-for="(f,fnumber) of date">
                        <span @click="showFares(f.FlightID)" class="show-fares" v-if="!faresByFlight[f.FlightID] && getFligtStatusClass(f)!='CANCELED'"> + </span>
                        <span @click="hideFares(f.FlightID)" class="show-fares" v-else> - </span>
                        {{getFligtData(fnumber, f)}} <span :class="getFligtStatusClass(f)"> {{flightStatus[getFligtStatus(f)]}}</span>
                        <div class="form-row flight-result-fares" v-if="faresByFlight[f.FlightID]">
                            <div class="col-md-12 offset-md-1" v-for="fare of f.Fares" @click="selectFareReturn(d, fnumber, f, fare)" :class="isSelectedReturnFare(fare.SalesKey)" >
                                {{getFareFromat(fare)}}
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                </div>
                <div class="col-md-12 flight-result" v-if="Object.keys(returnFlights).length==0">
                    NO HAY VUELOS DISPONIBLES
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save"
            :disabled="!this.selectedSegmentDeparture || !this.selectedFareDeparture">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
import selectinput from '@/components/tools/SelectInput'
import datepicker from '@/components/tools/DatePicker'
export default {
  name: 'flightselect',
  props: ['move', 'flightkey'],
  components: {
    datepicker,
    selectinput
  },
  data  () {
    return {
        classofservice: null,
        processing: false,
        DepartureDate: moment(new Date()).format('YYYY-MM-DD'),
        DepartureDaysOut: 0,
        ReturnDate: null,
        ReturnDaysOut: 0,
        DepartureStation: null,
        ArrivalStation: null,
        departureFlights: null,
        returnFlights: null,
        faresByFlight: {},
        selectedFareDeparture: null,
        selectedFareReturn: null,
        selectedSegmentDeparture: null,
        selectedSegmentReturn: null,
        overbook: false,
        serviceFee: null,
        canOverBook: false,
        classes: null,
        requests: 0,
        ADT: 1,
        CHD: 0,
        INF: 0,
        flightStatus: {
            'CONFIRMED': 'CONFIRMADO',
            'CANCELED': 'CANCELADO',
            'SUSPENDED': 'CERRADO A LA VENTA',

        }

    }
  },
  mounted: async function() {
    this.canOverBook = app.canAccess(this.user, 'api','GET', 'overbook_class', false) || app.canAccess(this.user, 'api','GET', 'overbook_leg', false);
    this.classes = await app.get('/api/classofservice/')
    if (this.move) {
        let segment = _.find(this.booking.Passengers[0].Segments, (s) => s.SegmentFlightKey==this.flightkey);
        if (segment) {
            this.classofservice = segment.ClassOfService;
            this.DepartureStation = segment.DepartureStation;
            this.ArrivalStation = segment.ArrivalStation;
            this.DepartureDate = segment.DepartureDate;
        }
    }
    this.serviceFee = await app.get('/api/fee/SF');
    let adt = _.filter(this.booking.Passengers, (p)=>p.PaxType=='ADT').length;
    if (adt) this.ADT = adt;
    let chd = _.filter(this.booking.Passengers, (p)=>p.PaxType=='CHD').length;
    if (chd) this.CHD = chd;
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
        stations: state => state.stations,
    }),
    dataCompleted  (){
        if (!this.DepartureStation) return false;
        if (!this.ArrivalStation) return false;
        if (!this.DepartureDate) return false;
        return true;
    },
    fieldOptions  (){
        let stationList = [];
        for (let station of this.stations) {
            stationList.push({label: station.id, value: station.id})
        }
        return {DepartureStation: stationList, ArrivalStation: stationList};
    }
  },
  methods: {
    search: async function(overbook) {
        if (overbook && !this.canOverBook) {
            alert('Sin permiso para esta acción');
            return;
        }
        this.overbook = overbook;
        this.processing = true;
        this.selectedFareDeparture = null;
        this.selectedFareReturn = null;
        this.selectedSegmentDeparture = null;
        this.selectedSegmentReturn = null;
        let adt = this.ADT;
        let chd = this.CHD;
        let inf = this.INF;
        if (this.booking.BookingID) {
            adt = _.filter(this.booking.Passengers, (p) => ['ADT', 'CBUS', 'NREV'].indexOf(p.PaxType)>-1 );
            adt = adt.length;
            chd = _.filter(this.booking.Passengers, (p) => p.PaxType=='CHD');
            chd = chd.length;
            inf = _.filter(this.booking.Passengers, (p) => p.Infant==true);
            inf = inf.length;
        }
        let data = {
            DepartureDateStart: this.DepartureDate,
            DepartureDateEnd: moment(this.DepartureDate).add(this.DepartureDaysOut, 'days').format('YYYY-MM-DD'),
            DepartureStation: this.DepartureStation,
            ArrivalStation: this.ArrivalStation,
            ADT: adt,
            CHD: chd,
            INF: inf,
            ClassQuantity: 100,
        };
        if (overbook) {
            data.Overbook = true;
        }
        if (!this.move) {
            data.ReturnDateStart = this.ReturnDate;
            data.ReturnDateEnd = moment(this.ReturnDate).add(this.ReturnDaysOut, 'days').format('YYYY-MM-DD');
        }
        if (this.move) {
            data.ClassOfService = this.classofservice;
            data.Move = true;
        }
        let res = await app.get('/api/booking/get_availability3_period', data);
        if (res) {
            this.departureFlights = res.departure_flight;
            for (let date in this.departureFlights) {
                for (let flightNr in this.departureFlights[date]) {
                    let fareClass = _.find(this.departureFlights[date][flightNr].Fares, (f)=>f.ClassOfService==this.classofservice);
                    if (this.move) {
                        this.departureFlights[date][flightNr].Fares = _.filter(this.departureFlights[date][flightNr].Fares, function(f) {
                            return parseFloat(f.Amount)>=parseFloat(fareClass.Amount);
                        })
                    }
                    this.departureFlights[date][flightNr].Fares.sort(this.compare);
                }

            }
            this.returnFlights = res.return_flight;
            for (let date in this.returnFlights) {
                for (let flightNr in this.returnFlights[date]) {
                    this.returnFlights[date][flightNr].Fares.sort(this.compare);
                }
            }
            this.processing = false;

        }
    },
    getFligtData (fnumber, f){
        return fnumber + ', ' + app.getDate(f.FlightDate) + '  ' + f.STDTime + ' / ' + f.STATime
         + ' DISP: ' + f.Availability;
    },
    getFligtStatus (f){
         return f.FlightStatus;
    },
    getFligtStatusClass (f){
         if (f.FlightStatus!='CONFIRMED') {
            return 'flight-canceled';
         }
         return '';
    },

    getDate (d){
        return app.getDate(d);
    },
    showFares (flightId) {
        this.faresByFlight[flightId] = true;
        this.faresByFlight = Object.assign({}, this.faresByFlight);
    },
    hideFares (flightId) {
        this.faresByFlight[flightId] = false;
        this.faresByFlight = Object.assign({}, this.faresByFlight);
    },
    getFareFromat (fare) {
        return fare.ClassOfService + ': ' + fare.Currency + ' ' + fare.Amount + ' ' + this.getTotal(fare)
        + ' (' + fare.Product + ') '
        + '; DISP: ' + fare.Lid;
    },
    getTotal (fare) {
        let t = parseFloat(fare.Amount);
        t += parseFloat(fare.Charges.Total);
        t += parseFloat(fare.Taxes.Total);
        t = Math.round(t * 100) / 100;
        return t;
    },
    selectFareDeparture (date, fnumber, f, fare) {
        let t = parseFloat(fare.Amount);
        t += parseFloat(fare.Charges.Total);
        t += parseFloat(fare.Taxes.Total);
        this.selectedFareDeparture = fare;
        this.selectedSegmentDeparture = this.departureFlights[date][fnumber];
        this.selectedSegmentDeparture.Availability = f.Availability;
    },
    selectFareReturn (date, fnumber, f, fare) {
        let t = parseFloat(fare.Amount);
        t += parseFloat(fare.Charges.Total);
        t += parseFloat(fare.Taxes.Total);
        this.selectedFareReturn = fare;
        this.selectedSegmentReturn = this.returnFlights[date][fnumber];
        this.selectedSegmentReturn.Availability = f.Availability;
    },
    isSelectedDepartureFare (SalesKey) {
        if (!this.selectedFareDeparture) return "";
        if (SalesKey==this.selectedFareDeparture.SalesKey) return "selected-fare";
        return ""
    },
    isSelectedReturnFare (SalesKey) {
        if (!this.selectedFareReturn) return "";
        if (SalesKey==this.selectedFareReturn.SalesKey) return "selected-fare";
        return ""
    },

    addPasssengers () {
        for (let i=0; i<this.ADT; i++) {
            this.addPasssenger('ADT');
        }
        for (let i=0; i<this.CHD; i++) {
            this.addPasssenger('CHD');
        }
    },
    addPasssenger (paxType) {
        let p = {
            Baggages: [],
            DOB: null,
            DocNumber: null,
            DocTypeCode: null,
            Fees: [],
            FirstName: null,
            MiddleName: null,
            LastName: null,
            Gender: null,
            Infant: false,
            Infants: [],
            Natinality: null,
            PaxType: paxType,
            Segments: []
        }
        if (this.serviceFee) {
            let serviceFee = {
                FeeCode: this.serviceFee.FeeCode,
                FeeType: this.serviceFee.FeeType,
                FeeOverride: false,
                isNew: true,
                Status: 'HK',
                FeeNumber: 1,
                Charges: [{
                    ChargeNumber: 1,
                    FeeNumber: 1,
                    ChargeCode: this.serviceFee.FeeCode,
                    TicketCode: this.serviceFee.FeeCode,
                    ChargeType: 6,
                    CurrencyCode: 'ARS',
                    ForeignCurrencyCode: 'ARS',
                    ChargeAmount: null,
                    ForeignAmount: null,
                    ChargeDetail: this.serviceFee.Name,
                }]
            }
            if (this.serviceFee.BasePrice) {
                serviceFee.Charges[0].ChargeAmount = this.serviceFee.BasePrice;
                serviceFee.Charges[0].ForeignAmount = this.serviceFee.ForeignAmount;
            }
            p.Fees.push(serviceFee);
        }
        this.booking.Passengers.push(p);


    },
    save () {
        if (this.booking.Passengers.length==0) {
            this.addPasssengers();
        }
        if (!this.selectedSegmentDeparture || !this.selectedFareDeparture) return;
        for (let p in this.booking.Passengers) {
            if (this.move) {
                this.moveSegmentToPassenger(p, this.selectedSegmentDeparture);
            } else {
                this.addSegmentToPassenger(p, this.selectedSegmentDeparture, this.selectedFareDeparture);
            }
        }
        if (this.selectedSegmentReturn && this.selectedFareReturn) {
            for (let p in this.booking.Passengers) {
                this.addSegmentToPassenger(p, this.selectedSegmentReturn, this.selectedFareReturn);
            }
        }
        //this.$emit("update:booking", Object.assign({}, this.booking));
    },

    addSegmentToPassenger: async function(p, segment, fare){
        this.requests += 1;
        let s = {};
        let SegmentFlightKey = segment.FlightDate + '_' + segment.DepartureStation + '_' + segment.ArrivalStation;
        s.DepartureDate = segment.FlightDate;
        s.DepartureStation = segment.DepartureStation;
        s.ArrivalStation = segment.ArrivalStation;
        s.ClassOfService = fare.ClassOfService;
        s.ProductClassCode = fare.ProductClassId;
        s.ProductClass = {id: fare.ProductClassId, Name: fare.Product}
        s.OverbookIndicator = '';
        if (this.overbook) {
            if (fare.Lid < 0) s.OverbookIndicator = 'S';
            if (segment.Availability<=0) s.OverbookIndicator = 'O';
        }
        s.Legs = [];
        s.Charges = [];
        s.Fees = [];
        let legs = segment.LegIDs.split(',');
        for (let legId of legs) {
            let inventoryLeg = await app.get('/api/inventoryleg/' + legId);
            if (inventoryLeg) {
                let leg = {
                    LegNumber: s.Legs.length + 1,
                    InventoryLegID: inventoryLeg.InventoryLegID,
                    InventoryLeg: inventoryLeg,
                    LiftStatus: 0
                }
                s.Legs.push(leg);
                SegmentFlightKey = SegmentFlightKey + '_' + inventoryLeg.FlightNumber;
            }
        }
        s.SegmentFlightKey = SegmentFlightKey;
        this.addChargeToSegment(s, fare.Currency, fare.Amount);
        let classOfService = _.find(this.classes, (c)=> c.id==s.ClassOfService)
        if (!classOfService || !classOfService.NonTaxes) {
            await this.addFeeAndTaxes(s, fare.Currency, fare.Amount);
        }
        this.moveFeeFromPaxToSegment(p, s);
        this.booking.Passengers[p].Segments.push(s)
        this.booking.Passengers[p].Segments.sort(this.compareDates)
        app.bookingSumUp(this.booking);
        this.requests += -1;
    },
    moveFeeFromPaxToSegment(p, s) {
        let toRemove = [];
        for (let i in this.booking.Passengers[p].Fees) {
            let fee = this.booking.Passengers[p].Fees[i];
            if (fee.DepartureStation==s.DepartureStation && fee.ArrivalStation==s.ArrivalStation) {
                toRemove.push(parseInt(i));
                let newFee =  {
                    FeeCode: fee.FeeCode,
                    FeeType: fee.FeeType,
                    FeeOverride: fee.FeeOverride,
                    Status: fee.Status,
                    Charges: [{
                        ChargeNumber: 1,
                        ChargeCode: fee.Charges[0].ChargeCode,
                        TicketCode: fee.Charges[0].TicketCode,
                        ChargeType: fee.Charges[0].ChargeType,
                        CurrencyCode: fee.Charges[0].CurrencyCode,
                        ForeignCurrencyCode: fee.Charges[0].ForeignCurrencyCode,
                        ChargeAmount: fee.Charges[0].ChargeAmount,
                        ForeignAmount: fee.Charges[0].ForeignAmount,
                        ChargeDetail: fee.Charges[0].ChargeDetail,
                    }]
                }
                s.Fees.push(newFee);
            }
        }
        toRemove.sort().reverse();
        for (let i of toRemove) {
            this.booking.Passengers[p].Fees.splice(i, 1);
        }
    },
    getMaxLegNumber () {

    },
    moveSegmentToPassenger: async function(p, segment){
        this.requests += 1;
        let s = _.find(this.booking.Passengers[p].Segments, (s) => s.SegmentFlightKey==this.flightkey);
        let self = this;
        if (!s) {
            s = _.find(this.booking.Passengers[p].Segments, function(s) {
                if (s.Legs.length==0 && s.DepartureStation==segment.DepartureStation && s.ArrivalStation==s.ArrivalStation) {
                        let sDate = self.flightkey.split('_')[0];
                        if (sDate==s.DepartureDate) {
                            return true;
                        }
                    }
            });
        }
        if (s) {
            for (let leg of s.Legs){
                if (leg.LiftStatus!=0) {
                    return;
                }
            }
        }
        let comment = 'MOVE: from ' + app.getSegmentData(s, false) + ' to ' ;

        let SegmentFlightKey = segment.FlightDate + '_' + segment.DepartureStation + '_' + segment.ArrivalStation;
        s.DepartureDate = segment.FlightDate;
        s.OverbookIndicator = '';
        if (this.overbook) {
            //if (fare.Lid < 0) s.OverbookIndicator = 'S';
            if (segment.Availability) s.OverbookIndicator = 'O';
        }

        let ssrs;
        let legs = segment.LegIDs.split(',');
        if (s.Legs.length>0) {
            ssrs = s.Legs[0].SSRs;
        }
        let movedToFlightNr;
        let movedToDate;
        s.Legs = [];
        for (let i in legs) {
            let legId = legs[i];
            let inventoryLeg = await app.get('/api/inventoryleg/' + legId);
            if (inventoryLeg) {
                movedToFlightNr = inventoryLeg.FlightNumber;
                movedToDate = moment(inventoryLeg.STD).locale('es').format('dddd DD MMM');
                let leg = {
                    SegmentID: s.SegmentID,
                    LegNumber: s.Legs.length + 1,
                    InventoryLegID: inventoryLeg.InventoryLegID,
                    InventoryLeg: inventoryLeg,
                    LiftStatus: 0,
                    UnitDesignator: null,
                    BoardingSequence: 0,
                    SSRs: this.copySSRs(ssrs, s.Legs.length + 1, s.SegmentID),
                }
                s.Legs.push(leg);
                if (i==0) SegmentFlightKey = SegmentFlightKey + '_' + inventoryLeg.FlightNumber;
            }
        }
        s.SegmentFlightKey = SegmentFlightKey;
        comment += app.getSegmentData(s, false);
        if (p==0) {
            let c = {
                CommentText: comment,
                CreatedUserCode: this.user.UserName,
                CommentType: 0
            }
            this.booking.Comments.push(c)
        }
        this.requests += -1;

        //app.bookingSumUp(this.booking);
    },
    copySSRs  (ssrs, legNr, segmentID) {
        let res = [];
        for (let i in ssrs) {
            let ssr = ssrs[i];
            let r = {
                SegmentID: segmentID,
                LegNumber: legNr,
                SSRCode: ssr.SSRCode,
                SSRNumber: ssr.SSRNumber,
                CreatedUserID: ssr.CreatedUserID,
                CreatedUTC: ssr.CreatedUTC,
                ModifiedUserID: ssr.ModifiedUserID,
                ModifiedUTC: ssr.ModifiedUTC,
                SSRDetail: ssr.SSRDetail,
                FeeCode: ssr.FeeCode,
                UnitValue: ssr.UnitValue,
                Note: ssr.Note,
                Status: ssr.Status
            }
            res.push(r);
        }
        return res;
    },
    addChargeToSegment (segment, currency, fare){
        let c = {};
        c.ChargeNumber = segment.Charges.length + 1;
        c.CurrencyCode = currency;
        c.ChargeAmount = fare;
        c.ForeignCurrencyCode = currency;
        c.ForeignAmount = fare
        segment.Charges.push(c)
    },
    addFeeAndTaxes: async function(segment, currency, fare) {
        this.requests += 1;
        let data = {
            DepartureDate: segment.DepartureDate,
            DepartureStation: segment.DepartureStation
        }
        let tax_settings = await app.get('/api/booking/get_tax_settings', data);
        if (tax_settings) {
            for (let fee_code in tax_settings.Fees){
                let c = {};
                c.ChargeNumber = segment.Charges.length + 1;
                c.CurrencyCode = currency;
                c.ChargeAmount = fare * tax_settings.Fees[fee_code];
                c.ForeignCurrencyCode = currency;
                c.ForeignAmount = fare * tax_settings.Fees[fee_code];
                c.ChargeCode = fee_code;
                c.TicketCode = fee_code;
                segment.Charges.push(c)
            }

            for (let tax_code in tax_settings.Taxes){
                let c = {};
                c.ChargeNumber = segment.Charges.length + 1;
                c.CurrencyCode = currency;
                c.ChargeAmount = tax_settings.Taxes[tax_code];
                c.ForeignCurrencyCode = currency;
                c.ForeignAmount = tax_settings.Taxes[tax_code];
                c.ChargeCode = tax_code;
                c.TicketCode = tax_code;
                segment.Charges.push(c);
            }
        }
        this.requests += -1;
    },
    compare (a,b) {
      if (parseFloat(a.Amount) < parseFloat(b.Amount))
        return -1;
      if (parseFloat(a.Amount) > parseFloat(b.Amount))
        return 1;
      return 0;
    },
    compareDates (a,b) {
      if (a.DepartureDate < b.DepartureDate)
        return -1;
      if (a.Amount > b.Amount)
        return 1;
      return 0;
    }

  },
  watch: {
    requests: {
        handler (newValue ,oldValue) {
            if (newValue == 0 && oldValue > 0) {
                this.$store.commit('setProcessing', false);
                this.$emit('close');
            }
            if (newValue > 0) {
                this.$store.commit('setProcessing', true);
            }
        },
    },
  }
}
</script>

