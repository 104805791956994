<template>
  <div class="modal fade printable" id="boarding-pass" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog ticket-modal" role="document">
      <div class="modal-content">
        <div class="modal-body itinerary-body ticket-print">
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div v-for="row of rows" class="col-12 row">
              <div class="col-12"  :class="getRowClass(row)" v-if="!row.barcode">
                <span>{{row.value}}</span>
              </div>
                <img v-else id="barcode" class="boarding-barcode"/>
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@media print {
    .ticket-modal{
        size: auto;
        width: 80%;
        min-width: 400px;
        max-width: 400px;
    }
}
</style>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
var JsBarcode = require('jsbarcode');
export default {
  name: 'boarding-pass',
  props: ['rows'],
  components: {
  },
  data  () {
    return {
        printDirect: false,
    }
  },
  mounted: async function() {
    let self = this;
    this.printDirect = localStorage.getItem("printDirect");
    app.addEscapeKey(this, '#boarding-pass');
    this.$nextTick(function() {
        $('.modal.printable').on('shown.bs.modal', function () {
            $('.modal-dialog', this).addClass('focused');
            $('body').addClass('modalprinter');

        }).on('hidden.bs.modal', function () {
            $('.modal-dialog', this).removeClass('focused');
            $('body').removeClass('modalprinter');
        });
        for (let row of this.rows) {
            if (row.barcode) {
                let value = row.value.split(':').join('').split('-').join('');
                JsBarcode("#barcode", value, {
                   width: 1,
                   height: 20,
                   displayValue: false
                });
            }
        }
        if (self.printDirect=='1') {
            setTimeout(function(){
                self.print();
            }, 500);
        }
    });
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    print () {
        window.print();
        if (this.printDirect=='1') {
            let self = this;
            setTimeout(function(){
                self.$emit('close');
            }, 500);
        }
    },
    getRowClass (row) {
        let res = '';
        if (row.align && row.align=='center') res += 'text-center ';
        let fontSize = 'ticket-font-size-2';
        if (row.size) {
            if (row.size.height && row.size.height==1) fontSize = 'ticket-font-size-' + row.size.height;
        }
        res += fontSize;
        return res;
    },
  },
}
</script>

