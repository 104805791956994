<template>
  <div class="modal fade printable" id="itinerary" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" :class="getPrinterFormat()" role="document">
      <div class="modal-content">
        <div class="modal-body itinerary-body">
            <div class="row" v-if="printerType!='ticket'">
                <div class="col-12" align="center"><img src="https://www.andesonline.com/wp-content/themes/andes/images/logo.png" class="company-logo"></div>
            </div>
            <div class="spacer"></div>
            <div class="row">
                <div class="col-12 bg-secondary" height="20">Detalle de Billetes</div>
            </div>
            <div class="spacer"></div>
            <div class="row">
                <div class="col-12">
                  Gracias por elegir <b>Andes Líneas Aéreas</b>, le deseamos un excelente vuelo.
                </div>
                <div class="col-12 text-justify">
                  <br/>
                  Este itinerario no es válido como tarjeta de embarque.
                  Recuerde realizar el web Check-In desde 48  hasta 3 horas antes de la salida de vuelo, ya que en aeropuerto tiene un costo adicional. </br>
                  <br/>
                  En el aeropuerto de Jujuy deberá presentarse con una hora y media de anticipación. Para los otros aeropuertos la anticipación es de dos horas.
                  Deberá presentarse en el aeropuerto con dos horas de anticipación.
                  El  Check-In de aeropuerto <b>finaliza 45 minutos antes del horario programado de partida del vuelo</b>,
                  los pasajeros que lleguen  al mostrador pasado ese horario <b>NO</b> serán aceptados.
                  <i>EN CASO DE NO PRESENTACIÓN  AL VUELO SIN PREVIO AVISO SE CANCELA LA TOTALIDAD DEL PASAJE.</i>
                  Recuerde que el equipaje permitido de mano es de <b>
                  5 Kg. Consulte condiciones de tarifas y equipaje en nuestra página web </b>.
                </div>
            </div>
            <div class="spacer"></div>
            <hr>
            <div class="spacer"></div>
            <div class="row">
                <div class="col-12"><b>Código de la Reserva:</b> <span>{{booking.RecordLocator}}</span></div>
            </div>
            <div v-for="row of booking.Passengers[0].Segments" class="row">
                <div class="col-4">
                   <b>Vuelo:</b> {{row.Legs[0].InventoryLeg.FlightNumber}}
                </div>
                <div class="col-8">
                   <b>Clase: {{row.ClassOfService}} {{getBasicClass(row.ClassOfService)}}</b>
                </div>
                <div class="col-12">
                  <b>Origen:</b> <span>{{row.DepartureStation}} - {{row.DepartureStationObject.Name}}</span>
                </div>
                <div class="col-12">
                     <b>Destino:</b> <span>{{row.ArrivalStation}} - {{row.ArrivalStationObject.Name}}</span>
                </div>
                  <div class="col-4"><b>Fecha de Salida:</b> <span>{{getDate(row.Legs[0].InventoryLeg.DepartureDate)}}</span></div>
                  <div class="col-4"><b>Horario de Salida:</b> <span>{{getTime(row.Legs[0].InventoryLeg.STD)}}</span></div>
            </div>
            <div class="spacer"></div>
            <hr>
            <div class="spacer"></div>
            <div class="row">
                  <div class="col-12">
                     <b>Pasajeros:</b>
                  </div>
                  <div class="col-12" v-for="pax of booking.Passengers">
                     <span>{{pax.FirstName}} {{pax.LastName}}</span>
                  </div>
            </div>
            <div class="spacer"></div>
            <hr>
            <div class="spacer"></div>
            <div class="row">
              <div class="col-3"><b>Costo Total:</b></div>
              <div class="col-4"><span>{{parseFloat(booking.TotalCost).toFixed(2)}} ARS</span></div>
            </div>
            <div class="row">
              <div class="col-3"><b>Importe :</b></div>
              <div class="col-4"><span>{{parseFloat(booking.TotalPayments).toFixed(2)}} ARS</span></div>
            </div>
            <div class="row">
              <div class="col-3" v-if="booking.BalanceDue">
                    <b v-if="parseFloat(booking.BalanceDue)>=0">Pago Pendiente:</b>
                    <b v-else>Saldo a Favor:</b>
              </div>
              <div class="col-4" >
                    <span v-if="parseFloat(booking.BalanceDue)>=0">{{parseFloat(booking.BalanceDue).toFixed(2)}} ARS</span>
                    <span v-else>{{-parseFloat(booking.BalanceDue).toFixed(2)}} ARS</span>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="row">
              <div class="col-12"><b>Detalle de Pago:</b></div>
            </div>
            <div class="row" v-for="p of booking.Payments">
                <div class="col-4"><b>Monto:</b> <span>{{p.CurrencyCode}} {{parseFloat(p.PaymentAmount).toFixed(2)}}</span></div>
                <div class="col-4"><b>Forma de Pago:</b> <span>{{p.PaymentMethod.Name}}</span></div>
                <div class="col-4"><b>Fecha de Pago:</b> <span>{{getDate(p.CreatedUTC)}}</span></div>
            </div>
            <div class="spacer"></div>
            <hr/>
            <div class="spacer"></div>
            <div v-if="showTax">
                <div class="row">
                    <div class="col-12"><b>Tasas, Impuestos y Otros Cargos:</b></div>
                    <div class="col-2"><b>Concepto</b> </div>
                    <div class="col-4"><b class="amount-right">Monto</b> </div>
                </div>
                <div v-for="tax of getTaxes" class="row">
                    <div class="col-2"><span>{{tax.Code}}</span></div>
                    <div class="col-4"><span class="amount-right">{{tax.Amount.toFixed(2)}} {{tax.CurrencyCode}}</span></div>
                </div>
            </div>
            <div class="spacer"></div>
            <hr/>
            <div class="spacer"></div>
            <div class="row">
              <div class="col-12 text-justify">
                  Según RG 1415/03 de AFIP, en su Apartado I Inc. e):
                  "Las empresas que presten servicios de transporte público de pasajeros están exceptuadas de emitir comprobantes,
                  esta excepción alcanza a la venta de pasajes aéreos realizadas por las Agencias de Viajes y Turismo".
                </div>
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="row" v-if="printerType!='ticket'">
                <div class="col-12 itinerary-footer">0810-777-2633 - www.andesonline.com</div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@media print {
    .ticket-modal{
        size: auto;
        width: 80%;
        min-width: 450px;
        max-width: 450px;
    }
}
</style>

<script>
import { mapState } from 'vuex';
import escape from '@/components/tools/Escape';
var moment = require('moment');
export default {
  name: 'itinerary',
  mixins: [escape],
  data  () {
    return {
        showTax: true,
        printerType: null,
    }
  },
  mounted: async function() {
    this.printerType = localStorage.getItem("printerType");
    //let self = this;
    this.$nextTick(function() {
        $('.modal.printable').on('shown.bs.modal', function () {
            $('.modal-dialog', this).addClass('focused');
            //$('.modal-dialog', this).addClass(self.getPrinterFormat());
            $('body').addClass('modalprinter');

        }).on('hidden.bs.modal', function () {
            $('.modal-dialog', this).removeClass('focused');
            $('body').removeClass('modalprinter');
        });
    });
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    getTaxes () {
        let taxes = {};
        for (let p of this.booking.Passengers) {
            for (let s of p.Segments) {
                for (let c of s.Charges) {
                    if (!c.ChargeCode) continue;
                    if (!taxes[c.ChargeCode]) taxes[c.ChargeCode] = {Code: c.ChargeCode, CurrencyCode: c.CurrencyCode, Amount: 0};
                    taxes[c.ChargeCode].Amount += parseFloat(c.ChargeAmount);
                }
            }
        }
        return taxes;
    }
  },
  methods: {
    getDate (d) {
        return app.getDate(d)
    },
    getTime (d) {
        return app.getTime(d)
    },
    print () {
        window.print();
    },
    getPrinterFormat () {
        if (this.printerType=='ticket') return 'ticket-modal';
        return 'history-modal';
    },
    getBasicClass (c) {
        if (['A', 'C', 'F', 'I', 'J', 'N', 'O', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'].indexOf(c)>-1) {
            return ' - TARIFA BASICA. NO PERMITE CAMBIOS NI DEVOLUCIONES'
        }
        return '';
    }

  },
}
</script>

