<template>
    <div>
        <div class="container" v-if="booking && !processing">
            <div class="form-row">
                <div class="col-md-2">
                    <div class="card card-header booking-summary">
                        <div class="form-row">
                            <div class="col-md-12"><h4>{{booking.RecordLocator}}</h4></div>
                            <div class="col-md-12">{{bookingStatus[booking.Status]}}</div>
                            <!--div class="col-md-12">Pagos: {{booking.PaidStatus}}</div-->
                            <div class="col-md-12">Total: {{booking.TotalCost}}</div>
                            <div class="col-md-12">Pagos: {{booking.TotalPayments}}</div>
                            <div class="col-md-12">Saldo: <span :style="getColor(booking.BalanceDue)">{{booking.BalanceDue}}</span></div>
                            <div class="col-md-12" v-if="booking.Status==1">Hold: {{getLocalDateTime(booking.HoldUTC)}}</div>
                            <div class="col-md-12">Creado por: {{booking.CreatedUserName}}</div>
                            <div class="col-md-12">Ubicación: {{booking.CreatedLocationCode}}</div>
                            <div class="col-md-12">Booking ID: {{booking.BookingID}}</div>
                            <div class="col-md-12" v-if="booking.Organization">Organización: {{booking.Organization.Name}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary button-row" @click="confirmcancel = true">Cancelar</button>
                        <button class="btn btn-primary button-row" @click="save" :disabled="!infoCompleted">Grabar</button>
                        <button class="btn btn-primary button-row" @click="newBooking">Nueva Reserva</button>
                    </div>
                </div>

                <div class="col-md-10">
                    <ul class="nav nav-tabs col-md-12" id="langTabs" role="tablist">
                      <li class="nav-item" v-if="booking.BookingID">
                        <a class="nav-link" :class="getActiveClass(true)" id="summary" data-toggle="tab" href="#tab-summary" role="tab"
                        aria-controls="tab-summary" aria-selected="true"><label>Resumen</label></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" :class="getActiveClass(false)" id="fliths" data-toggle="tab" href="#tab-fliths" role="tab"
                        aria-controls="tab-fliths" aria-selected="true"><label>Vuelos</label></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="passengers" data-toggle="tab" href="#tab-passengers" role="tab"
                        aria-controls="tab-passengers" aria-selected="true"><label>Pasajeros</label>
                        <span class="field-missing" v-if="!paxInfoCompleted">*</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="contact" data-toggle="tab" href="#tab-contact" role="tab"
                        aria-controls="tab-contact" aria-selected="true"><label>Contacto</label>
                        <span class="field-missing" v-if="!contactInfoCompleted">*</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="charges" data-toggle="tab" href="#tab-charges" role="tab"
                        aria-controls="tab-charges" aria-selected="true"><label>Cargos</label></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="payments" data-toggle="tab" href="#tab-payments" role="tab"
                        aria-controls="tab-payments" aria-selected="true"><label>Pagos</label></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="comments" data-toggle="tab" href="#tab-comments" role="tab"
                        aria-controls="tab-comments" aria-selected="true"><label>Comentarios</label></a>
                      </li>
                      <li class="nav-item ml-auto">
                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="history = true"  rel="tooltip" data-placement="top" title="Historia">
                            <font-awesome-icon icon="history"/>
                        </button>
                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="itinerary = true"  rel="tooltip" data-placement="top" title="Imprimir">
                            <font-awesome-icon icon="print"/>
                        </button>
                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="split = true"  rel="tooltip" data-placement="top" title="Dividir Reserva">
                            <font-awesome-icon icon="divide"/>
                        </button>

                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="tickets = true"  rel="tooltip" data-placement="top" title="Comprobantes">
                            <font-awesome-icon icon="ticket-alt"/>
                        </button>
                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="sendItinerary"  rel="tooltip" data-placement="top" title="Enviar Itinerario">
                            <font-awesome-icon icon="envelope"/>
                        </button>
                        <button type="button" class="btn btn-primary action-button" :disabled="!booking.RecordLocator"
                            @click="showAssignSeats"  rel="tooltip" data-placement="top" title="Asignar Asientos">
                            <seat class="seat-svg"></seat>
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content col-md-12" id="TabsContent">
                      <div class="tab-pane fade show" :class="getActiveClass(true)" id="tab-summary" role="tabpanel"
                        aria-labelledby="tab-summary">
                         <bookingsummary :booking="booking"></bookingsummary>
                      </div>
                      <div class="tab-pane fade show" :class="getActiveClass(false)" id="tab-fliths"
                        role="tabpanel" aria-labelledby="tab-fliths">
                         <bookingflights></bookingflights>
                      </div>
                      <div class="tab-pane fade show" id="tab-passengers" role="tabpanel" aria-labelledby="tab-passengers">
                         <bookingpassengers></bookingpassengers>
                      </div>
                      <div class="tab-pane fade show" id="tab-contact" role="tabpanel" aria-labelledby="tab-contact">
                         <bookingcontact></bookingcontact>
                      </div>

                      <div class="tab-pane fade show" id="tab-charges" role="tabpanel" aria-labelledby="tab-charges">
                         <bookingfees></bookingfees>
                      </div>
                      <div class="tab-pane fade show" id="tab-payments" role="tabpanel" aria-labelledby="tab-payments">
                         <bookingpayments></bookingpayments>
                      </div>
                      <div class="tab-pane fade show" id="tab-comments" role="tabpanel" aria-labelledby="tab-comments">
                         <bookingcomments></bookingcomments>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="processing">
            <img class="loading-gif" src="@/img/loading.gif">
        </div>

        <bookinghistory v-if="history" :show.sync="history" id="bookinghistory"></bookinghistory>
        <itinerary v-if="itinerary" :show.sync="itinerary" id="itinerary"></itinerary>
        <tickets v-if="tickets" :show.sync="tickets" id="tickets"></tickets>
        <splitbooking v-if="split" :show.sync="split" @split="callSplitBooking" id="split-booking"></splitbooking>
        <confirmcancel v-if="confirmcancel" :show.sync="confirmcancel" @confirm="cancel" id="confirmCancel"></confirmcancel>
        <confirmnew v-if="confirmnew" :show.sync="confirmnew"  @confirm="getNewBooking" id="confirmCancel"></confirmnew>
        <confirm-seats v-if="showConfirmSeats" :show.sync="showConfirmSeats" @save="assignSeats" id="confirm-seats"></confirm-seats>
    </div>
</template>

<script>
import '@/css/app.css';

import bookingflights from '@/components/booking/BookingFlights'
import bookingpayments from '@/components/booking/BookingPayments'
import bookingsummary from '@/components/booking/BookingSummary'
import bookingpassengers from '@/components/booking/BookingPassengers'
import bookingcontact from '@/components/booking/BookingContact'
import bookingcomments from '@/components/booking/BookingComments'
import bookingfees from '@/components/booking/BookingFees'
import bookinghistory from '@/components/booking/BookingHistory'
import itinerary from '@/components/booking/Itinerary'
import tickets from '@/components/booking/Tickets'
import splitbooking from '@/components/booking/SplitBooking'
import confirmcancel from '@/components/tools/ConfirmCancel';
import confirmnew from '@/components/tools/ConfirmCancel';
import confirmSeats from '@/components/tools/ConfirmSeats';
import seat from '@/components/svg/Seat';
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'booking',
  components: {
    confirmSeats,
    bookingflights,
    bookingsummary,
    bookingpassengers,
    bookingpayments,
    bookingcomments,
    bookingfees,
    bookingcontact,
    bookinghistory,
    confirmcancel,
    itinerary,
    tickets,
    splitbooking,
    confirmnew,
    seat
  },
  data  () {
    return {
        processing: false,
        endpoint: '/api/booking/',
        history: false,
        itinerary: false,
        tickets: false,
        confirmcancel: false,
        confirmnew: false,
        ready: false,
        split: false,
        expireOn: null,
        modified: false,
        showConfirmSeats: false,
        bookingStatus: {
            0: 'PENDIENTE',
            1: 'HOLD',
            2: 'CONFIRMADO',
            3: 'FINALIZADO',
            4: 'CANCELADO'
        }
    }
  },
  mounted: async function() {
    this.ready = true;
    if (!this.booking) {
        this.$router.push('/booking_search');
        return
    }
    this.checkCanceledFlights();
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    paxInfoCompleted  () {
        for (let pax of this.booking.Passengers) {
            if (!pax.FirstName || pax.FirstName.length==0) return false;
            if (!pax.LastName || pax.LastName.length==0) return false;
            if (!pax.DocNumber || pax.DocNumber.length==0) return false;
            if (!pax.DocTypeCode) return false;
            if (!pax.DOB) return false;
            if (!pax.PaxType) return false;
            //if (!pax.Gender) return false;
            if (pax.Infant && pax.Infants[0]) {
                if (!pax.Infants[0].FirstName || pax.Infants[0].FirstName.length==0) return false;
                if (!pax.Infants[0].LastName || pax.Infants[0].LastName.length==0) return false;
                if (!pax.Infants[0].DOB) return false;
                //if (!pax.Infants[0].Gender) return false;
                if (!pax.Infants[0].DocNumber || pax.Infants[0].DocNumber.length==0) return false;
            }
        }
        return true;

    },
    contactInfoCompleted () {
        if (!this.booking.ContactInformation.length>0) return false;
        let c = this.booking.ContactInformation[0];
        if (!c.EmailAddress || !c.FirstName || !c.LastName) return false;
        if (!c.HomePhone && !c.WorkPhone && !c.OtherPhone) return false;
        return true;
    },
    infoCompleted  (){
        if (!this.booking.Passengers.length>0) return false;
        if (!this.paxInfoCompleted && !this.booking.Group) return false;
        if (!this.booking.Passengers[0].Segments.length>0 && !this.booking.BookingID) return false;
        if (!this.contactInfoCompleted) return false;
        return true;
    }
  },
  methods: {
    getColor (balance) {
        if (balance>0) return "color: red;"
        if (balance<0) return "color: blue;"
        return "";
    },
    cancel: async function() {
        //app.postJSON('/api/booking/discard_modified', {booking: this.booking});
        if (!this.booking || !this.booking.BookingID) {
            this.$router.push('/booking_search');
            return
        }
        this.processing = true;
        let res = await app.get('/api/booking/' + this.booking.BookingID)
        if (res) {
            this.ready = false;
            this.$store.commit('setBooking', res);
            this.processing = false;
            this.$nextTick(function() {
                self.ready = true;
            })
        }

    },
    save: async function() {
        this.processing = true;
        this.booking.original_id = this.booking.BookingID;
        let result = await app.save(this, this.booking);
        if (result) {
            //result.original_id = result.BookingID;
            this.ready = false;
            this.modified = false;
            this.$store.commit('setBooking', result)
            this.processing = false;
            let self = this;
            this.$nextTick(function() {
                self.ready = true;
            })
        }

    },
    getActiveClass (active) {
        if (active && this.booking.BookingID) return 'active';
        if (!active && !this.booking.BookingID) return 'active';
        return '';
    },
    getLocalDateTime (d) {
        return moment.utc(d).local().locale('es').format('ddd DD MMM HH:mm');
    },
    startCounter (seconds){
        let self = this;
        setTimeout(function(){
            self.cancel();
        }, seconds * 1000);
    },
    leaving  (event) {
        alert('leaving')
        //app.postJSON('/api/booking/discard_modified', {booking: this.booking})
    },
    sendItinerary: async function() {
        let data = {
            BookingID: this.booking.BookingID,
            RecordLocator: this.booking.RecordLocator,
        }
        let res = await app.postJSON('/api/booking/send_itinerary', data);
        if (res) {
            alert('Mensage Enviado');
        }
    },
    showAssignSeats () {
        this.showConfirmSeats = true;
    },
    assignSeats: async function() {
        this.processing = true;
        let data = {
            BookingID: this.booking.BookingID,
            RecordLocator: this.booking.RecordLocator,
        }
        let res = await app.postJSON('/api/booking/assign_seats', data, this);
        if (res) {
            //result.original_id = result.BookingID;
            this.ready = false;
            this.modified = false;
            this.$store.commit('setBooking', res)
            this.processing = false;
            let self = this;
            this.$nextTick(function() {
                self.ready = true;
            })
        } else {
            this.processing = true;
            self.ready = true;
        }
    },
    callSplitBooking: async function(passengers) {
        let data = {
            bookingId: this.booking.BookingID,
            passengerToMoveIds: passengers,
        }
        this.processing = true;
        let res = await app.postJSON('/api/booking/split', data, this);
        if (res) {
            this.$store.commit('setBooking', res.new_booking);
            this.processing = false;
        }
    },
    newBooking () {
        this.confirmnew = true;
        this.$nextTick(function () {
          $('#confirmCancel').modal('show')
        })
    },
    getNewBooking () {
        this.confirmnew = false;
        this.processing = true;
        $('#confirmCancel').modal('hide')
        let self = this;
        setTimeout(function() {
            let booking = app.newBooking();
            self.$store.commit('setBooking', booking);
            self.processing = false;
        }, 500)
    },
    checkCanceledFlights () {
        for (let pax of this.booking.Passengers) {
            for (let segment of pax.Segments) {
                if (moment(segment.DepartureDate)>moment(new Date())) {
                    for (let leg of segment.Legs) {
                        if (leg.InventoryLeg && leg.InventoryLeg.LegStatus=='CANCELED') {
                            alert('Esta reserva tiene un vuelo Cancelado');
                            return;
                        }
                    }
                }
            }
        }
    }



  },
  watch: {
        booking: {
            handler: async function(oldValue, newValue) {
                if (this.ready) {
                    this.modified = true;
                }
                this.ready = true;
            },
            deep: true,
        },
  },
  beforeDestroy() {
    //app.postJSON('/api/booking/discard_modified', {booking: this.booking})
  },
  created () {
    //window.addEventListener('beforeunload', this.leaving);
    //window.addEventListener('onblur', this.leaving);
    //window.addEventListener('keydown', this.leaving);
  },
}
</script>

