<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'escape',
  props: ['show', 'id'],
  mounted () {
      $('#' + this.id).modal('show');
  },
  methods: {
    close () {
        this.$emit('update:show', false);
        $('#' + this.id).modal('hide');
    },
    evenListener (e) {
      if (e.keyCode == 27) {
        if ($('.modal').length==2) {
            return;
        }
        this.close();
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.evenListener);
  },
  created () {
    setTimeout(()=> document.addEventListener("keydown", this.evenListener), 500);
  }
}
</script>
