<template>
      <div class="modal-content">
        <div class="modal-body form-row">
            <div class="col-md-12">
                <textarea v-model="text" rows="3" class="form-control"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">Aceptar</button>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
import selectinput from '@/components/tools/SelectInput'
var moment = require('moment');
export default {
  name: 'newcomment',
  props: ['refund'],
  components: {
    selectinput
  },
  data  () {
    return {
        text: null,
    }
  },
  mounted: async function() {
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    save () {
        let p = {
            CommentText: this.text,
            CreatedUserCode: this.user.UserName,
            CommentType: 0
        }
        this.booking.Comments.push(p)
        this.$emit('close');
    }
  },
}
</script>

