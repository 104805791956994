<template>
  <div class="modal fade bd-example-modal-lg printable" id="bookinghistory" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg history-modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmDeleteLabel">{{booking.RecordLocator}}</h5>
        </div>
        <div class="modal-body">
            <table class="table table-striped table-sm table-bordered">
                <tbody>
                    <tr v-for="(events,date) of getEventsByDate">
                        <td>
                            <div class="text-left form-row">
                                <div class="col-md-3"><b>Fecha: {{getDate(date)}}</b></div>
                                <div class="col-md-3"><b>Hora: {{getTime(date)}}</b></div>
                                <div class="col-md-3"><b>Usuario: {{events[0].CreatedUserCode}}</b></div>
                            </div>
                            <div class="text-left form-row text-small" v-for="e of events">
                                <!--div class="col-md-1"><b>{{e.HistoryCode}}</b></div-->
                                <div class="col-md-2">{{getHistoryName(e.HistoryCode)}}</div>
                                <div class="col-md-10">{{e.Detail}}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import escape from '@/components/tools/Escape';
import { mapState } from 'vuex';
var moment = require('moment');
var codes = require('@/historycodes.json');
export default {
  name: 'bookinghistory',
  mixins: [escape],
  data  () {
    return {
    }
  },
  mounted: async function() {
    this.$nextTick(function() {
        $('.modal.printable').on('shown.bs.modal', function () {
            $('.modal-dialog', this).addClass('focused');
            $('body').addClass('modalprinter');

        }).on('hidden.bs.modal', function () {
            $('.modal-dialog', this).removeClass('focused');
            $('body').removeClass('modalprinter');
        });
    });
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
    getEventsByDate () {
        let res = {};
        for (let event of this.booking.History) {
            if (!res[event.CreatedUTC]) res[event.CreatedUTC] = [];
            res[event.CreatedUTC].push(event);
        }
        return res;
    }
  },
  methods: {
    getDate (d) {
        return app.getLocalDate(d)
    },
    getTime (d) {
        return app.getLocalTime(d)
    },
    getHistoryName (code) {
        let t = codes[code];
        if (t) return t;
        return "";
    },
    print () {
        window.print();
          let div = $('#bookinghistory').html()
          div += '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">'
          div += '<style>.modal-lg {max-width: 1100px;}</style>';

          var win = window.open();
          win.document.body.innerHTML = div;

          setTimeout(() => {
            //win.print();
            //win.close()
          }, 200)


        //window.print();
    }

  },
}
</script>

