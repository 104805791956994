<template>
    <div class="container container-report">
        <div class="row" v-if="isMounted" >
            <div class="col-md-2 row report-header">
                <div class="col-12 text-center">
                    <h5>{{title}}</h5>
                </div>
                <div v-for="field of myfields" class="col-12" v-if="!field.hidden">
                   <inputfield :field="field" :defValue.sync="current[field.name]" :fieldOptions="fieldOptions"></inputfield>
                </div>
                <div class="col-12 row-report-button px-3">
                    <button class="btn btn-primary w-100" @click="run" :disabled="processing">Consultar</button>
                </div>
                <div class="col-12 row-report-button px-3">
                    <button class="btn btn-primary w-100" @click="download" :disabled="processing || !list">Descargar</button>
                </div>
                <div class="col-12 row-report-button px-3" v-for="b of buttons">
                    <button class="btn btn-primary btn-report w-100" @click="buttonCallback(b.callback, b.id)"  :disabled="processing || !list"
                    :class="b.buttonClass">{{b.label}}</button>
                </div>
            </div>
            <div class="col-10 report-view" v-if="!processing && list">
                <div class="table-max-content" id="data-table">
                    <div style="display: none" v-if="headerFields">
                        <table>
                          <tbody>
                            <tr v-for="field of headerFields">
                                <td>
                                   {{field.label}}
                                </td>
                                <td>
                                    {{field.value}}
                                </td>
                            </tr>
                            <tr>
                            </tr>
                          </tbody>
                        </table>
                    </div>

                    <v-client-table :data="list" :columns="columnsNames" :options="options"
                    ></v-client-table>
                </div>
            </div>
            <div v-if="processing" class="col-10">
                <img class="loading-gif" src="@/img/loading.gif">
            </div>
        </div>
    </div>
</template>


<script>
import * as _ from 'lodash';
import selectinput from '@/components/tools/SelectInput';
import datepicker from '@/components/tools/DatePicker';
import inputfield from '@/components/tools/InputField'
import { mapState } from 'vuex';

import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);

export default {
    name: 'report-window',
    props: ['endpoint', 'fields', 'title', 'headers', 'templates', 'columnsClasses', 'perPage',
        'headerFields', 'afterRun', 'buttons'],
    components: {
      selectinput,
      datepicker,
      inputfield,
      ClientTable,
    },
    data  () {
        return {
            current: {},
            processing: false,
            fieldOptions: {},
            getInvalidClass: {},
            isMounted: false,
            list: null,
            toExcel: false,
            columnsNames: [],
            options: {
                filterable: true,
                perPageValues: [20, 50, 100, 500, 1000, 10000, 100000],
                perPage: this.perPage? this.perPage: 100,
                headings: this.getHeadings(),
                texts: app.vueTableText(),
                templates: this.getTemplates(),
                skin: 'table table-striped  table-sm table-bordered',
                columnsClasses: this.columnsClasses? this.columnsClasses: {},
            }
        }

    },
    computed:{
        ...mapState({
            user: state => state.user,
        }),
        columns () {
            return 12 / this.cols;
        },
        editorRows  () {
            return [this.myfields];
        },
        myfields  () {
            return app.myfields(this.fields);
        },
    },
    created (){
    },
    mounted: async function () {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        this.fieldOptions = await app.calculateFieldOptions(this);
        this.isMounted = true;
    },
    methods: {
        getColumns (field) {
            if (field.columns) return field.columns;
            return this.columns;

        },
        run: async function() {
            let required = app.checkFields(this);
            if (required) return;
            this.processing = true;
            let res = await app.get(this.endpoint, this.current)
            if (res) {
                this.list = res.rows
                this.columnsNames = res.header;
                if (this.afterRun) {
                    this.afterRun(this)
                }
                this.processing = false;
            }
        },
        download () {
            this.options.filterable = false;
            this.options = Object.assign({}, this.options);
            $('.VueTables__search').remove();
            $('.VueTables__limit').remove();
            $('.VuePagination').remove();
            setTimeout(() => {
                app.downloadReport('data-table');
                this.options.filterable = true;
                this.options = Object.assign({}, this.options);
            }, 500);

        },
        getHeadings () {
            let res = {};
            for (let name in this.headers) {
                res[name] = this.headers[name];
            }
            return res;
        },
        getTemplates () {
            let res = {};
            if (!this.templates) return res;
            for (let field of this.templates) {
                res[field.name] = function(t, row) {
                        return field.callback(row);
                    }
            }
            return res;

        },
        async buttonCallback (callback, id) {
            await callback(this)
        },


    }
}
</script>
