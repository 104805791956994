<template>
  <div class="modal fade bd-example-modal-lg printable" id="checkincomments" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg history-modal" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <table class="table table-striped table-sm table-bordered">
                <tbody>
                    <tr v-for="c of comments">
                        <td>
                            <div class="form-row">
                                Usuario: {{c.CreatedUserCode}}
                            </div>
                            <div class="form-row">
                                {{c.CommentText}}
                            </div>
                            <div class="form-row">
                                Fecha: {{getDateTime(c.CreatedUTC)}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'checkincomments',
  props: ['comments'],
  components: {
  },
  data  () {
    return {
    }
  },
  mounted: async function() {
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  methods: {
    getDateTime (d) {
        return moment(d).format('DD/MM/YYYY HH:mm');
    }

  },
}
</script>

